@import "smart-grid";

.reset();
.wrapper {
	.wrapper();
	box-sizing: border-box;
	.lg-block({
		max-width: 1080px;
	});
	.md-block({
		max-width: 768px;
	});
	.xs-block({
		max-width: 560px;
	});
}

.debug{
    .debug(rgba(0, 0, 0, 0.5), 1px solid #ff0);
    z-index: 999999999;
}

@font-face {
	font-family: Mariupol; 
	src: url("../fonts/Mariupol/WOFF/Mariupol-Regular.woff") format("woff"),
		 url("../fonts/Mariupol/OTF/Mariupol-Regular.otf") format("opentype");
	font-style: normal; 
	font-weight: normal; 
	font-display: fallback;
}

@font-face {
	font-family: Mariupol; 
	src: url("../fonts/Mariupol/WOFF/Mariupol-Bold.woff") format("woff"),
		 url("../fonts/Mariupol/OTF/Mariupol-Bold.otf") format("opentype");
	font-style: normal; 
	font-weight: bold; 
	font-display: fallback;
}
 
@font-face {
	font-family: Mariupol; 
	src: url("../fonts/Mariupol/WOFF/Mariupol-Bold.woff") format("woff"),
		 url("../fonts/Mariupol/OTF/Mariupol-Bold.otf") format("opentype");
	font-style: normal; 
	font-weight: bold; 
	font-display: fallback;
}


* {
	font-size: 16px;
	font-family: Mariupol,Arial,sans-serif;
}
body {
	background: #fff;
	color: #000;
	font-family: Mariupol, Arial, sans-serif;
	font-weight: bold;
	height: 100%;
	min-height: auto;
	position: relative;
	word-wrap: break-word;
	color: @fontColor;
	box-sizing: border-box;
	overflow-x: hidden;
}

.rembit_why_mob_two,
.rembit_why_mob_one,
.rembit_principles_mob,
.rembit_scheme,
.rembit_reviews {
	overflow: hidden;
}

// ++++++++++++++++
@media (max-width: 768px) {
	// body > header,
// 	body > div,
// 	body > footer {
// 		display: none;
// 	}

// 	.rembit_bid {
// 		display: block;
// 	}
// 	.rembit_scheme {
// 		display: block;
// 	}
// 	.rembit_questions {
// 		display: block;
// 	}
// 	.rembit_reviews {
// 		display: block;
// 	}
// 	.rembit_description {
// 		display: block;
// 	}
// 	.rembit_map {
// 		display: block;
// 	}
// 	.rembit_faults {
// 		display: block;
// 	}
// 	.rembit_repair {
// 		display: block;
// 	}
// 	.rembit_cost {
// 		display: block;
// 	}
}
// ++++++++++++++++

strong {
	font-weight: bold;
}

ul, li, a {
	font-family: Mariupol; 
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none;
	color: #fff;
}
.wrapper h1, .wrapper h2, .wrapper h3, .wrapper h4, .wrapper h5 {  // ?
	padding: 0 !important;
	border-bottom: none !important;
}
.fancybox-slide--html {
	padding: 0;
}

// scrollbar===================================================================
::-webkit-scrollbar {
	width: 12px;
	.xs-block({
		width: 6px;
	});
} 
::-webkit-scrollbar-track {
	background: @back;
	box-shadow: none; 
}
::-webkit-scrollbar-thumb {
	background: @optiColor;
	box-shadow: none;
}
// scrollbar===================================================================

.button_unset() {
	font-family: Mariupol,Arial,sans-serif;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
}

.button_std() {
	.button_unset();
	background: @firstLinkHover;
	cursor: pointer;
	font-size: 18px;
	text-align: center;
	letter-spacing: 0.04em;
	color: #FFFFFF;
	transition: .3s;

	width: 260px;
	height: 50px;

	&:hover {
		background: #66B9FF;
	}
}

.btn_show_all() {
	.button_unset();
	width: 263px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	background: @optiColor;
	margin: 0 auto;
	margin-top: 30px;
	transition: .15s;

	img {
		margin-right: 20px;
		transition: .5s;
	}

	&:hover {
		background: linear-gradient(0deg,rgba(255,255,255,.3),rgba(255,255,255,.3)),#784fd1;
		box-shadow: 0 2px 5px rgba(0,0,0,.66);

		img {
			transform: rotate(360deg);
		}
	}
}


.square_small() {
	content: '';
	position: absolute;
	width: 18px;
	height: 18px;
	background: @optiColor;
	top: 0;
	left: 0;
}
.square_img() {
	content: '';
	position: absolute;
	width: 33px;
	height: 52px;
	background: url(../img/why/block.svg) no-repeat;
	background-size: contain;
	bottom: 0;
	right: 0;
}

.h2() {
	font-weight: bold;
	line-height: 34px;
	font-size: 25px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: #FFFFFF;
}

.h2_color() {
	font-weight: bold;
	line-height: 34px;
	font-size: 25px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: @optiColor;
}

.h2_gray() {
	font-weight: bold;
	line-height: 34px;
	font-size: 25px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: @fontColor;
}


// VARIABLES****************************************************
@mainColor: #ECECEC;
@mainLists: #F7F7F7;
@firstLinkHover: #008BFF;
@mainColorHover: #e9e9e9;

@back: #E2EFFF;

@fontColor: #4C4C4C;
@fontColorTwo: #333333;
@fontColorFaded: #9D9D9D;

@optiColor: #784FD1;

@iconColor: #4E16C5;

@backMob: #4B1BB1;

@menuTitle: #9b9b9b;
// VARIABLES****************************************************





// Elements=====================================================
// =============================================================

.h2_mob(@color: #FFFFFF) {
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	text-align: center;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: @color;
}
.desc_mob(@color: #FFFFFF) {
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	text-align: center;
	letter-spacing: 0.02em;
	color: @color;
}


// dots for slider______________________________________________
@dot: '../img/optional/dot.svg';
@dotGray: '../img/optional/dot_gray.svg';

.dots(@img: @dot; @bottom: -45px) {
    .flickity-page-dots {
		bottom: @bottom;
        .dot {
            opacity: 1;
            background: url(@img) no-repeat;
            background-size: contain;
            width: 8px;
            border-radius: 0;
            transition: .3s;

            &.is-selected {
                background: url(../img/optional/dot_selected.svg) no-repeat;
                background-size: contain;
            }
        }
    }
}
// dots for slider______________________________________________

// input______________________________________________
.input(@width: 265px; @height: 45px) {
	box-sizing: border-box;
	width: @width;
	height: @height;
	padding-left: 20px;
	outline: none;
	border: none;
	
	color: @fontColor;
	font-weight: normal;
	line-height: normal;
	font-size: 16px;
	letter-spacing: 0.02em;

	&::placeholder {
		color: @fontColorFaded;
	}
}
// input______________________________________________


.error() {
	display: none;
	opacity: 0;
	position: relative;
	padding-right: 20px;
    margin-bottom: 10px;
    color: @optiColor;
	&::after  {
		content: '';
		position: absolute;
		right: 0;
		top: 3px;
		background: url(../img/principles/nut_before.svg) 0 0/contain no-repeat;
		width: 10px;
		height: 12px;
	}
	&.active {
	    display: inline-block;
	    opacity: 1;
	    transition: .3s;
	}
}



// end__Elements=====================================================







[disabled] {
	cursor: not-allowed;	
}
// select-styling===========================================================================
select[disabled] + .select2 .select2-selection {
	background: #dad8d8;
    border: 1px solid #dad8d8;
	cursor: not-allowed;
}

.select2-container {
	top: -13px;
}
.select2-container--default .select2-selection--single {
    height: 45px;
    border: 1px solid @back;
	outline: none;
	border-radius: 0;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-weight: normal;
    line-height: 45px;
    font-size: 15px;
    letter-spacing: 0.02em;
    color: @fontColorFaded;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: normal;
    line-height: 45px;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #000000;
    padding-left: 17px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: none;
    border-bottom: 1px solid @back;
    padding-left: 17px;
    padding-right: 30px;
    height: 45px;
    color: #000000;
    font-weight: normal;

    &:focus {
        outline: 1px solid purple;
        outline-offset: 0px;
    }
}
.select2-search--dropdown {
    padding: 0;
    outline: none;
}
.select2-dropdown {
    border: 1px solid @back;
}
.select2-results__option {
    font-weight: normal;
    line-height: 45px;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #000000;
    padding: 0;
    padding-left: 17px;
}
.select2-container--default .select2-results>.select2-results__options {
    min-height: 320px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: @firstLinkHover;
    color: #ffffff;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background: @back;
    color: #000000;

    &:hover {
        color: #000000;
    }
}



.select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 13px;
    height: 12px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: unset;
    border-style: unset;
    border-width: unset;
    height: unset;
    left: unset;
    margin-left: unset;
    margin-top: unset;
    top: unset;
    width: unset;

    background: url(../img/map/arrow.svg) no-repeat;
    background-size: contain;
    width: 13px;
    height: 12px;
    top: 17px;
    right: 15px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b.arrow_up {
    animation: select_arrow_up .25s linear forwards;
}

@keyframes select_arrow_up {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
// select-styling===========================================================================