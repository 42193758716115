@import "entry";

.rembit_repair {
    box-sizing: border-box;
    background: @back url(../img/description/back.png) no-repeat;
    background-size: cover;
    padding: 50px 0;
    .xs-block({
        background: @back;
        padding-top: 40px;
        padding-bottom: 100px;

        .wrapper {
            padding: 0;
        }
    });
    h2 {
        .h2_gray();
        margin-bottom: 30px;

        .xs-block({
            .h2_mob(@fontColor);
            padding: 0 15px !important;
        });
    }
    &__block {
        display: flex;
        flex-wrap: wrap;

        .xs-block({
            display: block;
            .dots(@dot; -70px);
        });

        .item {
            box-sizing: border-box;
            color: @fontColor;
            background: #ffffff;
            text-transform: initial;
            letter-spacing: 0.02em;
            overflow: hidden;
            position: relative;
            width: 324px;
            height: 460px;
            padding: 0 28px 40px 28px;
            margin-right: 19px;
            margin-top: 20px;

            .xs-block({
                width: 100%;
                padding: 0 15px 40px 15px;
            });

            &:hover {
                button {
                    transform: translateY(0);
                }
                .block_for_img {
                    opacity: .4;
                }
                .block_for_img, .title, .text {
                    transform: translateY(-70px);
                }
                &::before {
                    transform: translateX(-18px);
                }
                &::after {
                    transform: translateX(33px);
                }
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
            &:nth-child(-n+4) {
                margin-top: 0;
            }
            .lg-block({
                &:nth-child(odd), &:nth-child(even) {
                    margin-right: 19px;
                    margin-top: 20px;
                }
                &:nth-child(3n) {
                    margin-right: 0;
                }
                &:nth-child(-n+3) {
                    margin-top: 0;
                }
            });
            .md-block({
                width: 359px;
                &:nth-child(odd), &:nth-child(even) {
                    margin-right: 19px;
                    margin-top: 20px;
                }
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            });
            .xs-block({
                // width: 359px;
                &:nth-child(odd), &:nth-child(even) {
                    margin: 0;
                }
            });

            &::before {
                .square_small();
                transition: .2s;
            }
            &::after {
                .square_img();
                transition: .2s;
            }

            &__block {
                .block_for_img {
                    height: 100px;
                    width: 100%;
                    margin-top: 32px;
                    display: flex;
                    align-items: center;
                    transition: .3s;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .title {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 25px;
                    margin: 10px 0;
                    transition: .3s;
                }
                .text {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    transition: .3s;
                }
            }
            button {
                .button_std();
                // display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                background: @firstLinkHover;

                transition: .3s;
                transform: translateY(50px);
            }
        }
    }

    .show_all {
        .btn_show_all();

        .xs-block({
            display: none;
        });
    }
}