@import "entry";

// elements__________________________________________________________________
.form_right() {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 325px;
    height: 350px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 40px;
    background: @back;
    box-sizing: border-box;

    .lg-block({
        width: 305px;
    });
    .xs-block({
        display: none;
    });

    input {
        .input();

        .lg-block({
            width: 245px;
        });
    }
    .desc {
        color: @fontColorFaded;
        font-weight: normal;
        line-height: 14px;
        font-size: 12px;
    }
    button {
        .button_unset();
        .button_std();

        .lg-block({
            width: 245px;
        });
    }
}

.screwdriver() {
    content: '';
    z-index: 1;
    background: url(../img/bid/screwdriver.svg) no-repeat;
    background-size: contain;
    width: 198px;
    height: 27px;
    position: absolute;
    bottom: 53px;
    right: -40px;
    animation: screwdriver 2s linear infinite alternate;

    .xs-block({
        display: none;
    });
}

@keyframes screwdriver {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

.call() {
    .button_unset();
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    background: @optiColor;
    height: 35px;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: none;

    &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
        transition: .15s;
    }

    .xs-block({
        display: block;
    });
}

// elements__________________________________________________________________

.rembit_bid {
    margin-top: 20px;
    
    .xs-block({
        margin-top: 0;
        position: relative;
        background: @back;

        &::after {
            content: '';
            position: absolute;
            width: 269px;
            height: 228px;
            background: url(../img/bid/xs_master.svg) no-repeat;
            top: unset;
            bottom: 35px;
            left: unset;
            right: 0;
        }
    });

    .call {
        .call();
    }

    &__block {
        display: flex;
        position: relative;
        .rembit_bid__main_block {
            background: @back url(../img/bid/back.png);
            width: 1015px;
            height: 350px;
            display: flex;
            margin-right: 20px;
            position: relative;

            .xs-block({
                height: 486px;
                width: 100%;
                background: none;
                margin-right: 0;
            });

            &::before {
                .screwdriver();
            }
            &::after {
                content: '';
                background: url(../img/bid/master.svg) no-repeat;
                background-size: contain;
                width: 494px;
                height: 350px;
                position: absolute;
                top: 0;
                left: 0;

                .lg-block({
                    background: url(../img/bid/lg_master.png) no-repeat;
                    top: unset;
                    bottom: -20px;
                    left: 5px;
                });
                .md-block({
                    background: url(../img/bid/md_master.png) no-repeat;
                    top: unset;
                    bottom: -190px;
                    left: 0;
                });
                .xs-block({
                    display: none;
                });
            }
            .text {
                color: @fontColor;
                margin-top: 75px;
                padding-left: 457px;

                .lg-block({
                    margin-top: 70px;
                    padding-left: 298px;
                    padding-right: 20px;
                });
                .md-block({
                    margin-top: 45px;
                    padding-left: 20px;
                });
                .xs-block({
                    margin-top: 70px;
                    padding: 0;
                });
                h1 {
                    font-weight: bold;
                    line-height: 50px;
                    font-size: 45px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    &.stub {
                        font-size: 30px;
                        line-height: 35px;

                        .lg-block({
                            font-size: 26px;
                            line-height: 33px;
                        });
                        .md-block({
                            font-size: 27px;
                            line-height: 30px;
                        });
                        .xs-block({
                            font-size: 20px;
                            line-height: 25px;
                        });
                    }
                    .lg-block({
                        font-size: 35px;
                        line-height: 39px;
                    });
                    .xs-block({
                        font-size: 25px;
                        line-height: 28px;
                    });
                }
                .desc {
                    font-weight: normal;
                    line-height: 23px;
                    font-size: 18px;
                    letter-spacing: 0.02em;

                    .lg_br {
                        display: none;

                        .lg-block({
                            display: block;
                        });
                        .xs-block({
                            display: none;
                        });
                    }
                    .lg_br_none {
                        .lg-block({
                            display: none;
                        });
                        .xs-block({
                            display: none;
                        });
                    }
                    .lg-block({
                        font-size: 16px;
                        line-height: 20px;
                    });
                }
            }
        }
    }
    &__form_bid {
        .form_right();

        .error_name {
            .error();
            font-size: 13px;
            margin-bottom: 5px;
        }
        .error_tel {
            .error();
            font-size: 13px;
            margin: 0;
            margin-top: 5px;
        }
    }
}


// rembit_bid_separate=========================================================================
.rembit_bid_separate {
    margin-top: 20px;
    .xs-block({
        margin-top: 58px;
        padding-bottom: 35px;
        position: relative;
    });

    .call {
        .call();
    }

    &__block {
        display: flex;
        position: relative;
        .rembit_bid_separate__main_block {
            background: #ffffff;
            width: 1015px;
            height: 350px;
            display: flex;
            margin-right: 20px;
            position: relative;
            
            .md-block({
                height: 320px;
            });
            .xs-block({
                flex-direction: column;
                height: auto;
                min-height: 436px;
            });

            &::before {
                .screwdriver();

                .md-block({
                    display: none;
                });
            }

            &::after {
                content: '';
                background: url(../img/bid/md_master.png) no-repeat;
                background-size: contain;
                width: 104px;
                height: 152px;
                position: absolute;
                bottom: 0;
                right: 0;
                transform: rotate(180deg);
                display: none;

                .md-block({
                    display: block;
                });
                .xs-block({
                    display: none;
                });
            }

            .block_f_img {
                width: 457px;
                max-width: 457px;
                display: flex;
                height: 100%;
                padding: 0 8px;
                box-sizing: border-box;
                .lg-block({
                    width: 250px;
                });
                .xs-block({
                    max-height: 300px;
                    margin: 0 auto;
                });
                img {
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                    display: block;

                    &.brand {
                        max-height: 180px;
                    }
                }
            }
            .text {
                width: 755px;
                padding-top: 75px;
                padding-left: 30px;
                padding-right: 30px;
                color: @fontColor;
                background: @back url(../img/bid/back.png);
                box-sizing: border-box;
                position: relative;

                .lg-block({
                    width: 437px;
                    padding-top: 30px;
                });
                .md-block({
                    width: 488px;
                });
                .xs-block({
                    background: none;
                    width: 100%;
                    margin-top: 15px;
                    padding: 0;
                });
                h1 {
                    font-weight: bold;
                    font-size: 33px;
                    line-height: 40px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    .lg-block({
                        font-size: 25px;
                        line-height: 30px;
                    });
                    .xs-block({
                        .h2_mob(@fontColor);
                    });
                }
                .desc {
                    font-weight: normal;
                    line-height: 23px;
                    font-size: 18px;
                    letter-spacing: 0.02em;

                    .lg_br {
                        display: none;

                        .lg-block({
                            display: block;
                        });
                        .md-block({
                            display: none;
                        });
                    }
                    .lg_br_none {
                        .lg-block({
                            display: none;
                        });
                        .xs-block({
                            display: none;
                        });
                    }
                    .lg-block({
                        font-size: 16px;
                        line-height: 20px;
                    });
                    .xs-block({
                        margin-bottom: 20px;
                    });
                }
                .price {
                    font-weight: normal;
                    line-height: 33px;
                    font-size: 23px;
                    letter-spacing: 0.02em;
                    font-weight: bold;
                    color: @optiColor;
                    position: absolute;
                    bottom: 15px;
                    left: 30px;

                    .lg-block({
                        line-height: 27px;
                        font-size: 19px;
                    });
                    .md-block({
                        font-size: 16px;
                        line-height: 16px;
                        bottom: 15px;
                        left: 30px;
                    });                    
                    .xs-block({
                        font-size: 18px;
                        line-height: 23px;
                        position: relative;
                        left: 0;
                        padding-top: 10px;
                    });
                }
                .call_separate {
                    .button_std();
                    width: 300px;
                    position: absolute;
                    bottom: 40px;
                    display: none;
                    
                    .md-block({
                        display: block;
                    });
                    .xs-block({
                        display: none;
                    });
                }
            }
        }
    }
    &__form_bid {
        .form_right();
        .md-block({
            display: none;
        });

        .error_name {
            .error();
            font-size: 13px;
            margin-bottom: 5px;
        }
        .error_tel {
            .error();
            font-size: 13px;
            margin: 0;
            margin-top: 5px;
        }
    }
}