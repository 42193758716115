@import "entry";

.rembit_principles {
    // overflow: hidden;
    
    .md-block({
        background: #ffffff;
    });
    .xs-block({
        display: none;
    });

    .wrapper {
        position: relative;

        &::before {
            content: '';
            width: 345px;
            height: 410px;
            position: absolute;
            left: -150px;
            bottom: -200px;
            background: url(../img/principles/nut_back.svg) no-repeat;
            background-size: contain;
            z-index: -2;

            .lg-block({
                bottom: -150px;
            });
            .md-block({
                bottom: 0px;
                left: -180px;
            });
        }
        &::after {
            content: '';
            position: absolute;
            background: url(../img/principles/nut_back.svg) no-repeat;
            background-size: contain;
            z-index: -2;
            width: 345px;
            height: 410px;
            right: 150px;
            top: -100px;

            .lg-block({
                right: -90px;
            });
            .md-block({
                right: 100px;
                top: 20px;
            });
        }
    }

    &__block {
        height: 435px;
        box-sizing: border-box;
        padding-top: 60px;
        position: relative;

        .lg-block({
            height: 500px;
        });
        .md-block({
            height: auto;
            padding-top: 50px;
            padding-bottom: 50px;
        });

        &::before {
            content: '';
            position: absolute;
            background: url(../img/principles/nut_back.svg) no-repeat;
            background-size: contain;
            z-index: -2;
            width: 203px;
            height: 240px;
            right: 0;
            bottom: -40px;

            .lg-block({
                right: -90px;
            });
            .md-block({
                right: 10px;
            });
        }
        
        h2 {
            .h2_color();

            .md-block({
                font-size: 25px;
                line-height: 34px;
            });
        }

        &__items {
            margin-top: 60px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .lg-block({
                justify-content: flex-start;
            });
            .md-block({
                margin-top: 30px;
            });

            .item {
                .size(3);
                position: relative;

                &:nth-child(n+5) {
                    margin-top: 75px;
                }

                .lg-block({
                    .size(4);
                    &:nth-child(n+4) {
                        margin-top: 42px;
                    }
                });
                .md-block({
                    .size(6);
                    &:nth-child(odd), &:nth-child(even) {
                        margin-top: 0;
                    }
                    &:nth-child(n+3) {
                        margin-top: 42px;
                    }
                });

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 4px;
                    background: url(../img/principles/nut_before.svg) no-repeat;
                    background-size: contain;
                    width: 10px;
                    height: 12px;
                }
                .desc {
                    padding-left: 20px;
                    font-weight: normal;
                    line-height: 23px;
                    font-size: 18px;
                    letter-spacing: 0.02em;
                    color: @fontColor;
                }
            }
        }
    }
}



.rembit_principles_mob {
    background: #ffffff;
    display: none;

    .xs-block({
        display: block;
    });

    &__block {
        padding-top: 40px;
        padding-bottom: 100px;
        h2 {
            .h2_mob(@optiColor);
        }
        .slider {
            .dots(@dotGray, -50px);
            padding-top: 40px;

            .flickity-viewport {
                overflow: initial;
            }

            .slide {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                .item {
                    margin-bottom: 40px;
                    &:nth-child(even) {
                        margin-bottom: 0;
                    }
                    .desc {
                        .desc_mob(@fontColor);
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            left: calc(50% - 10px);
                            top: -20px;
                            background: url(../img/principles/nut_before.svg) no-repeat;
                            background-size: contain;
                            width: 10px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }
}
