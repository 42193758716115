body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1360px;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

.debug {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 999999999;
}

.debug > div {
  height: inherit;
  max-width: 1360px;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

.debug > div > div {
  height: inherit;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.debug > div > div > div {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(8.333333333333334% - 20px);
  height: inherit;
  outline: 1px solid #ff0;
  background: rgba(0, 0, 0, 0.5);
}

@font-face {
  font-family: Mariupol;
  src: url("../fonts/Mariupol/WOFF/Mariupol-Regular.woff") format("woff"), url("../fonts/Mariupol/OTF/Mariupol-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
}

@font-face {
  font-family: Mariupol;
  src: url("../fonts/Mariupol/WOFF/Mariupol-Bold.woff") format("woff"), url("../fonts/Mariupol/OTF/Mariupol-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: Mariupol;
  src: url("../fonts/Mariupol/WOFF/Mariupol-Bold.woff") format("woff"), url("../fonts/Mariupol/OTF/Mariupol-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
  font-display: fallback;
}

* {
  font-size: 16px;
  font-family: Mariupol, Arial, sans-serif;
}

body {
  background: #fff;
  color: #000;
  font-family: Mariupol, Arial, sans-serif;
  font-weight: bold;
  height: 100%;
  min-height: auto;
  position: relative;
  word-wrap: break-word;
  color: #4C4C4C;
  box-sizing: border-box;
  overflow-x: hidden;
}

.rembit_why_mob_two,
.rembit_why_mob_one,
.rembit_principles_mob,
.rembit_scheme,
.rembit_reviews {
  overflow: hidden;
}

strong {
  font-weight: bold;
}

ul,
li,
a {
  font-family: Mariupol;
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

.wrapper h1,
.wrapper h2,
.wrapper h3,
.wrapper h4,
.wrapper h5 {
  padding: 0 !important;
  border-bottom: none !important;
}

.fancybox-slide--html {
  padding: 0;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #E2EFFF;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: #784FD1;
  box-shadow: none;
}

[disabled] {
  cursor: not-allowed;
}

select[disabled] + .select2 .select2-selection {
  background: #dad8d8;
  border: 1px solid #dad8d8;
  cursor: not-allowed;
}

.select2-container {
  top: -13px;
}

.select2-container--default .select2-selection--single {
  height: 45px;
  border: 1px solid #E2EFFF;
  outline: none;
  border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  font-weight: normal;
  line-height: 45px;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #9D9D9D;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-weight: normal;
  line-height: 45px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  padding-left: 17px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  border-bottom: 1px solid #E2EFFF;
  padding-left: 17px;
  padding-right: 30px;
  height: 45px;
  color: #000000;
  font-weight: normal;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: 1px solid purple;
  outline-offset: 0px;
}

.select2-search--dropdown {
  padding: 0;
  outline: none;
}

.select2-dropdown {
  border: 1px solid #E2EFFF;
}

.select2-results__option {
  font-weight: normal;
  line-height: 45px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 0;
  padding-left: 17px;
}

.select2-container--default .select2-results > .select2-results__options {
  min-height: 320px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #008BFF;
  color: #ffffff;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #E2EFFF;
  color: #000000;
}

.select2-container--default .select2-results__option[aria-selected=true]:hover {
  color: #000000;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 13px;
  height: 12px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: unset;
  border-style: unset;
  border-width: unset;
  height: unset;
  left: unset;
  margin-left: unset;
  margin-top: unset;
  top: unset;
  width: unset;
  background: url(../img/map/arrow.svg) no-repeat;
  background-size: contain;
  width: 13px;
  height: 12px;
  top: 17px;
  right: 15px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b.arrow_up {
  animation: select_arrow_up 0.25s linear forwards;
}

@keyframes select_arrow_up {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.top_button {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 60px;
  height: 50px;
  background: url(../img/optional/top_button.svg) no-repeat;
  background-size: contain;
  transition: 0.4s;
  position: fixed;
  right: 50px;
  bottom: -100px;
}

.top_button:hover {
  transform: translateY(-2px);
}

.top_button.visible {
  bottom: 50px;
}

#modal_call {
  display: none;
  background: #E2EFFF url(../img/questions/back.png) no-repeat;
  background-size: cover;
  width: 410px;
  height: 500px;
  overflow: hidden;
}

#modal_call .title {
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #4C4C4C;
  margin: 0 auto;
  margin-bottom: 22px;
}

#modal_call .desc {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 25px;
}

#modal_call form .input_block input {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding-left: 20px;
  outline: none;
  border: none;
  color: #4C4C4C;
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
}

#modal_call form .input_block input::placeholder {
  color: #9D9D9D;
}

#modal_call form .input_block input:first-child {
  margin-bottom: 20px;
}

#modal_call form .action {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

#modal_call form .action:hover {
  background: #66B9FF;
}

#modal_call form .error_name {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
}

#modal_call form .error_name::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

#modal_call form .error_name.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

#modal_call form .error_tel {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
  margin: 0;
  margin-top: 10px;
}

#modal_call form .error_tel::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

#modal_call form .error_tel.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

#modal_call .conditions {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #9D9D9D;
}

#modal_call.fancybox-content {
  padding-left: 30px;
  padding-right: 30px;
}

#modal_accepted {
  display: none;
  background: #E2EFFF url(../img/questions/back.png) no-repeat;
  background-size: cover;
  width: 410px;
  height: 500px;
  overflow: hidden;
  position: relative;
}

#modal_accepted::after {
  content: '';
  background: url(../img/optional/master_accepted.svg) no-repeat;
  width: 324px;
  height: 325px;
  position: absolute;
  bottom: -1px;
  left: 56px;
}

#modal_accepted .title {
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #4C4C4C;
  width: 250px;
  margin: 0 auto;
  margin-bottom: 25px;
}

#modal_accepted .desc {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  width: 260px;
  margin: 0 auto;
  margin-bottom: 25px;
}

#modal_accepted.fancybox-content {
  padding-left: 30px;
  padding-right: 30px;
}

.rembit_breadcrumb {
  margin-top: 20px;
}

.rembit_breadcrumb__block {
  box-sizing: border-box;
  background: #E2EFFF;
  padding: 19px 30px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.rembit_breadcrumb__block:before {
  content: '';
  position: absolute;
  background: #784fd1;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
}

.rembit_breadcrumb__block:after {
  content: '';
  position: absolute;
  background: #784fd1;
  width: 8px;
  height: 8px;
  left: unset;
  top: unset;
  right: 0;
  bottom: 0;
}

.rembit_breadcrumb__block li {
  margin-right: 10px;
}

.rembit_breadcrumb__block li.active {
  color: #4E16C5;
  isplay: none;
}

.rembit_breadcrumb__block li a {
  color: #4C4C4C;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
}

.rembit_breadcrumb__block li a img {
  width: 10px;
  height: 12px;
  margin-left: 10px;
  padding-bottom: 1px;
}

.rembit_breadcrumb__block li a:hover {
  transition: 0.3s;
  color: #008BFF;
}

.rembit_head {
  padding: 21px 0 21px 0;
}

.rembit_head__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rembit_head__logo {
  display: flex;
  width: calc(25% - 20px);
  cursor: pointer;
}

.rembit_head__logo:hover .text {
  color: #008BFF;
}

.rembit_head__logo:hover .icon {
  fill: #008BFF;
}

.rembit_head__logo .icon {
  fill: #4E16C5;
  width: 59px;
  height: 53px;
  transition: 0.3s;
}

.rembit_head__logo .text {
  margin-left: 13px;
  color: #4C4C4C;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.3s;
}

.rembit_head__logo .text .title {
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 0.04em;
}

.rembit_head__logo .text .desc {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.014em;
}

.rembit_head__search {
  display: flex;
  align-items: center;
  margin-right: 47px;
  width: calc(8.333333333333334% - 20px);
}

.rembit_head__search .icon {
  fill: #333333;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
}

.rembit_head__search .title {
  color: #333333;
  font-weight: 500;
  line-height: 14px;
  font-size: 12px;
  min-width: 83px;
}

.rembit_head__time {
  display: flex;
  align-items: center;
  width: calc(8.333333333333334% - 20px);
  margin-right: 65px;
}

.rembit_head__time .icon {
  fill: #333333;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 10px;
}

.rembit_head__time .text {
  min-width: 103px;
}

.rembit_head__time .text .title {
  color: #9D9D9D;
  font-weight: 500;
  line-height: 14px;
  font-size: 12px;
}

.rembit_head__time .text .desc {
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  color: #4C4C4C;
}

.rembit_head__cost {
  display: flex;
  align-items: center;
  width: calc(16.666666666666668% - 20px);
  cursor: pointer;
}

.rembit_head__cost:hover .title {
  color: #008BFF;
}

.rembit_head__cost:hover .icon {
  fill: #008BFF;
}

.rembit_head__cost .icon {
  fill: #4E16C5;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  margin-right: 12px;
  transition: 0.3s;
}

.rembit_head__cost .title {
  min-width: 111px;
  color: #4C4C4C;
  font-weight: 500;
  line-height: 16px;
  font-size: 14px;
  transition: 0.3s;
}

.rembit_head__question {
  display: flex;
  align-items: center;
  width: calc(16.666666666666668% - 20px);
  cursor: pointer;
}

.rembit_head__question:hover .title {
  color: #008BFF;
}

.rembit_head__question:hover .icon {
  fill: #008BFF;
}

.rembit_head__question .icon {
  fill: #4E16C5;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  margin-right: 12px;
  transition: 0.3s;
}

.rembit_head__question .title {
  min-width: 111px;
  color: #4C4C4C;
  font-weight: 500;
  line-height: 16px;
  font-size: 14px;
  transition: 0.3s;
}

.rembit_head__call {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: column;
  width: calc(25% - 20px);
  width: 263px;
}

.rembit_head__call .title {
  color: #9D9D9D;
  font-weight: 500;
  line-height: 16px;
  font-size: 14px;
}

.rembit_head__call .tel {
  margin-top: -10px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.rembit_head__call .tel:hover .number {
  color: #008BFF;
}

.rembit_head__call .tel:hover .icon {
  fill: #008BFF;
}

.rembit_head__call .tel .icon {
  fill: #4E16C5;
  width: 38px;
  height: 38px;
  margin-right: 14px;
  transition: 0.3s;
}

.rembit_head__call .tel .number {
  color: #4C4C4C;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  transition: 0.3s;
}

.rembit_head_mob {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px -4px 10px #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.rembit_head_mob__block {
  display: flex;
  justify-content: space-between;
  padding: 11px 0;
}

.rembit_head_mob__block .logo {
  display: flex;
  align-items: center;
}

.rembit_head_mob__block .logo .icon {
  fill: #4E16C5;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.rembit_head_mob__block .logo .title {
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
}

.rembit_head_mob__block .burger {
  width: 25px;
  height: 18px;
  fill: #4C4C4C;
  transition: 0.15s;
}

.rembit_head_mob__block .burger.hide {
  opacity: 0;
}

.rembit_head_mob__block .tel {
  margin: 0 30px 0 auto;
}

.rembit_head_mob__block .tel svg {
  width: 18px;
  height: 18px;
  fill: #4E16C5;
}

.rembit_bottom_mob {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px #000000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 43px;
}

.rembit_bottom_mob .wrapper {
  padding-left: 0;
}

.rembit_bottom_mob__block {
  display: flex;
  justify-content: space-between;
}

.rembit_bottom_mob__block .call {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  background: #784FD1;
  height: 43px;
  width: 50%;
}

.rembit_bottom_mob__block .right_block {
  padding: 11px 0;
}

.rembit_bottom_mob__block .right_block .burger {
  width: 25px;
  height: 18px;
  fill: #4C4C4C;
  transition: 0.15s;
}

.rembit_bottom_mob__block .right_block .burger.hide {
  opacity: 0;
}

.rembit_bottom_mob__block .right_block .tel {
  margin: 0 30px 0 auto;
}

.rembit_bottom_mob__block .right_block .tel svg {
  width: 18px;
  height: 18px;
  fill: #4E16C5;
}

.rembit_mob_menu {
  z-index: 1;
  background: #fff;
  top: 42px;
  padding-top: 22px;
  width: 100%;
  height: calc(100vh - 64px);
  position: fixed;
  overflow-y: auto;
  left: -100%;
}

.rembit_mob_menu.bottom {
  top: 0;
  height: calc(100vh - 43px);
}

.rembit_mob_menu.show {
  animation: slideLeftShow 0.3s linear forwards;
}

.rembit_mob_menu.close {
  animation: slideLeftClose 0.3s linear forwards;
}

.rembit_mob_menu .info_block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(157, 157, 157, 0.1);
  margin-bottom: 30px;
}

.rembit_mob_menu .info_block .time {
  display: flex;
  flex-direction: column;
}

.rembit_mob_menu .info_block .time .top {
  display: flex;
  margin-bottom: 5px;
}

.rembit_mob_menu .info_block .time .top .icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.rembit_mob_menu .info_block .time .top .title {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #9D9D9D;
}

.rembit_mob_menu .info_block .time .desc {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4C4C4C;
  padding-left: 19px;
}

.rembit_mob_menu .info_block .phone .title {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #9D9D9D;
  margin-bottom: 5px;
}

.rembit_mob_menu .info_block .phone .tel {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4C4C4C;
}

.rembit_mob_menu .main_menu .main_item {
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.rembit_mob_menu .main_menu .main_item:last-child {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(157, 157, 157, 0.1);
  padding-bottom: 35px;
}

.rembit_mob_menu .main_menu .main_item .icon {
  fill: #4E16C5;
  margin-right: 15px;
}

.rembit_mob_menu .main_menu .main_item .icon:nth-child(1) {
  width: 21px;
  height: 17px;
}

.rembit_mob_menu .main_menu .main_item .icon:nth-child(2) {
  width: 23px;
  height: 29px;
}

.rembit_mob_menu .main_menu .main_item .icon:nth-child(3) {
  width: 20px;
  height: 20px;
}

.rembit_mob_menu .main_menu .main_item .icon:nth-child(4) {
  width: 20px;
  height: 20px;
}

.rembit_mob_menu .main_menu .main_item .arrow {
  fill: #4E16C5;
  width: 17px;
  height: 15px;
  margin: 0 0 0 auto;
}

.rembit_mob_menu .main_menu .optional_block {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  color: #4C4C4C;
  flex-direction: column;
  margin-top: 20px;
}

.rembit_mob_menu .main_menu .optional_block:last-child {
  margin-bottom: 0;
}

.rembit_mob_menu .main_menu .optional_block .icon {
  fill: #4E16C5;
  margin-right: 20px;
}

.rembit_mob_menu .main_menu .optional_block .icon:nth-child(1) {
  width: 29px;
  height: 29px;
}

.rembit_mob_menu .main_menu .optional_block .icon:nth-child(2) {
  width: 32px;
  height: 32px;
}

.rembit_mob_menu .main_menu .optional_block .item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  color: #4C4C4C;
}

.rembit_mob_menu .main_menu .optional_block .item:last-child {
  margin-bottom: 0;
}

.rembit_mob_menu .main_menu .optional_block .item .icon {
  fill: #4E16C5;
  margin-right: 20px;
}

.rembit_mob_menu .main_menu .optional_block .item .icon:nth-child(1) {
  width: 29px;
  height: 29px;
}

.rembit_mob_menu .main_menu .optional_block .item .icon:nth-child(2) {
  width: 32px;
  height: 32px;
}

.rembit_mob_menu .action {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  background: #784FD1;
  height: 35px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 68px;
}

.rembit_mob_menu .action.hide {
  display: none;
}

.rembit_mob_menu .action:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
  transition: 0.15s;
}

@keyframes slideLeftShow {
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
  }
}

@keyframes slideLeftClose {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}

@keyframes slideRighttShow {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

@keyframes slideRightClose {
  0% {
    left: 0%;
  }

  100% {
    left: 100%;
  }
}

.rembit_mob_menu__second,
.rembit_mob_menu__second_info {
  z-index: 2;
  background: #F7F7F7;
  top: 42px;
  width: 100%;
  height: calc(100vh - 42px);
  position: fixed;
  overflow-y: auto;
  left: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_mob_menu__second.bottom,
.rembit_mob_menu__second_info.bottom {
  top: 0;
  height: calc(100vh - 43px);
}

.rembit_mob_menu__second.show,
.rembit_mob_menu__second_info.show {
  animation: slideRighttShow 0.3s linear forwards;
}

.rembit_mob_menu__second.close,
.rembit_mob_menu__second_info.close {
  animation: slideRightClose 0.3s linear forwards;
}

.rembit_mob_menu__second .main_title,
.rembit_mob_menu__second_info .main_title {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  padding-left: 15px;
  background: #fff;
}

.rembit_mob_menu__second .main_title .arrow,
.rembit_mob_menu__second_info .main_title .arrow {
  width: 17px;
  height: 15px;
  transform: rotate(180deg);
  fill: #4E16C5;
  margin-right: 15px;
}

.rembit_mob_menu__second .tech,
.rembit_mob_menu__second_info .tech {
  padding-bottom: 75px;
}

.rembit_mob_menu__second .tech li,
.rembit_mob_menu__second_info .tech li {
  display: flex;
  align-items: center;
  height: 55px;
}

.rembit_mob_menu__second .tech li.other,
.rembit_mob_menu__second_info .tech li.other {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ffffff;
}

.rembit_mob_menu__second .tech li .icon,
.rembit_mob_menu__second_info .tech li .icon {
  width: 30px;
  height: 30px;
  fill: #4C4C4C;
  margin-right: 22px;
}

.rembit_mob_menu__second .tech li .link,
.rembit_mob_menu__second_info .tech li .link {
  color: #4C4C4C;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.rembit_mob_menu__second .tech li .arrow,
.rembit_mob_menu__second_info .tech li .arrow {
  width: 17px;
  height: 15px;
  fill: #4E16C5;
  margin: 0 0 0 auto;
}

.rembit_mob_menu__third {
  z-index: 3;
  background: #F7F7F7;
  top: 42px;
  width: 100%;
  height: calc(100vh - 42px);
  position: fixed;
  overflow-y: auto;
  left: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  background: #ffffff;
  overflow-y: scroll;
}

.rembit_mob_menu__third.bottom {
  top: 0;
  height: calc(100vh - 43px);
}

.rembit_mob_menu__third.show {
  animation: slideRighttShow 0.3s linear forwards;
}

.rembit_mob_menu__third.close {
  animation: slideRightClose 0.3s linear forwards;
}

.rembit_mob_menu__third .main_title {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  padding-left: 15px;
  background: #fff;
}

.rembit_mob_menu__third .main_title .arrow {
  width: 17px;
  height: 15px;
  transform: rotate(180deg);
  fill: #4E16C5;
  margin-right: 15px;
}

.rembit_mob_menu__third .wrapper {
  padding: 0;
}

.rembit_mob_menu__third .table_finish {
  padding-bottom: 75px;
  display: none;
}

.rembit_mob_menu__third .table_finish.show {
  display: block !important;
}

.rembit_mob_menu__third .table_finish .item {
  display: none;
}

.rembit_mob_menu__third .table_finish .item.show {
  display: block !important;
}

.rembit_mob_menu__third .table_finish .item .all {
  box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  background: #F7F7F7;
  height: 62px;
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.rembit_mob_menu__third .table_finish .item .list {
  margin-bottom: 3px;
}

.rembit_mob_menu__third .table_finish .item .list.active .title .arrow {
  transform: rotate(270deg);
}

.rembit_mob_menu__third .table_finish .item .list .title {
  padding: 0 15px;
  background: #F7F7F7;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rembit_mob_menu__third .table_finish .item .list .title .link {
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_mob_menu__third .table_finish .item .list .title .arrow {
  width: 17px;
  height: 15px;
  fill: #4E16C5;
  transform: rotate(90deg);
  transition: 0.3s;
}

.rembit_mob_menu__third .table_finish .item .list .scroll {
  background: #ffffff;
  padding: 15px;
  display: none;
}

.rembit_mob_menu__third .table_finish .item .list .scroll li {
  height: 55px;
  display: flex;
  align-items: center;
}

.rembit_mob_menu__third .table_finish .item .list .scroll li a {
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.rembit_mob_menu__third .table_finish .item .list .scroll li a .logo {
  width: 40px;
  margin-right: 10px;
}

.wrapper_menu {
  z-index: 10;
  position: relative;
  background: #ECECEC;
  width: 100%;
}

.wrapper_menu .wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.wrapper_menu .menu_top {
  background: #ECECEC;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  width: 1100px;
}

.wrapper_menu .menu_top li {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 25px;
  transition: 0.3s;
}

.wrapper_menu .menu_top li a {
  color: #4C4C4C;
  text-decoration: none;
}

.wrapper_menu .menu_top li.menu_top__submenu:first-of-type:hover {
  background: #008BFF;
}

.wrapper_menu .menu_top li.menu_top__submenu:first-of-type:hover .burger,
.wrapper_menu .menu_top li.menu_top__submenu:first-of-type:hover .first {
  transition: 0s;
  color: #fff;
}

.wrapper_menu .menu_top .burger {
  border-radius: 8px;
  color: #4C4C4C;
  cursor: pointer;
  user-select: none;
  width: 30px;
  height: 22px;
}

.wrapper_menu .menu_top .burger:before,
.wrapper_menu .menu_top .burger:after {
  border-radius: inherit;
  background: currentColor;
  content: "";
  display: block;
  height: 2px;
  transition: 0.3s;
}

.wrapper_menu .menu_top .burger:after {
  filter: drop-shadow(0 -10px currentColor);
  transform: translateY(18px);
}

.wrapper_menu .menu_top .burger.open:before {
  transform: translateY(10px) rotateZ(40deg);
}

.wrapper_menu .menu_top .burger.open:after {
  filter: initial;
  transform: translateY(8px) rotateZ(-40deg);
}

.wrapper_menu .menu_top__submenu {
  box-sizing: border-box;
  background: #F7F7F7;
  width: 25%;
}

.wrapper_menu .menu_top__submenu:nth-of-type(n+2):nth-of-type(-n+4) {
  border-left: 1px solid #E4E4E4;
}

.wrapper_menu .menu_top__submenu.third .menu_top__submenu_list_optilist {
  width: 485px;
}

.wrapper_menu .menu_top__submenu.third .block_double .list {
  width: 100%;
}

.wrapper_menu .menu_top__submenu.third .block_double .list:first-child {
  margin: 0;
}

.wrapper_menu .menu_top__submenu.info a {
  justify-content: left;
}

.wrapper_menu .menu_top__submenu.info .menu_top__submenu_list {
  background: #ffffff;
  border: 1px solid #E2EFFF;
}

.wrapper_menu .menu_top__submenu.info .menu_top__submenu_list.active {
  opacity: 1;
  display: block;
}

.wrapper_menu .menu_top__submenu.info .menu_top__submenu_list .time {
  display: none;
  height: 40px;
  padding-top: 25px;
  padding-left: 25px;
}

.wrapper_menu .menu_top__submenu.info .menu_top__submenu_list .time .text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9D9D9D;
}

.wrapper_menu .menu_top__submenu.info .menu_top__submenu_list .time .icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  fill: #9D9D9D;
  margin-right: 10px;
}

.wrapper_menu .menu_top__submenu.info .menu_top__submenu_list.hover {
  animation: submenu_hover 0.3s ease forwards;
}

.wrapper_menu .menu_top__submenu.info .menu_top__submenu_list li:hover a {
  transition: 0.3s;
  color: #008BFF;
}

.wrapper_menu .menu_top__submenu.md {
  height: 100%;
  width: 100%;
  background: #ECECEC;
  cursor: pointer;
  display: none;
  padding-left: 15px;
}

.wrapper_menu .menu_top__submenu.md .catalog {
  display: flex;
  align-items: center;
  height: 100%;
}

.wrapper_menu .menu_top__submenu.md .catalog .title {
  color: #4C4C4C;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu.md .catalog.active .arrow {
  fill: #ffffff;
  transform: rotate(0) translateX(-2px);
}

.wrapper_menu .menu_top__submenu.md .catalog.active .title {
  color: #ffffff;
}

.wrapper_menu .menu_top__submenu.md .arrow {
  fill: #784fd1;
  width: 12px;
  min-width: 12px;
  height: 11px;
  margin-right: 15px;
  transform: rotate(90deg) translateX(-2px);
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu.md .main_menu {
  display: none;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #ECECEC;
  position: absolute;
  left: 15px;
  right: 15px;
}

.wrapper_menu .menu_top__submenu.md .main_menu .main_item {
  cursor: pointer;
  height: 48px;
}

.wrapper_menu .menu_top__submenu.md .main_menu .main_item:hover {
  color: #008BFF;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu.md .main_menu .main_item .icon {
  width: 17px;
  height: 17px;
  margin-right: 15px;
  fill: #4E16C5;
}

.wrapper_menu .menu_top__submenu.md .main_menu .main_item .icon.faults {
  height: 24px;
}

.wrapper_menu .menu_top__submenu.md .tech {
  display: none;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #ECECEC;
  position: absolute;
  left: 15px;
  right: 15px;
}

.wrapper_menu .menu_top__submenu.md .tech li {
  height: 48px;
}

.wrapper_menu .menu_top__submenu.md .tech li.title {
  color: #008BFF;
}

.wrapper_menu .menu_top__submenu.md .tech li.title .arrow {
  transform: rotate(-180deg);
  fill: #008BFF;
}

.wrapper_menu .menu_top__submenu.md .tech li.title:hover {
  transition: 0.3s;
  color: #784FD1;
  background: none;
}

.wrapper_menu .menu_top__submenu.md .tech li.title:hover .arrow {
  fill: #784FD1;
}

.wrapper_menu .menu_top__submenu.md .tech li.other {
  margin-top: 15px;
}

.wrapper_menu .menu_top__submenu.md .tech li:hover {
  background: #008BFF;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu.md .tech li:hover .icon,
.wrapper_menu .menu_top__submenu.md .tech li:hover .arrow {
  fill: #fff;
}

.wrapper_menu .menu_top__submenu.md .tech li:hover .link {
  color: #fff;
}

.wrapper_menu .menu_top__submenu.md .tech li .icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-right: 20px;
  fill: #4C4C4C;
}

.wrapper_menu .menu_top__submenu.md .tech li .link {
  color: #4C4C4C;
}

.wrapper_menu .menu_top__submenu.md .tech li .arrow {
  transform: rotate(0deg);
  margin-left: 15px;
}

.wrapper_menu .menu_top__submenu.md .table_finish {
  display: none;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ECECEC;
  position: absolute;
  left: 15px;
  right: 15px;
}

.wrapper_menu .menu_top__submenu.md .table_finish .title,
.wrapper_menu .menu_top__submenu.md .table_finish .all {
  font-size: 16px;
  height: 40px;
  color: #008BFF;
  display: flex;
  align-items: center;
}

.wrapper_menu .menu_top__submenu.md .table_finish .title:hover,
.wrapper_menu .menu_top__submenu.md .table_finish .all:hover {
  transition: 0.3s;
  color: #784FD1;
}

.wrapper_menu .menu_top__submenu.md .table_finish .title:hover .arrow,
.wrapper_menu .menu_top__submenu.md .table_finish .all:hover .arrow {
  fill: #784FD1;
}

.wrapper_menu .menu_top__submenu.md .table_finish .title .arrow,
.wrapper_menu .menu_top__submenu.md .table_finish .all .arrow {
  fill: #008BFF;
  transform: rotate(-180deg);
}

.wrapper_menu .menu_top__submenu.md .table_finish .all {
  text-decoration: underline;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_double {
  display: flex;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_double .list {
  width: 45%;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_double .list:first-child {
  margin-right: 10%;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_double .list .title {
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  color: #9b9b9b;
  margin-bottom: 4px;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_double .list ul li {
  font-size: 16px;
  height: 40px;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_double .list ul li:hover a {
  transition: 0.3s;
  color: #008BFF;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list {
  width: 100%;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list .title {
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  color: #9b9b9b;
  margin-bottom: 4px;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list ul li {
  width: 45%;
  font-size: 16px;
  height: 40px;
  box-sizing: border-box;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list ul li:nth-child(odd) {
  margin-right: 10%;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list ul li:hover a {
  transition: 0.3s;
  color: #008BFF;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list ul li a .logo {
  width: 40px;
  margin-right: 5px;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list ul li a.all {
  color: #008BFF;
  text-decoration: underline;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu.md .table_finish .block_one .list ul li a.all:hover {
  text-decoration: none;
}

.wrapper_menu .menu_top__submenu.md .table_finish .item {
  display: none;
}

.wrapper_menu .menu_top__submenu:hover {
  background: #008BFF;
  transition: 0.3s;
  cursor: pointer;
}

.wrapper_menu .menu_top__submenu:hover > a {
  color: #ffffff;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu:hover > a .icon {
  fill: #ffffff;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu_title {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 15px;
  letter-spacing: 0.02em;
}

.wrapper_menu .menu_top__submenu_title .icon {
  margin-right: 15px;
  fill: #4E16C5;
  width: 20px;
  height: 23px;
}

.wrapper_menu .menu_top__submenu_title .icon.faults {
  width: 22px;
  height: 30px;
}

.wrapper_menu .menu_top__submenu_title.first .icon {
  margin-right: 20px;
}

.wrapper_menu .menu_top__submenu_list {
  z-index: 900;
  display: none;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 55px;
  width: 325px;
  background: #ECECEC;
  padding-top: 15px;
  padding-bottom: 10px;
}

.wrapper_menu .menu_top__submenu_list li {
  padding: 0;
  height: 48px;
  font-size: 16px;
}

.wrapper_menu .menu_top__submenu_list li a {
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 25px;
}

.wrapper_menu .menu_top__submenu_list li a .link {
  width: 215px;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu_list li a .arrow {
  fill: #4C4C4C;
  width: 12px;
  height: 10px;
}

.wrapper_menu .menu_top__submenu_list li.other {
  margin-top: 15px;
}

.wrapper_menu .menu_top__submenu_list li:hover {
  cursor: pointer;
  background: #fff;
}

.wrapper_menu .menu_top__submenu_list li:hover > a {
  color: #008BFF;
}

.wrapper_menu .menu_top__submenu_list li:hover > a .icon {
  fill: #008BFF;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu_list li:hover > a .arrow {
  fill: #008BFF;
}

.wrapper_menu .menu_top__submenu_list li .icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-right: 20px;
  fill: #4C4C4C;
}

.wrapper_menu .menu_top__submenu_list.hover {
  animation: submenu_hover 0.3s ease forwards;
}

.wrapper_menu .menu_top__submenu_list_optilist {
  box-sizing: border-box;
  z-index: 900;
  display: none;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 575px;
  background: #fff;
  padding-top: 15px;
  padding-left: 35px;
  padding-bottom: 30px;
}

.wrapper_menu .menu_top__submenu_list_optilist.hover {
  animation: submenu_hover 0.3s ease forwards;
}

.wrapper_menu .menu_top__submenu_list_optilist .title {
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  color: #9B9B9B;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_double {
  display: flex;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_double .list {
  width: 45%;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_double .list:first-child {
  margin-right: 10%;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_double ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_double ul li {
  height: 40px;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_double ul li:hover a {
  transition: 0.3s;
  color: #008BFF;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_double ul li a {
  color: #4C4C4C;
  padding-left: 0;
  transition: 0.3s;
  font-size: 14px;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul li {
  height: 40px;
  width: 45%;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul li:nth-child(odd) {
  margin-right: 10%;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul li:hover a {
  transition: 0.3s;
  color: #008BFF;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul li a {
  color: #4C4C4C;
  padding-left: 0;
  transition: 0.3s;
  font-size: 14px;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul li a .logo {
  width: 40px;
  margin-right: 5px;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul li a.all {
  color: #008BFF;
  text-decoration: underline;
  transition: 0.3s;
}

.wrapper_menu .menu_top__submenu_list_optilist .block_one ul li a.all:hover {
  text-decoration: none;
}

.wrapper_menu .call {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  background: #784FD1;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.04em;
  width: 260px;
}

.wrapper_menu .call:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
  transition: 0.15s;
}

.dimming {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
  display: none;
  animation: submenu_hover 0.3s ease forwards;
}

@keyframes submenu_hover {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes screwdriver {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-20px);
  }
}

.rembit_bid {
  margin-top: 20px;
}

.rembit_bid .call {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  background: #784FD1;
  height: 35px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: none;
}

.rembit_bid .call:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
  transition: 0.15s;
}

.rembit_bid__block {
  display: flex;
  position: relative;
}

.rembit_bid__block .rembit_bid__main_block {
  background: #E2EFFF url(../img/bid/back.png);
  width: 1015px;
  height: 350px;
  display: flex;
  margin-right: 20px;
  position: relative;
}

.rembit_bid__block .rembit_bid__main_block::before {
  content: '';
  z-index: 1;
  background: url(../img/bid/screwdriver.svg) no-repeat;
  background-size: contain;
  width: 198px;
  height: 27px;
  position: absolute;
  bottom: 53px;
  right: -40px;
  animation: screwdriver 2s linear infinite alternate;
}

.rembit_bid__block .rembit_bid__main_block::after {
  content: '';
  background: url(../img/bid/master.svg) no-repeat;
  background-size: contain;
  width: 494px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
}

.rembit_bid__block .rembit_bid__main_block .text {
  color: #4C4C4C;
  margin-top: 75px;
  padding-left: 457px;
}

.rembit_bid__block .rembit_bid__main_block .text h1 {
  font-weight: bold;
  line-height: 50px;
  font-size: 45px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.rembit_bid__block .rembit_bid__main_block .text h1.stub {
  font-size: 30px;
  line-height: 35px;
}

.rembit_bid__block .rembit_bid__main_block .text .desc {
  font-weight: normal;
  line-height: 23px;
  font-size: 18px;
  letter-spacing: 0.02em;
}

.rembit_bid__block .rembit_bid__main_block .text .desc .lg_br {
  display: none;
}

.rembit_bid__form_bid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 325px;
  height: 350px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  background: #E2EFFF;
  box-sizing: border-box;
}

.rembit_bid__form_bid input {
  box-sizing: border-box;
  width: 265px;
  height: 45px;
  padding-left: 20px;
  outline: none;
  border: none;
  color: #4C4C4C;
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.rembit_bid__form_bid input::placeholder {
  color: #9D9D9D;
}

.rembit_bid__form_bid .desc {
  color: #9D9D9D;
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
}

.rembit_bid__form_bid button {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
}

.rembit_bid__form_bid button:hover {
  background: #66B9FF;
}

.rembit_bid__form_bid .error_name {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
  font-size: 13px;
  margin-bottom: 5px;
}

.rembit_bid__form_bid .error_name::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

.rembit_bid__form_bid .error_name.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

.rembit_bid__form_bid .error_tel {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
  font-size: 13px;
  margin: 0;
  margin-top: 5px;
}

.rembit_bid__form_bid .error_tel::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

.rembit_bid__form_bid .error_tel.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

.rembit_bid_separate {
  margin-top: 20px;
}

.rembit_bid_separate .call {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  background: #784FD1;
  height: 35px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: none;
}

.rembit_bid_separate .call:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
  transition: 0.15s;
}

.rembit_bid_separate__block {
  display: flex;
  position: relative;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block {
  background: #ffffff;
  width: 1015px;
  height: 350px;
  display: flex;
  margin-right: 20px;
  position: relative;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block::before {
  content: '';
  z-index: 1;
  background: url(../img/bid/screwdriver.svg) no-repeat;
  background-size: contain;
  width: 198px;
  height: 27px;
  position: absolute;
  bottom: 53px;
  right: -40px;
  animation: screwdriver 2s linear infinite alternate;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block::after {
  content: '';
  background: url(../img/bid/md_master.png) no-repeat;
  background-size: contain;
  width: 104px;
  height: 152px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
  display: none;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .block_f_img {
  width: 457px;
  max-width: 457px;
  display: flex;
  height: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .block_f_img img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .block_f_img img.brand {
  max-height: 180px;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .text {
  width: 755px;
  padding-top: 75px;
  padding-left: 30px;
  padding-right: 30px;
  color: #4C4C4C;
  background: #E2EFFF url(../img/bid/back.png);
  box-sizing: border-box;
  position: relative;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .text h1 {
  font-weight: bold;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc {
  font-weight: normal;
  line-height: 23px;
  font-size: 18px;
  letter-spacing: 0.02em;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc .lg_br {
  display: none;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .text .price {
  font-weight: normal;
  line-height: 33px;
  font-size: 23px;
  letter-spacing: 0.02em;
  font-weight: bold;
  color: #784FD1;
  position: absolute;
  bottom: 15px;
  left: 30px;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .text .call_separate {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
  width: 300px;
  position: absolute;
  bottom: 40px;
  display: none;
}

.rembit_bid_separate__block .rembit_bid_separate__main_block .text .call_separate:hover {
  background: #66B9FF;
}

.rembit_bid_separate__form_bid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 325px;
  height: 350px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  background: #E2EFFF;
  box-sizing: border-box;
}

.rembit_bid_separate__form_bid input {
  box-sizing: border-box;
  width: 265px;
  height: 45px;
  padding-left: 20px;
  outline: none;
  border: none;
  color: #4C4C4C;
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.rembit_bid_separate__form_bid input::placeholder {
  color: #9D9D9D;
}

.rembit_bid_separate__form_bid .desc {
  color: #9D9D9D;
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
}

.rembit_bid_separate__form_bid button {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
}

.rembit_bid_separate__form_bid button:hover {
  background: #66B9FF;
}

.rembit_bid_separate__form_bid .error_name {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
  font-size: 13px;
  margin-bottom: 5px;
}

.rembit_bid_separate__form_bid .error_name::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

.rembit_bid_separate__form_bid .error_name.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

.rembit_bid_separate__form_bid .error_tel {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
  font-size: 13px;
  margin: 0;
  margin-top: 5px;
}

.rembit_bid_separate__form_bid .error_tel::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

.rembit_bid_separate__form_bid .error_tel.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

.rembit_appliances {
  margin-top: 20px;
}

.rembit_appliances__block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.rembit_appliances__block .tech {
  height: 220px;
  width: 220px;
  background: url(../img/appliances/back_1.png) no-repeat;
  background-size: cover;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  box-sizing: border-box;
  position: relative;
}

.rembit_appliances__block .tech:nth-child(8n+2) {
  background: url(../img/appliances/back_2.png) no-repeat;
  background-size: cover;
}

.rembit_appliances__block .tech:nth-child(8n+3) {
  background: url(../img/appliances/back_3.png) no-repeat;
  background-size: cover;
}

.rembit_appliances__block .tech:nth-child(8n+4) {
  background: url(../img/appliances/back_4.png) no-repeat;
  background-size: cover;
}

.rembit_appliances__block .tech:nth-child(5) {
  background: url(../img/appliances/back_5.png) no-repeat;
  background-size: cover;
}

.rembit_appliances__block .tech:nth-child(6) {
  background: url(../img/appliances/back_3.png) no-repeat;
  background-size: cover;
}

.rembit_appliances__block .tech:nth-child(7) {
  background: url(../img/appliances/back_3.png) no-repeat;
  background-size: cover;
}

.rembit_appliances__block .tech:nth-child(6n) {
  margin-right: 0;
}

.rembit_appliances__block .tech:nth-child(n+7) {
  margin-top: 8px;
}

.rembit_appliances__block .tech:hover {
  transition: 0.3s;
  background: url(../img/appliances/back_hover.svg) no-repeat;
  background-size: cover;
}

.rembit_appliances__block .tech:hover .icon {
  transition: 0.2s;
  opacity: 0;
}

.rembit_appliances__block .tech:hover .title {
  transition: 0.3s;
  color: #ffffff;
}

.rembit_appliances__block .tech .price {
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  top: 78px;
  position: absolute;
  opacity: 0;
  display: none;
}

.rembit_appliances__block .tech .icon {
  width: 110px;
  height: 110px;
  margin-bottom: 20px;
}

.rembit_appliances__block .tech .title {
  color: #4C4C4C;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.04em;
}

.rembit_appliances__block_optional {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.rembit_appliances__block_optional .tech {
  height: 130px;
  width: 676px;
  background: url(../img/appliances/back_2_1.png) no-repeat;
  background-size: cover;
  margin-right: 8px;
  margin-top: 8px;
  padding-left: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.rembit_appliances__block_optional .tech:last-child {
  margin-right: 0;
}

.rembit_appliances__block_optional .tech:hover {
  transition: 0.3s;
  background: url(../img/appliances/opt_back_hover.svg) no-repeat;
  background-size: cover;
}

.rembit_appliances__block_optional .tech:hover .icon {
  transition: 0.2s;
  opacity: 0;
}

.rembit_appliances__block_optional .tech:hover .title {
  transition: 0.3s;
  color: #ffffff;
}

.rembit_appliances__block_optional .tech .price {
  font-weight: normal;
  line-height: 35px;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  position: absolute;
  opacity: 0;
  display: none;
}

.rembit_appliances__block_optional .tech .icon {
  width: 130px;
  height: 105px;
  fill: #4C4C4C;
  margin-right: 70px;
}

.rembit_appliances__block_optional .tech .title {
  color: #4C4C4C;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.04em;
}

.rembit_appliances_mob {
  display: none;
  padding-top: 25px;
  padding-bottom: 60px;
}

.rembit_appliances_mob__slider {
  width: 100%;
}

.rembit_appliances_mob__slider .flickity-page-dots {
  bottom: -38px;
}

.rembit_appliances_mob__slider .flickity-page-dots .dot {
  opacity: 1;
  background: url('../img/optional/dot_gray.svg') no-repeat;
  background-size: contain;
  width: 8px;
  border-radius: 0;
  transition: 0.3s;
}

.rembit_appliances_mob__slider .flickity-page-dots .dot.is-selected {
  background: url(../img/optional/dot_selected.svg) no-repeat;
  background-size: contain;
}

.rembit_appliances_mob__slider .slide {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.rembit_appliances_mob__slider .slide.optional .tech {
  margin: 0;
  padding: 0;
  padding-left: 15px;
  padding-right: 20px;
  margin-bottom: 2%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.rembit_appliances_mob__slider .slide.optional .tech .icon {
  width: 90px;
  max-width: 100px;
  margin-right: 35px;
}

.rembit_appliances_mob__slider .slide.optional .tech .price {
  position: absolute;
  top: 60px;
  left: 15px;
}

.rembit_appliances_mob__slider .slide .tech {
  width: 48%;
  height: 150px;
  max-height: 250px;
  background: url(../img/appliances/back_1.png) no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 2%;
  margin-bottom: 2%;
  padding-top: 20px;
}

.rembit_appliances_mob__slider .slide .tech:nth-child(2n) {
  margin-right: 0;
}

.rembit_appliances_mob__slider .slide .tech:nth-child(n+3) {
  margin-top: 0;
}

.rembit_appliances_mob__slider .slide .tech .price {
  font-weight: normal;
  font-size: 27px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  position: absolute;
  opacity: 0;
  top: 50px;
  display: none;
}

.rembit_appliances_mob__slider .slide .tech .icon {
  width: 70px;
  max-width: 70px;
  fill: #4C4C4C;
  padding-bottom: 15px;
}

.rembit_appliances_mob__slider .slide .tech .title {
  color: #4C4C4C;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
}

.rembit_why {
  margin-top: 20px;
  background: #3D0E92;
  height: 610px;
}

.rembit_why.visible {
  background: #3D0E92 url(../img/why/back.jpg) no-repeat;
  background-size: cover;
}

.rembit_why__block {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rembit_why__block__left_block {
  margin-top: 70px;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.rembit_why__block__left_block h2 {
  font-weight: bold;
}

.rembit_why__block__left_block .items_block {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}

.rembit_why__block__left_block .items_block .item {
  display: flex;
  align-items: center;
  width: 46%;
}

.rembit_why__block__left_block .items_block .item .icon {
  fill: #FFFFFF;
  width: 50px;
  height: 50px;
  margin-right: 25px;
}

.rembit_why__block__left_block .items_block .item .desc {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.02em;
  letter-spacing: 0.04em;
  text-transform: initial;
}

.rembit_why__block__left_block .items_block .item:nth-child(2n-1) {
  margin-right: 50px;
}

.rembit_why__block__left_block .items_block .item:nth-child(n+3) {
  margin-top: 60px;
}

.rembit_why__block__right_block {
  margin-top: 37px;
  width: 575px;
  min-width: 575px;
  height: 530px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 150px;
  box-sizing: border-box;
  position: relative;
}

.rembit_why__block__right_block::before {
  content: '';
  background: url(../img/why/block.svg) no-repeat;
  background-size: contain;
  width: 63px;
  height: 100px;
  position: absolute;
  top: 20px;
  right: -1px;
}

.rembit_why__block__right_block h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
}

.rembit_why__block__right_block .items_block {
  margin-top: 27px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.rembit_why__block__right_block .items_block .item {
  display: flex;
}

.rembit_why__block__right_block .items_block .item .icon {
  width: 50px;
  height: 50px;
  margin-right: 35px;
}

.rembit_why__block__right_block .items_block .item .desc {
  line-height: 23px;
  font-size: 18px;
  letter-spacing: 0.02em;
  font-weight: normal;
  color: #4C4C4C;
}

.rembit_why__block__right_block .items_block .item:nth-child(n+2) {
  margin-top: 60px;
}

.rembit_why_mob_one {
  background: #4B1BB1;
  display: none;
}

.rembit_why_mob_one__block {
  padding-top: 40px;
  padding-bottom: 100px;
}

.rembit_why_mob_one__block h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.rembit_why_mob_one__block .slider .flickity-page-dots {
  bottom: -50px;
}

.rembit_why_mob_one__block .slider .flickity-page-dots .dot {
  opacity: 1;
  background: url('../img/optional/dot.svg') no-repeat;
  background-size: contain;
  width: 8px;
  border-radius: 0;
  transition: 0.3s;
}

.rembit_why_mob_one__block .slider .flickity-page-dots .dot.is-selected {
  background: url(../img/optional/dot_selected.svg) no-repeat;
  background-size: contain;
}

.rembit_why_mob_one__block .slider .slide {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rembit_why_mob_one__block .slider .slide .icon {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.rembit_why_mob_one__block .slider .slide .desc {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.rembit_why_mob_two {
  display: none;
  background: #ffffff;
}

.rembit_why_mob_two__block {
  padding-top: 40px;
  padding-bottom: 100px;
}

.rembit_why_mob_two__block h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 30px;
}

.rembit_why_mob_two__block .slider .flickity-page-dots {
  bottom: -50px;
}

.rembit_why_mob_two__block .slider .flickity-page-dots .dot {
  opacity: 1;
  background: url('../img/optional/dot_gray.svg') no-repeat;
  background-size: contain;
  width: 8px;
  border-radius: 0;
  transition: 0.3s;
}

.rembit_why_mob_two__block .slider .flickity-page-dots .dot.is-selected {
  background: url(../img/optional/dot_selected.svg) no-repeat;
  background-size: contain;
}

.rembit_why_mob_two__block .slider .slide {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rembit_why_mob_two__block .slider .slide .icon {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.rembit_why_mob_two__block .slider .slide .desc {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_principles .wrapper {
  position: relative;
}

.rembit_principles .wrapper::before {
  content: '';
  width: 345px;
  height: 410px;
  position: absolute;
  left: -150px;
  bottom: -200px;
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  z-index: -2;
}

.rembit_principles .wrapper::after {
  content: '';
  position: absolute;
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  z-index: -2;
  width: 345px;
  height: 410px;
  right: 150px;
  top: -100px;
}

.rembit_principles__block {
  height: 435px;
  box-sizing: border-box;
  padding-top: 60px;
  position: relative;
}

.rembit_principles__block::before {
  content: '';
  position: absolute;
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  z-index: -2;
  width: 203px;
  height: 240px;
  right: 0;
  bottom: -40px;
}

.rembit_principles__block h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #784FD1;
}

.rembit_principles__block__items {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rembit_principles__block__items .item {
  width: calc(25% - 20px);
  position: relative;
}

.rembit_principles__block__items .item:nth-child(n+5) {
  margin-top: 75px;
}

.rembit_principles__block__items .item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  background: url(../img/principles/nut_before.svg) no-repeat;
  background-size: contain;
  width: 10px;
  height: 12px;
}

.rembit_principles__block__items .item .desc {
  padding-left: 20px;
  font-weight: normal;
  line-height: 23px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_principles_mob {
  background: #ffffff;
  display: none;
}

.rembit_principles_mob__block {
  padding-top: 40px;
  padding-bottom: 100px;
}

.rembit_principles_mob__block h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #784FD1;
}

.rembit_principles_mob__block .slider {
  padding-top: 40px;
}

.rembit_principles_mob__block .slider .flickity-page-dots {
  bottom: -50px;
}

.rembit_principles_mob__block .slider .flickity-page-dots .dot {
  opacity: 1;
  background: url('../img/optional/dot_gray.svg') no-repeat;
  background-size: contain;
  width: 8px;
  border-radius: 0;
  transition: 0.3s;
}

.rembit_principles_mob__block .slider .flickity-page-dots .dot.is-selected {
  background: url(../img/optional/dot_selected.svg) no-repeat;
  background-size: contain;
}

.rembit_principles_mob__block .slider .flickity-viewport {
  overflow: initial;
}

.rembit_principles_mob__block .slider .slide {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rembit_principles_mob__block .slider .slide .item {
  margin-bottom: 40px;
}

.rembit_principles_mob__block .slider .slide .item:nth-child(even) {
  margin-bottom: 0;
}

.rembit_principles_mob__block .slider .slide .item .desc {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  position: relative;
}

.rembit_principles_mob__block .slider .slide .item .desc::before {
  content: '';
  position: absolute;
  left: calc(50% - 10px);
  top: -20px;
  background: url(../img/principles/nut_before.svg) no-repeat;
  background-size: contain;
  width: 10px;
  height: 12px;
}

.rembit_scheme {
  background: #3D0E92;
  background-position: 0px -32px;
  height: 565px;
  box-sizing: border-box;
  padding-top: 65px;
}

.rembit_scheme.visible {
  background: #3D0E92 url(../img/why/back.jpg) no-repeat;
  background-size: cover;
}

.rembit_scheme h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 50px;
}

.rembit_scheme__block {
  display: flex;
}

.rembit_scheme__block__check {
  width: 440px;
  min-width: 440px;
  margin-right: 40px;
}

.rembit_scheme__block__check .item {
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background: #4D1CB5;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  transition: 0.3s;
  position: relative;
  z-index: 3;
  cursor: pointer;
}

.rembit_scheme__block__check .item:not(:first-child) {
  margin-top: 3px;
}

.rembit_scheme__block__check .item::after {
  content: '';
  position: absolute;
  right: -32px;
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-left: 41px solid linear-gradient(262.69deg, #784FD1 1.5%, #7130FB 109.46%), #784FD1;
  border-bottom: 16px solid transparent;
  opacity: 0;
  transition: 0.15s;
}

.rembit_scheme__block__check .item:hover {
  background: linear-gradient(262.69deg, #784FD1 1.5%, #7130FB 109.46%), #784FD1;
}

.rembit_scheme__block__check .item.active {
  background: linear-gradient(262.69deg, #784FD1 1.5%, #7130FB 109.46%), #784FD1;
  transform: translateX(30px);
}

.rembit_scheme__block__check .item.active::after {
  opacity: 1;
}

.rembit_scheme__block__desc {
  width: 100%;
  height: calc(58px * 6 + 3px * 5);
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);
  position: relative;
}

.rembit_scheme__block__desc::before {
  content: '';
  background: url(../img/scheme/nut_1.png) no-repeat;
  background-size: contain;
  width: 50px;
  height: 43px;
  position: absolute;
  top: -8px;
  left: 60px;
}

.rembit_scheme__block__desc::after {
  content: '';
  background: url(../img/scheme/nut_2.png) no-repeat;
  background-size: contain;
  width: 48px;
  height: 57px;
  position: absolute;
  top: -28px;
  left: 120px;
}

.rembit_scheme__block__desc__main {
  display: flex;
  align-items: center;
  padding-left: 60px;
  padding-right: 50px;
  padding-top: 50px;
}

.rembit_scheme__block__desc__main.fade {
  animation: fade 0.3s linear;
}

.rembit_scheme__block__desc__main.show {
  animation: show 0.3s linear;
}

.rembit_scheme__block__desc__main .text {
  font-weight: normal;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  width: 480px;
  margin-right: 50px;
}

.rembit_scheme__block__desc__main img {
  width: 240px;
  height: 260px;
}

.rembit_scheme__block__desc .number {
  font-weight: bold;
  line-height: 61px;
  font-size: 45px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #F2EDFA;
  position: absolute;
  left: 60px;
  bottom: 44px;
}

.rembit_scheme__block__desc .number.fade {
  animation: fade 0.3s linear;
}

.rembit_scheme__block__desc .number.show {
  animation: show 0.3s linear;
}

.rembit_scheme__block-slider {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);
}

.rembit_scheme__block-slider::before {
  content: '';
  background: url(../img/scheme/nut_1.png) no-repeat;
  background-size: contain;
  width: 50px;
  height: 43px;
  position: absolute;
  top: -8px;
  left: 50px;
}

.rembit_scheme__block-slider::after {
  content: '';
  background: url(../img/scheme/nut_2.png) no-repeat;
  background-size: contain;
  width: 48px;
  height: 57px;
  position: absolute;
  top: -28px;
  left: 110px;
}

.rembit_scheme__block-slider__item {
  position: relative;
  width: 100%;
}

.rembit_scheme__block-slider__item__main {
  display: flex;
  align-items: center;
  padding: 50px;
}

.rembit_scheme__block-slider__item__main .text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  margin-right: 30px;
}

.rembit_scheme__block-slider__item__main img {
  width: 240px;
  min-width: 240px;
  height: 260px;
}

.rembit_scheme__block-slider__item .number {
  font-weight: bold;
  line-height: 61px;
  font-size: 45px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #F2EDFA;
  position: absolute;
  left: 50px;
  bottom: 20px;
}

.rembit_scheme .flickity-page-dots {
  bottom: -45px;
}

.rembit_scheme .flickity-page-dots .dot {
  opacity: 1;
  background: url(../img/optional/dot.svg) no-repeat;
  background-size: contain;
  width: 8px;
  border-radius: 0;
  transition: 0.3s;
}

.rembit_scheme .flickity-page-dots .dot.is-selected {
  background: url(../img/optional/dot_selected.svg) no-repeat;
  background-size: contain;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.rembit_questions {
  box-sizing: border-box;
  padding: 60px 0;
  min-height: 500px;
}

.rembit_questions h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 50px;
}

.rembit_questions__block {
  display: flex;
}

.rembit_questions__block__items {
  width: 650px;
  min-width: 650px;
  margin-right: 40px;
}

.rembit_questions__block__items .item {
  background: #4D1CB5;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.rembit_questions__block__items .item:not(:first-child) {
  margin-top: 3px;
}

.rembit_questions__block__items .item::after {
  content: '';
  background: url(../img/questions/arrow.svg) no-repeat;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 25px;
  width: 11px;
  height: 10px;
  transition: 0.3s;
}

.rembit_questions__block__items .item.active::after {
  transform: rotate(90deg);
}

.rembit_questions__block__items .item .title {
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  display: flex;
  padding: 19px 30px;
}

.rembit_questions__block__items .item .title:hover {
  background: #008BFF;
}

.rembit_questions__block__items .item .title.active {
  background: #008BFF;
}

.rembit_questions__block__items .item .text {
  font-weight: normal;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  background: #FFFFFF;
  border: 1px solid #E2EFFF;
  border-top: none;
  overflow-y: auto;
  display: none;
  padding: 30px;
}

.rembit_questions__block form {
  background: #E2EFFF url(../img/questions/back.png) no-repeat;
  background-size: cover;
  width: 670px;
  height: 420px;
  padding-top: 35px;
  padding-left: 35px;
  padding-right: 35px;
  box-sizing: border-box;
  position: relative;
}

.rembit_questions__block form .br_on {
  display: none;
}

.rembit_questions__block form::before {
  content: '';
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -110px;
  right: 35px;
  width: 137px;
  height: 163px;
  z-index: -2;
}

.rembit_questions__block form::after {
  content: '';
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -150px;
  right: 160px;
  width: 59px;
  height: 70px;
  z-index: -2;
}

.rembit_questions__block form .error_name {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
  font-size: 13px;
  position: absolute;
}

.rembit_questions__block form .error_name::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

.rembit_questions__block form .error_name.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

.rembit_questions__block form .error_tel {
  display: none;
  opacity: 0;
  position: relative;
  padding-right: 20px;
  margin-bottom: 10px;
  color: #784FD1;
  font-size: 13px;
  margin: 0;
  position: absolute;
}

.rembit_questions__block form .error_tel::after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  background: url(../img/principles/nut_before.svg) 0 0 / contain no-repeat;
  width: 10px;
  height: 12px;
}

.rembit_questions__block form .error_tel.active {
  display: inline-block;
  opacity: 1;
  transition: 0.3s;
}

.rembit_questions__block form .title {
  font-weight: bold;
  line-height: 25px;
  font-size: 22px;
  letter-spacing: 0.04em;
  color: #4C4C4C;
  margin-bottom: 20px;
}

.rembit_questions__block form .desc {
  font-weight: normal;
  line-height: 23px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  margin-bottom: 30px;
}

.rembit_questions__block form .input_block {
  display: flex;
}

.rembit_questions__block form .input_block .input_item {
  width: 287px;
}

.rembit_questions__block form .input_block .input_item input {
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  padding-left: 17px;
  box-sizing: border-box;
  height: 45px;
  outline: none;
  border: none;
  width: 100%;
}

.rembit_questions__block form .input_block .input_item.name {
  margin-right: 20px;
}

.rembit_questions__block form .input_block .input_item::placeholder {
  color: #9D9D9D;
}

.rembit_questions__block form textarea {
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  padding-left: 17px;
  box-sizing: border-box;
  padding-top: 15px;
  width: 100%;
  height: 85px;
  resize: none;
  border: none;
  margin-top: 20px;
}

.rembit_questions__block form textarea::placeholder {
  color: #9D9D9D;
}

.rembit_questions__block form .foot {
  margin-top: 20px;
  display: flex;
}

.rembit_questions__block form .foot button {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
  margin-right: 20px;
}

.rembit_questions__block form .foot button:hover {
  background: #66B9FF;
}

.rembit_questions__block form .foot .conditions,
.rembit_questions__block form .foot .conditions a {
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
  color: #9D9D9D;
}

.rembit_questions__block form .foot .conditions a {
  text-decoration-line: underline;
  font-weight: 500;
  transition: 0.3s;
}

.rembit_questions__block form .foot .conditions a:hover {
  color: #008BFF;
}

.rembit_reviews {
  box-sizing: border-box;
  height: 500px;
  background: #3D0E92;
  background-position: 0 -30px;
  padding-top: 65px;
}

.rembit_reviews.visible {
  background: #3D0E92 url(../img/why/back.jpg) no-repeat;
  background-size: cover;
}

.rembit_reviews h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 55px;
}

.rembit_reviews__block {
  width: 1170px;
  margin: 0 auto;
}

.rembit_reviews__block .item {
  background: #ffffff;
  width: 575px;
  height: 280px;
  position: relative;
  padding-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  margin-right: 20px;
}

.rembit_reviews__block .item::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #784FD1;
  top: 0;
  left: 0;
}

.rembit_reviews__block .item::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #784FD1;
  top: 0;
  right: 0;
  left: unset;
}

.rembit_reviews__block .item .squares {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rembit_reviews__block .item .squares::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #784FD1;
  bottom: 0;
  left: 0;
}

.rembit_reviews__block .item .squares::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #784FD1;
  bottom: 0;
  right: 0;
}

.rembit_reviews__block .item .date {
  position: absolute;
  right: 50px;
  top: 40px;
  font-weight: normal;
  line-height: 15px;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_reviews__block .item .person {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.rembit_reviews__block .item .person img {
  width: 58px;
  height: 67px;
  margin-right: 30px;
}

.rembit_reviews__block .item .person .desc .name {
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #070707;
}

.rembit_reviews__block .item .person .desc .position {
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_reviews__block .item p {
  font-weight: normal;
  line-height: 23px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_reviews .flickity-button {
  background: none;
  width: unset;
  height: unset;
}

.rembit_reviews .flickity-button:hover {
  background: none;
}

.rembit_reviews .flickity-button:hover svg .st0 {
  fill: #784FD1;
  transition: 0.3s;
}

.rembit_reviews .flickity-button:hover svg .st1 {
  fill: #ffffff;
  transition: 0.3s;
}

.rembit_reviews .flickity-prev-next-button {
  border-radius: 0;
  width: 69px;
  height: 77px;
  top: 50%;
  transition: 0.3s;
}

.rembit_reviews .flickity-button-icon {
  display: none;
}

.rembit_reviews .flickity-prev-next-button.previous {
  left: -95px;
}

.rembit_reviews .flickity-prev-next-button.next {
  right: -95px;
}

.rembit_description {
  box-sizing: border-box;
  padding-top: 60px;
  background: #E2EFFF url(../img/description/back.png) no-repeat;
  background-size: cover;
  height: 760px;
}

.rembit_description h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #784FD1;
  margin-bottom: 70px;
}

.rembit_description__block .item {
  box-sizing: border-box;
  width: 365px;
  height: 500px;
  padding-top: 70px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 90px;
  background: #ffffff;
  position: relative;
  margin-right: 20px;
}

.rembit_description__block .item:not(.is-selected) {
  opacity: 0.4;
}

.rembit_description__block .item::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #784FD1;
  top: 0;
  left: 0;
}

.rembit_description__block .item::after {
  content: '';
  position: absolute;
  width: 33px;
  height: 52px;
  background: url(../img/why/block.svg) no-repeat;
  background-size: contain;
  bottom: 0;
  right: 0;
}

.rembit_description__block .item .number {
  content: '';
  position: absolute;
  top: 18px;
  right: 16px;
  font-weight: normal;
  line-height: 40px;
  font-size: 30px;
  text-align: right;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #784FD1;
}

.rembit_description__block .item .text {
  font-weight: normal;
  line-height: 23px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_description .flickity-viewport {
  overflow: unset;
}

.rembit_description .flickity-button {
  background: none;
  width: unset;
  height: unset;
}

.rembit_description .flickity-button:hover {
  background: none;
}

.rembit_description .flickity-button:hover svg .st0 {
  fill: #784FD1;
  transition: 0.3s;
}

.rembit_description .flickity-button:hover svg .st1 {
  fill: #ffffff;
  transition: 0.3s;
}

.rembit_description .flickity-prev-next-button {
  border-radius: 0;
  width: 69px;
  height: 77px;
  top: 50%;
  transition: 0.3s;
}

.rembit_description .flickity-button-icon {
  display: none;
}

.rembit_description .flickity-prev-next-button.previous {
  left: -95px;
}

.rembit_description .flickity-prev-next-button.next {
  right: -95px;
}

.rembit_map {
  padding-top: 60px;
  box-sizing: border-box;
  position: relative;
}

.rembit_map h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #784FD1;
  margin-bottom: 30px;
}

.rembit_map__block {
  display: flex;
  margin-bottom: 30px;
}

.rembit_map__block .city {
  width: 50%;
  display: flex;
}

.rembit_map__block .city .title {
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #070707;
  margin-right: 20px;
  padding-top: 4px;
}

.rembit_map__block .city .items {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
}

.rembit_map__block .city .items .item {
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  cursor: not-allowed;
  border: 1px solid #E2EFFF;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 5px 13px 5px 13px;
}

.rembit_map__block .city .items .item:nth-child(n+7) {
  margin-top: 10px;
}

.rembit_map__block .city .items .item:nth-child(n+2) {
  margin-left: 8px;
}

.rembit_map__block .city .items .item:nth-child(7) {
  margin-left: 0;
}

.rembit_map__block .city .items .item.item_1,
.rembit_map__block .city .items .item.item_2,
.rembit_map__block .city .items .item.item_3,
.rembit_map__block .city .items .item.item_6,
.rembit_map__block .city .items .item.item_7,
.rembit_map__block .city .items .item.item_8,
.rembit_map__block .city .items .item.item_11 {
  width: 64px;
  max-width: 64px;
}

.rembit_map__block .city .items .item.item_4,
.rembit_map__block .city .items .item.item_5,
.rembit_map__block .city .items .item.item_9,
.rembit_map__block .city .items .item.item_10 {
  width: 74px;
  max-width: 74px;
}

.rembit_map__block .city .items .item.item_12 {
  width: 120px;
  max-width: 120px;
}

.rembit_map__block .area {
  width: 50%;
  display: flex;
  align-items: flex-start;
}

.rembit_map__block .area .title {
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #070707;
  margin-right: 20px;
}

.rembit_map__block .area .check_area {
  width: 440px;
}

.rembit_map #map {
  width: 100%;
  height: 400px;
}

.rembit_brands {
  padding-top: 50px;
  margin-bottom: 40px;
  box-sizing: border-box;
}

.rembit_brands h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 30px;
}

.rembit_brands__block {
  display: flex;
  flex-wrap: wrap;
}

.rembit_brands__block a {
  box-sizing: border-box;
  width: 157px;
  height: 100px;
  margin-right: 13px;
  border: 1px solid #E2EFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}

.rembit_brands__block a:hover {
  box-shadow: 0 0 5px #e2efff;
  transition: 0.3s;
}

.rembit_brands__block a:nth-child(8n) {
  margin-right: 0;
}

.rembit_brands__block a:nth-child(n+9) {
  margin-top: 13px;
}

.rembit_brands__block a img {
  max-width: 100%;
  max-height: 100%;
}

.rembit_brands .show_all {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  width: 263px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #784FD1;
  margin: 0 auto;
  margin-top: 30px;
  transition: 0.15s;
}

.rembit_brands .show_all img {
  margin-right: 20px;
  transition: 0.5s;
}

.rembit_brands .show_all:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784fd1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.66);
}

.rembit_brands .show_all:hover img {
  transform: rotate(360deg);
}

.rembit_brands_mob {
  display: none;
  background: #ffffff;
  padding-top: 40px;
  padding-bottom: 90px;
}

.rembit_brands_mob .flickity-page-dots {
  bottom: -45px;
}

.rembit_brands_mob .flickity-page-dots .dot {
  opacity: 1;
  background: url('../img/optional/dot_gray.svg') no-repeat;
  background-size: contain;
  width: 8px;
  border-radius: 0;
  transition: 0.3s;
}

.rembit_brands_mob .flickity-page-dots .dot.is-selected {
  background: url(../img/optional/dot_selected.svg) no-repeat;
  background-size: contain;
}

.rembit_brands_mob h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 25px;
}

.rembit_brands_mob__slider .item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.rembit_brands_mob__slider .item a {
  box-sizing: border-box;
  width: 48%;
  height: 100px;
  margin-right: 2%;
  border: 1px solid #e2efff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}

.rembit_brands_mob__slider .item a:nth-child(even) {
  margin-right: 0;
}

.rembit_brands_mob__slider .item a:nth-child(-n+6) {
  margin-bottom: 10px;
}

.rembit_brands_mob__slider .item a img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.rembit_faults {
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 40px;
  min-height: 600px;
}

.rembit_faults .wrapper {
  position: relative;
}

.rembit_faults .wrapper::before {
  content: '';
  width: 271px;
  height: 322px;
  position: absolute;
  left: -210px;
  top: 490px;
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  z-index: -2;
}

.rembit_faults .wrapper::after {
  content: '';
  position: absolute;
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  z-index: -2;
  width: 345px;
  height: 410px;
  right: -140px;
  top: -80px;
}

.rembit_faults h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 40px;
}

.rembit_faults h2 .xs_on {
  display: none;
}

.rembit_faults__block {
  position: relative;
}

.rembit_faults__block::after {
  content: '';
  position: absolute;
  background: url(../img/principles/nut_back.svg) no-repeat;
  background-size: contain;
  z-index: -2;
  width: 203px;
  height: 241px;
  right: -130px;
  top: 310px;
}

.rembit_faults__block .item {
  box-sizing: border-box;
  cursor: pointer;
}

.rembit_faults__block .item:not(:first-child) {
  border-top: none;
}

.rembit_faults__block .item.active .outer {
  background: #008BFF;
  border: 1px solid #008BFF;
  border-bottom: none;
}

.rembit_faults__block .item.active .outer .title .text {
  color: #ffffff;
}

.rembit_faults__block .item.active .outer .title .arrow {
  fill: #ffffff;
  transform: rotate(0) translateX(-2px);
}

.rembit_faults__block .item.active .outer .price {
  color: #ffffff;
}

.rembit_faults__block .item.active .outer .price::before {
  background: #008BFF;
}

.rembit_faults__block .item .outer {
  padding: 19px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  background: #ffffff;
  border: 1px solid #E2EFFF;
  border-bottom: none;
}

.rembit_faults__block .item .outer:hover {
  background: #008BFF;
  border: 1px solid #008BFF;
  border-bottom: none;
}

.rembit_faults__block .item .outer:hover .title .text {
  color: #ffffff;
}

.rembit_faults__block .item .outer:hover .title .arrow {
  fill: #ffffff;
  transform: rotate(0) translateX(-2px);
}

.rembit_faults__block .item .outer:hover .price {
  color: #ffffff;
}

.rembit_faults__block .item .outer:hover .price::before {
  background: #008BFF;
}

.rembit_faults__block .item .outer .title {
  display: flex;
  align-items: center;
}

.rembit_faults__block .item .outer .title .arrow {
  fill: #784FD1;
  width: 12px;
  min-width: 12px;
  height: 11px;
  transform: rotate(90deg) translateX(-2px);
  margin-right: 40px;
  margin-left: 30px;
  transition: 0.3s;
}

.rembit_faults__block .item .outer .title .arrow.active {
  fill: #ffffff;
  transform: rotate(0) translateX(-2px);
}

.rembit_faults__block .item .outer .title .text {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  transition: 0.3s;
}

.rembit_faults__block .item .outer .price {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  box-sizing: border-box;
  padding-right: 70px;
  width: 240px;
  height: 100%;
  position: relative;
  transition: 0.3s;
}

.rembit_faults__block .item .outer .price::before {
  content: '';
  position: absolute;
  left: 0;
  top: -19px;
  width: 1px;
  height: 63px;
  background: #E2EFFF;
  transition: 0.3s;
}

.rembit_faults__block .item:last-of-type .outer,
.rembit_faults__block .item:last-of-type .inner {
  border-bottom: 1px solid #E2EFFF;
}

.rembit_faults__block .item .inner {
  display: none;
  padding: 30px 240px 40px 80px;
  background: #ffffff;
  border-left: 1px solid #E2EFFF;
  border-right: 1px solid #E2EFFF;
}

.rembit_faults__block .item .inner .text {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  margin-bottom: 40px;
}

.rembit_faults__block .item .inner .block_but {
  display: flex;
  justify-content: flex-start;
}

.rembit_faults__block .item .inner .block_but .more,
.rembit_faults__block .item .inner .block_but .call {
  width: 380px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  font-size: 18px;
}

.rembit_faults__block .item .inner .block_but .more {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.rembit_faults__block .item .inner .block_but .more:hover {
  background: #66B9FF;
}

.rembit_faults__block .item .inner .block_but .call {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  background: #784FD1;
  transition: 0.15s;
}

.rembit_faults__block .item .inner .block_but .call:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784fd1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.66);
}

.rembit_services {
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 40px;
  background: #e2efff;
  min-height: 600px;
  overflow: hidden;
}

.rembit_services.visible {
  background: url(../img/description/back.png) 0 0 / cover no-repeat #e2efff;
}

.rembit_services .wrapper {
  position: relative;
}

.rembit_services h3 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 25px;
}

.rembit_services h3 .xs_on {
  display: none;
}

.rembit_services .text_block {
  font-weight: 400;
  line-height: 23px;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  background: #FFFFFF;
  padding: 30px;
  margin-bottom: 40px;
  position: relative;
}

.rembit_services .text_block:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #784fd1;
  top: 0;
  left: 0;
}

.rembit_services .text_block:after {
  content: '';
  position: absolute;
  width: 33px;
  height: 52px;
  background: url(../img/why/block.svg) 0 0 / contain no-repeat;
  bottom: 0;
  right: 0;
}

.rembit_services .text_block p {
  margin-bottom: 20px;
}

.rembit_services .text_block p:last-child {
  margin-bottom: 0;
}

.rembit_services__block {
  position: relative;
}

.rembit_services__block .item {
  box-sizing: border-box;
  cursor: pointer;
}

.rembit_services__block .item:not(:first-child) {
  border-top: none;
}

.rembit_services__block .item.active .outer {
  background: #008BFF;
  border: 1px solid #008BFF;
  border-bottom: none;
}

.rembit_services__block .item.active .outer .title .text {
  color: #ffffff;
}

.rembit_services__block .item.active .outer .title .arrow {
  fill: #ffffff;
  transform: rotate(0) translateX(-2px);
}

.rembit_services__block .item.active .outer .price {
  color: #ffffff;
}

.rembit_services__block .item.active .outer .price::before {
  background: #008BFF;
}

.rembit_services__block .item .outer {
  padding: 19px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  background: #ffffff;
  border: 1px solid #E2EFFF;
  border-bottom: none;
}

.rembit_services__block .item .outer:hover {
  background: #008BFF;
  border: 1px solid #008BFF;
  border-bottom: none;
}

.rembit_services__block .item .outer:hover .title .text {
  color: #ffffff;
}

.rembit_services__block .item .outer:hover .title .arrow {
  fill: #ffffff;
  transform: rotate(0) translateX(-2px);
}

.rembit_services__block .item .outer:hover .price {
  color: #ffffff;
}

.rembit_services__block .item .outer:hover .price::before {
  background: #008BFF;
}

.rembit_services__block .item .outer .title {
  display: flex;
  align-items: center;
}

.rembit_services__block .item .outer .title .arrow {
  fill: #784FD1;
  width: 12px;
  min-width: 12px;
  height: 11px;
  transform: rotate(90deg) translateX(-2px);
  margin-right: 40px;
  margin-left: 30px;
  transition: 0.3s;
}

.rembit_services__block .item .outer .title .arrow.active {
  fill: #ffffff;
  transform: rotate(0) translateX(-2px);
}

.rembit_services__block .item .outer .title .text {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  transition: 0.3s;
}

.rembit_services__block .item .outer .price {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  box-sizing: border-box;
  padding-right: 70px;
  width: 240px;
  height: 100%;
  position: relative;
  transition: 0.3s;
}

.rembit_services__block .item .outer .price::before {
  content: '';
  position: absolute;
  left: 0;
  top: -19px;
  width: 1px;
  height: 63px;
  background: #E2EFFF;
  transition: 0.3s;
}

.rembit_services__block .item:last-of-type .outer,
.rembit_services__block .item:last-of-type .inner {
  border-bottom: 1px solid #E2EFFF;
}

.rembit_services__block .item .inner {
  display: none;
  padding: 30px 240px 40px 80px;
  background: #ffffff;
  border-left: 1px solid #E2EFFF;
  border-right: 1px solid #E2EFFF;
}

.rembit_services__block .item .inner .text {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #4C4C4C;
  margin-bottom: 40px;
}

.rembit_services__block .item .inner .block_but {
  display: flex;
  justify-content: flex-start;
}

.rembit_services__block .item .inner .block_but .more,
.rembit_services__block .item .inner .block_but .call {
  width: 380px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  font-size: 18px;
}

.rembit_services__block .item .inner .block_but .more {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.rembit_services__block .item .inner .block_but .more:hover {
  background: #66B9FF;
}

.rembit_services__block .item .inner .block_but .call {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  background: #784FD1;
  transition: 0.15s;
}

.rembit_services__block .item .inner .block_but .call:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784fd1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.66);
}

.rembit_repair {
  box-sizing: border-box;
  background: #E2EFFF url(../img/description/back.png) no-repeat;
  background-size: cover;
  padding: 50px 0;
}

.rembit_repair h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 30px;
}

.rembit_repair__block {
  display: flex;
  flex-wrap: wrap;
}

.rembit_repair__block .item {
  box-sizing: border-box;
  color: #4C4C4C;
  background: #ffffff;
  text-transform: initial;
  letter-spacing: 0.02em;
  overflow: hidden;
  position: relative;
  width: 324px;
  height: 460px;
  padding: 0 28px 40px 28px;
  margin-right: 19px;
  margin-top: 20px;
}

.rembit_repair__block .item:hover button {
  transform: translateY(0);
}

.rembit_repair__block .item:hover .block_for_img {
  opacity: 0.4;
}

.rembit_repair__block .item:hover .block_for_img,
.rembit_repair__block .item:hover .title,
.rembit_repair__block .item:hover .text {
  transform: translateY(-70px);
}

.rembit_repair__block .item:hover::before {
  transform: translateX(-18px);
}

.rembit_repair__block .item:hover::after {
  transform: translateX(33px);
}

.rembit_repair__block .item:nth-child(4n) {
  margin-right: 0;
}

.rembit_repair__block .item:nth-child(-n+4) {
  margin-top: 0;
}

.rembit_repair__block .item::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #784FD1;
  top: 0;
  left: 0;
  transition: 0.2s;
}

.rembit_repair__block .item::after {
  content: '';
  position: absolute;
  width: 33px;
  height: 52px;
  background: url(../img/why/block.svg) no-repeat;
  background-size: contain;
  bottom: 0;
  right: 0;
  transition: 0.2s;
}

.rembit_repair__block .item__block .block_for_img {
  height: 100px;
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.rembit_repair__block .item__block .block_for_img img {
  max-width: 100%;
  max-height: 100%;
}

.rembit_repair__block .item__block .title {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin: 10px 0;
  transition: 0.3s;
}

.rembit_repair__block .item__block .text {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  transition: 0.3s;
}

.rembit_repair__block .item button {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  width: 260px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #008BFF;
  transition: 0.3s;
  transform: translateY(50px);
}

.rembit_repair__block .item button:hover {
  background: #66B9FF;
}

.rembit_repair .show_all {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  width: 263px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #784FD1;
  margin: 0 auto;
  margin-top: 30px;
  transition: 0.15s;
}

.rembit_repair .show_all img {
  margin-right: 20px;
  transition: 0.5s;
}

.rembit_repair .show_all:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784fd1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.66);
}

.rembit_repair .show_all:hover img {
  transform: rotate(360deg);
}

.rembit_materials {
  box-sizing: border-box;
  background: #E2EFFF url(../img/description/back.png) no-repeat;
  background-size: cover;
  padding: 50px 0;
}

.rembit_materials h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 40px;
}

.rembit_materials__block {
  display: flex;
  flex-wrap: wrap;
}

.rembit_materials__block .item {
  width: 210px;
  height: 210px;
  background: #ffffff;
  position: relative;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.rembit_materials__block .item:nth-child(6n) {
  margin-right: 0;
}

.rembit_materials__block .item:nth-child(-n+6) {
  margin-top: 0;
}

.rembit_materials__block .item .block_for_img {
  height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 20px;
}

.rembit_materials__block .item .block_for_img img {
  max-width: 100%;
  max-height: 100%;
}

.rembit_materials__block .item .title {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #4C4C4C;
}

.rembit_materials__block .item::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #784FD1;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
}

.rembit_materials__block .item::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #784FD1;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  left: unset;
  top: unset;
  right: 0;
  bottom: 0;
}

.rembit_materials .show_all {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  width: 263px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #784FD1;
  margin: 0 auto;
  margin-top: 30px;
  transition: 0.15s;
}

.rembit_materials .show_all img {
  margin-right: 20px;
  transition: 0.5s;
}

.rembit_materials .show_all:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784fd1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.66);
}

.rembit_materials .show_all:hover img {
  transform: rotate(360deg);
}

.rembit_materials_mob {
  padding-top: 40px;
  padding-bottom: 100px;
  display: none;
  background: #E2EFFF;
}

.rembit_materials_mob h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4C4C4C;
  margin-bottom: 30px;
}

.rembit_materials_mob__slider .flickity-page-dots {
  bottom: -55px;
}

.rembit_materials_mob__slider .flickity-page-dots .dot {
  opacity: 1;
  background: url('../img/optional/dot.svg') no-repeat;
  background-size: contain;
  width: 8px;
  border-radius: 0;
  transition: 0.3s;
}

.rembit_materials_mob__slider .flickity-page-dots .dot.is-selected {
  background: url(../img/optional/dot_selected.svg) no-repeat;
  background-size: contain;
}

.rembit_materials_mob__slider .slide {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.rembit_materials_mob__slider .slide .item {
  width: 49%;
  height: auto;
  background: #ffffff;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.rembit_materials_mob__slider .slide .item:nth-child(odd) {
  margin-right: 2%;
}

.rembit_materials_mob__slider .slide .item .block_for_img {
  height: 70px;
  min-height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.rembit_materials_mob__slider .slide .item .block_for_img img {
  max-width: 100%;
  max-height: 100%;
}

.rembit_materials_mob__slider .slide .item .title {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 0 5px 15px 5px;
  margin: auto 0;
}

.rembit_materials_mob__slider .slide .item::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #784FD1;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
}

.rembit_materials_mob__slider .slide .item::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #784FD1;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  left: unset;
  top: unset;
  right: 0;
  bottom: 0;
}

.rembit_cost {
  box-sizing: border-box;
  height: 500px;
  position: relative;
}

.rembit_cost .select2-dropdown {
  width: 360px !important;
}

.rembit_cost .opt_back_left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 45%;
  background: #4F23A7;
  z-index: -1;
}

.rembit_cost .opt_back_left.visible {
  background: #4F23A7 url(../img/bid/back.png) no-repeat;
  background-size: cover;
}

.rembit_cost .opt_back_right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 55%;
  background: radial-gradient(1007.29px at 75.25% 50.6%, #602DE1 0%, #4D1CB5 30.2%, #3D0E91 60.17%, #34057B 84.45%, #300273 100%);
  z-index: -1;
}

.rembit_cost__block {
  box-sizing: border-box;
  display: flex;
}

.rembit_cost__block-check {
  padding-top: 60px;
  width: 465px;
  min-width: 465px;
}

.rembit_cost__block-check h2 {
  font-weight: bold;
  line-height: 34px;
  font-size: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.rembit_cost__block-check h2 .xs_on {
  display: none;
}

.rembit_cost__block-check .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.rembit_cost__block-check .title:last-of-type {
  margin-top: 10px;
}

.rembit_cost__block-check select {
  width: 100%;
}

.rembit_cost__block-check .but_block {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.rembit_cost__block-check .but_block .action {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  background: #008BFF;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  transition: 0.3s;
  width: 260px;
  height: 50px;
  min-width: 210px;
  max-width: 210px;
}

.rembit_cost__block-check .but_block .action:hover {
  background: #66B9FF;
}

.rembit_cost__block-check .but_block .more_info {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.04em;
  color: #008BFF;
  text-decoration: underline;
  transition: 0.3s;
}

.rembit_cost__block-check .but_block .more_info:hover {
  text-decoration: none;
}

.rembit_cost__block-category {
  padding-top: 100px;
  padding-left: 190px;
}

.rembit_cost__block-category .lg_on {
  display: none;
}

.rembit_cost__block-category .title {
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.rembit_cost__block-category .opt_title {
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin-bottom: 45px;
}

.rembit_cost__block-category__select_block {
  display: flex;
  flex-wrap: wrap;
  height: 217px;
}

.rembit_cost__block-category__select_block .select2-container {
  margin-right: 20px;
  margin-bottom: 20px;
  height: 36px;
}

.rembit_cost__block-category__select_block .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-radius: 20px;
}

.rembit_cost__block-category__select_block .select2-container--default .select2-selection--single {
  border-radius: 20px;
  height: 36px;
}

.rembit_cost__block-category__select_block .select2-container--default .select2-search--dropdown .select2-search__field {
  height: 36px;
}

.rembit_cost__block-category__select_block .select2-container--default .select2-selection--single .select2-selection__placeholder {
  line-height: 36px;
}

.rembit_cost__block-category__select_block .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
}

.rembit_cost__block-category__select_block .select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 12px;
}

.rembit_footer {
  box-sizing: border-box;
  background: #3D0E92;
  height: 360px;
  position: absolute;
  bottom: 0;
  bottom: -360px;
  left: 0;
  right: 0;
}

.rembit_footer.visible {
  background: #3D0E92 url(../img/footer/back.jpg) no-repeat;
  background-size: cover;
}

.rembit_footer__block {
  color: #ffffff;
  padding-top: 30px;
  display: flex;
}

.rembit_footer__block .description {
  width: 415px;
  padding-right: 30px;
}

.rembit_footer__block .description__logo {
  display: flex;
  cursor: pointer;
}

.rembit_footer__block .description__logo .icon {
  fill: #ffffff;
  width: 49px;
  height: 39px;
  transition: 0.3s;
}

.rembit_footer__block .description__logo .text {
  margin-left: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.3s;
}

.rembit_footer__block .description__logo .text .title {
  font-weight: bold;
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.rembit_footer__block .description__logo .text .desc {
  font-weight: normal;
  font-size: 13px;
  line-height: normal;
  letter-spacing: 0.01em;
}

.rembit_footer__block .description__text {
  width: 100%;
  margin-top: 50px;
}

.rembit_footer__block .description__text p {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.02em;
}

.rembit_footer__block .description__text p:first-child {
  margin-bottom: 20px;
}

.rembit_footer__block .payment {
  width: 350px;
  padding: 0 100px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.rembit_footer__block .payment .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin-bottom: 25px;
}

.rembit_footer__block .payment .payment_systems .cards {
  display: flex;
  flex-wrap: wrap;
}

.rembit_footer__block .payment .payment_systems .cards .item {
  background: #fff;
  width: 70px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 15px;
}

.rembit_footer__block .payment .payment_systems .cards .item:nth-child(n+5) {
  margin-top: 15px;
}

.rembit_footer__block .payment .payment_systems .cards .item img {
  max-width: 100%;
  max-height: 100%;
}

.rembit_footer__block .payment .banks {
  margin-top: 40px;
}

.rembit_footer__block .payment .banks .cards {
  display: flex;
  flex-wrap: wrap;
}

.rembit_footer__block .payment .banks .cards .item {
  background: #fff;
  width: 70px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 15px;
}

.rembit_footer__block .payment .banks .cards .item:nth-child(n+5) {
  margin-top: 15px;
}

.rembit_footer__block .payment .banks .cards .item img {
  max-width: 100%;
  max-height: 100%;
}

.rembit_footer__block .contacts {
  padding-left: 60px;
}

.rembit_footer__block .contacts .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin-bottom: 25px;
}

.rembit_footer__block .contacts .tel {
  font-weight: bold;
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
}

.rembit_footer__block .contacts .address {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 20px;
}

.rembit_footer__block .contacts .time {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  line-height: 18px;
}

.rembit_footer__block .contacts .social {
  margin-top: 40px;
}

.rembit_footer__block .contacts .social a {
  margin-right: 15px;
}

.rembit_footer__block .contacts .social a img {
  width: 40px;
  height: 46px;
}

.rembit_bid_404 {
  background: #E2EFFF url(../img/404/back.png) no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
}

.rembit_bid_404__block {
  padding-top: 30px;
  padding-left: 100px;
  display: flex;
}

.rembit_bid_404__block .master {
  width: 444px;
  height: 100%;
  margin-right: 110px;
}

.rembit_bid_404__block .desc {
  padding-top: 40px;
}

.rembit_bid_404__block .desc img {
  width: 485px;
}

.rembit_bid_404__block .desc .title {
  font-weight: normal;
  font-size: 22px;
  line-height: 25px;
  color: #4C4C4C;
  margin-top: 30px;
}

.rembit_bid_404__block .desc .return {
  font-family: Mariupol, Arial, sans-serif;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  background: #784FD1;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #fff;
  letter-spacing: 0.04em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 45px;
  margin: 75px auto 0;
}

.rembit_bid_404__block .desc .return:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
  transition: 0.15s;
}

@media screen and (max-width: 1400px) {
  .wrapper {
    padding-left: 34px;
    padding-right: 34px;
  }

  .wrapper {
    max-width: 1080px;
  }

  .debug > div {
    padding-left: 34px;
    padding-right: 34px;
  }

  .debug > div {
    padding-left: 34px;
    padding-right: 34px;
  }

  .debug > div {
    max-width: 1080px;
  }

  .rembit_head__logo {
    width: 290px;
  }

  .rembit_head__search {
    width: 16px;
    margin-right: 30px;
  }

  .rembit_head__search .title {
    display: none;
  }

  .rembit_head__time {
    display: none;
  }

  .rembit_head__cost {
    width: 168px;
  }

  .rembit_head__cost .icon {
    width: 27px;
    min-width: 27px;
    height: 27px;
    min-height: 27px;
  }

  .rembit_head__question .icon {
    width: 27px;
    min-width: 27px;
    height: 27px;
    min-height: 27px;
  }

  .rembit_head__call {
    width: 210px;
  }

  .rembit_head__call .title {
    line-height: 45px;
  }

  .rembit_head__call .tel .icon {
    display: none;
  }

  .wrapper_menu .menu_top__submenu.second .menu_top__submenu_list_optilist {
    padding-left: 20px;
    width: 493px;
  }

  .wrapper_menu .menu_top__submenu.third .menu_top__submenu_list_optilist {
    width: 300px;
  }

  .wrapper_menu .menu_top__submenu_title .icon {
    min-width: 20px;
    min-height: 20px;
  }

  .wrapper_menu .menu_top__submenu_title.first .icon {
    margin-right: 13px;
  }

  .wrapper_menu .menu_top__submenu_list_optilist .block_one ul li a .logo {
    display: none;
  }

  .wrapper_menu .call {
    width: 238px;
    min-width: 238px;
  }

  .rembit_bid__block .rembit_bid__main_block::after {
    background: url(../img/bid/lg_master.png) no-repeat;
    top: unset;
    bottom: -20px;
    left: 5px;
  }

  .rembit_bid__block .rembit_bid__main_block .text {
    margin-top: 70px;
    padding-left: 298px;
    padding-right: 20px;
  }

  .rembit_bid__block .rembit_bid__main_block .text h1.stub {
    font-size: 26px;
    line-height: 33px;
  }

  .rembit_bid__block .rembit_bid__main_block .text h1 {
    font-size: 35px;
    line-height: 39px;
  }

  .rembit_bid__block .rembit_bid__main_block .text .desc .lg_br {
    display: block;
  }

  .rembit_bid__block .rembit_bid__main_block .text .desc .lg_br_none {
    display: none;
  }

  .rembit_bid__block .rembit_bid__main_block .text .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .rembit_bid__form_bid {
    width: 305px;
  }

  .rembit_bid__form_bid input {
    width: 245px;
  }

  .rembit_bid__form_bid button {
    width: 245px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .block_f_img {
    width: 250px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text {
    width: 437px;
    padding-top: 30px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text h1 {
    font-size: 25px;
    line-height: 30px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc .lg_br {
    display: block;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc .lg_br_none {
    display: none;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .price {
    line-height: 27px;
    font-size: 19px;
  }

  .rembit_bid_separate__form_bid {
    width: 305px;
  }

  .rembit_bid_separate__form_bid input {
    width: 245px;
  }

  .rembit_bid_separate__form_bid button {
    width: 245px;
  }

  .rembit_appliances__block .tech {
    width: 162px;
    height: 162px;
    margin-right: 8px;
    padding-top: 22px;
  }

  .rembit_appliances__block .tech .price {
    top: 70px;
    font-size: 27px;
    line-height: 27px;
  }

  .rembit_appliances__block .tech .icon {
    height: 82px;
    width: 82px;
  }

  .rembit_appliances__block .tech .title {
    font-size: 14px;
    line-height: 16px;
  }

  .rembit_appliances__block_optional .tech {
    height: 96px;
    width: 502px;
    margin-right: 8px;
  }

  .rembit_appliances__block_optional .tech .price {
    font-size: 27px;
    line-height: 27px;
  }

  .rembit_appliances__block_optional .tech .icon {
    width: 100px;
    height: 80px;
  }

  .rembit_appliances__block_optional .tech .title {
    font-size: 20px;
    line-height: 23px;
  }

  .rembit_why {
    background-position: -7px 0px;
  }

  .rembit_why__block__left_block .items_block .item {
    width: 40%;
  }

  .rembit_why__block__left_block .items_block .item .lg_br_none {
    display: none;
  }

  .rembit_why__block__left_block .items_block .item .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .rembit_why__block__right_block {
    margin-top: 46px;
    width: 409px;
    min-width: 409px;
    height: 500px;
    padding-left: 36px;
    padding-right: 25px;
  }

  .rembit_why__block__right_block::before {
    width: 50px;
    height: 80px;
    top: 35px;
  }

  .rembit_why__block__right_block h2 {
    font-size: 20px;
    line-height: 25px;
  }

  .rembit_why__block__right_block .items_block {
    margin-top: 32px;
  }

  .rembit_why__block__right_block .items_block .item .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .rembit_why__block__right_block .items_block .item:nth-child(n+2) {
    margin-top: 65px;
  }

  .rembit_why__block__right_block .items_block .item:nth-child(3) {
    margin-top: 78px;
  }

  .rembit_principles .wrapper::before {
    bottom: -150px;
  }

  .rembit_principles .wrapper::after {
    right: -90px;
  }

  .rembit_principles__block {
    height: 500px;
  }

  .rembit_principles__block::before {
    right: -90px;
  }

  .rembit_principles__block__items {
    justify-content: flex-start;
  }

  .rembit_principles__block__items .item {
    width: calc(33.333333333333336% - 20px);
  }

  .rembit_principles__block__items .item:nth-child(n+4) {
    margin-top: 42px;
  }

  .rembit_scheme {
    height: 590px;
    background-position: 0px 0px;
  }

  .rembit_scheme__block__desc {
    width: 532px;
  }

  .rembit_scheme__block__desc::before {
    left: 35px;
  }

  .rembit_scheme__block__desc::after {
    left: 95px;
  }

  .rembit_scheme__block__desc__main {
    align-items: flex-start;
    padding-left: 35px;
    padding-right: 25px;
  }

  .rembit_scheme__block__desc__main .text {
    margin-right: 20px;
  }

  .rembit_scheme__block__desc__main img {
    width: 152px;
    height: 167px;
  }

  .rembit_scheme__block__desc .number {
    left: 35px;
    bottom: 20px;
  }

  .rembit_questions__block__items {
    width: 580px;
    min-width: 580px;
    margin-right: 20px;
  }

  .rembit_questions__block form {
    width: 410px;
    height: 600px;
  }

  .rembit_questions__block form .br_off {
    display: none;
  }

  .rembit_questions__block form .br_on {
    display: block;
  }

  .rembit_questions__block form .input_block {
    flex-direction: column;
  }

  .rembit_questions__block form .input_block .input_item {
    width: 350px;
  }

  .rembit_questions__block form .input_block .input_item.name {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .rembit_questions__block form textarea {
    width: 350px;
  }

  .rembit_questions__block form .foot {
    flex-direction: column;
  }

  .rembit_questions__block form .foot button {
    width: 350px;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .rembit_reviews {
    height: 585px;
    background-position: 0 0px;
  }

  .rembit_reviews__block {
    width: 880px;
  }

  .rembit_reviews__block .item {
    width: 430px;
    height: 330px;
  }

  .rembit_reviews .flickity-prev-next-button {
    width: 60px;
    height: 66px;
  }

  .rembit_reviews .flickity-prev-next-button.previous {
    left: -73px;
  }

  .rembit_reviews .flickity-prev-next-button.next {
    right: -73px;
  }

  .rembit_description__block {
    width: 800px;
    margin: 0 auto;
  }

  .rembit_description .flickity-prev-next-button {
    width: 60px;
    height: 66px;
  }

  .rembit_map__block .city {
    width: 65%;
    align-items: flex-start;
  }

  .rembit_map__block .city .title {
    font-weight: bold;
  }

  .rembit_map__block .city .items {
    align-items: flex-start;
  }

  .rembit_map__block .area {
    width: 35%;
    flex-direction: column;
  }

  .rembit_map__block .area .title {
    font-weight: bold;
  }

  .rembit_map__block .area .title {
    margin-bottom: 30px;
  }

  .rembit_map__block .area .check_area {
    width: 324px;
  }

  .rembit_brands__block a:nth-child(odd),
  .rembit_brands__block a:nth-child(even) {
    margin-top: 0;
    margin-right: 13px;
  }

  .rembit_brands__block a:nth-child(n+7) {
    margin-top: 13px;
  }

  .rembit_brands__block a:nth-child(6n) {
    margin-right: 0;
  }

  .rembit_faults .wrapper::before {
    left: -130px;
  }

  .rembit_faults .wrapper::after {
    right: -110px;
    top: -30px;
  }

  .rembit_faults__block::after {
    right: -80px;
    top: 320px;
  }

  .rembit_faults__block .item .outer .price {
    width: 200px;
    padding-right: 45px;
  }

  .rembit_faults__block .item .inner {
    padding: 30px 45px 40px 40px;
  }

  .rembit_services__block .item .outer .price {
    width: 200px;
    padding-right: 45px;
  }

  .rembit_services__block .item .inner {
    padding: 30px 45px 40px 40px;
  }

  .rembit_repair__block .item:nth-child(odd),
  .rembit_repair__block .item:nth-child(even) {
    margin-right: 19px;
    margin-top: 20px;
  }

  .rembit_repair__block .item:nth-child(3n) {
    margin-right: 0;
  }

  .rembit_repair__block .item:nth-child(-n+3) {
    margin-top: 0;
  }

  .rembit_materials__block .item {
    width: 194px;
    height: 194px;
  }

  .rembit_materials__block .item:nth-child(odd),
  .rembit_materials__block .item:nth-child(even) {
    margin-right: 10px;
    margin-top: 10px;
  }

  .rembit_materials__block .item:nth-child(5n) {
    margin-right: 0;
  }

  .rembit_materials__block .item:nth-child(-n+5) {
    margin-top: 0;
  }

  .rembit_cost__block-check {
    width: 423px;
    min-width: 423px;
  }

  .rembit_cost__block-check .but_block .more_info {
    font-size: 17px;
  }

  .rembit_cost__block-category .lg_on {
    display: block;
  }

  .rembit_cost__block-category {
    padding-top: 60px;
    padding-left: 60px;
  }

  .rembit_cost__block-category__select_block .select2-container {
    margin-bottom: 30px;
  }

  .rembit_footer__block .description {
    width: 268px;
  }

  .rembit_footer__block .payment {
    width: 352px;
    padding: 0 40px;
  }

  .rembit_footer__block .payment .payment_systems .cards .item:nth-child(4) {
    margin-right: 0;
  }

  .rembit_footer__block .payment .banks .cards .item:nth-child(4) {
    margin-right: 0;
  }

  .rembit_footer__block .contacts {
    padding-left: 30px;
  }

  .rembit_bid_404__block {
    padding-top: 75px;
    padding-left: 50px;
  }

  .rembit_bid_404__block .master {
    width: 373px;
    margin-right: 70px;
  }

  .rembit_bid_404__block .desc {
    padding-top: 10px;
  }

  .rembit_bid_404__block .desc img {
    width: 450px;
  }

  .rembit_bid_404__block .desc .title {
    font-size: 20px;
  }

  .rembit_bid_404__block .desc .return {
    margin-top: 60px;
  }
}

@media screen and (max-width: 1080px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .wrapper {
    max-width: 768px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .debug > div {
    max-width: 768px;
  }

  .top_button {
    right: 10px;
    width: 50px;
    height: 40px;
  }

  .top_button.visible {
    bottom: 20px;
  }

  .rembit_head {
    padding: 12px 0 12px 0;
  }

  .rembit_head__logo {
    width: 285px;
  }

  .rembit_head__logo .icon {
    width: 34px;
    height: 30px;
  }

  .rembit_head__logo .text {
    margin-left: 7px;
  }

  .rembit_head__logo .text .title {
    font-size: 16px;
  }

  .rembit_head__logo .text .desc {
    font-size: 10px;
    letter-spacing: 0.022em;
  }

  .rembit_head__cost {
    width: 150px;
  }

  .rembit_head__cost .icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
  }

  .rembit_head__cost .title {
    font-size: 12px;
    line-height: 13px;
  }

  .rembit_head__question {
    width: 150px;
  }

  .rembit_head__question .icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
  }

  .rembit_head__question .title {
    font-size: 12px;
    line-height: 13px;
  }

  .rembit_head__call {
    align-items: flex-end;
  }

  .rembit_head__call .title {
    font-size: 12px;
    line-height: 20px;
  }

  .rembit_head__call .tel {
    margin-top: 0;
  }

  .rembit_head__call .tel .number {
    font-size: 17px;
  }

  .wrapper_menu {
    background: #F7F7F7;
  }

  .wrapper_menu .menu_top {
    width: 615px;
  }

  .wrapper_menu .menu_top > li {
    padding: 0 0 0 10px;
  }

  .wrapper_menu .menu_top > li:first-of-type,
  .wrapper_menu .menu_top > li:last-of-type {
    padding: 0;
  }

  .wrapper_menu .menu_top .burger:before,
  .wrapper_menu .menu_top .burger:after {
    height: 1px;
  }

  .wrapper_menu .menu_top .burger:after {
    filter: drop-shadow(0 -7px currentColor);
    transform: translateY(14px);
  }

  .wrapper_menu .menu_top .burger.open:after {
    transform: translateY(9px) rotateZ(-40deg);
  }

  .wrapper_menu .menu_top__submenu:nth-child(-n+3) {
    display: none;
  }

  .wrapper_menu .menu_top__submenu.info {
    width: 200px;
    min-width: 200px;
    margin: 0 0 0 auto;
  }

  .wrapper_menu .menu_top__submenu.info > a {
    justify-content: center;
  }

  .wrapper_menu .menu_top__submenu.info .menu_top__submenu_list .time {
    display: flex;
  }

  .wrapper_menu .menu_top__submenu.md {
    display: block;
  }

  .wrapper_menu .menu_top__submenu_title {
    font-size: 13px;
  }

  .wrapper_menu .call {
    width: 200px;
    min-width: 200px;
    font-size: 14px;
    line-height: 16px;
  }

  .rembit_bid__block .rembit_bid__main_block::after {
    background: url(../img/bid/md_master.png) no-repeat;
    top: unset;
    bottom: -190px;
    left: 0;
  }

  .rembit_bid__block .rembit_bid__main_block .text {
    margin-top: 45px;
    padding-left: 20px;
  }

  .rembit_bid__block .rembit_bid__main_block .text h1.stub {
    font-size: 27px;
    line-height: 30px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block {
    height: 320px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block::before {
    display: none;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block::after {
    display: block;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text {
    width: 488px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc .lg_br {
    display: none;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .price {
    font-size: 16px;
    line-height: 16px;
    bottom: 15px;
    left: 30px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .call_separate {
    display: block;
  }

  .rembit_bid_separate__form_bid {
    display: none;
  }

  .rembit_appliances__block .tech {
    width: 174px;
    height: 174px;
    margin-right: 14px;
    margin-top: 14px;
  }

  .rembit_appliances__block .tech:nth-child(odd),
  .rembit_appliances__block .tech:nth-child(even) {
    margin-top: 0;
    margin-right: 14px;
  }

  .rembit_appliances__block .tech:nth-child(4n) {
    margin-right: 0;
  }

  .rembit_appliances__block .tech:nth-child(n+5) {
    margin-top: 14px;
  }

  .rembit_appliances__block_optional .tech {
    width: 362px;
    margin-right: 14px;
    margin-top: 14px;
    padding-left: 30px;
  }

  .rembit_appliances__block_optional .tech .title {
    font-size: 16px;
    line-height: 18px;
  }

  .rembit_why {
    height: auto;
  }

  .rembit_why__block {
    flex-direction: column;
    padding-bottom: 40px;
  }

  .rembit_why__block__left_block {
    margin-top: 40px;
  }

  .rembit_why__block__left_block h2 {
    font-size: 25px;
    line-height: 31px;
  }

  .rembit_why__block__left_block .items_block {
    padding-right: 50px;
    justify-content: space-between;
  }

  .rembit_why__block__left_block .items_block .item {
    width: 46%;
  }

  .rembit_why__block__left_block .items_block .item br {
    display: none;
  }

  .rembit_why__block__left_block .items_block .item .desc {
    font-size: 18px;
    line-height: 23px;
  }

  .rembit_why__block__right_block {
    width: 100%;
    padding-right: 180px;
    height: auto;
    padding-bottom: 50px;
  }

  .rembit_why__block__right_block h2 .md_off {
    display: none;
  }

  .rembit_why__block__right_block .items_block .item .desc {
    font-size: 18px;
    line-height: 23px;
  }

  .rembit_principles {
    background: #ffffff;
  }

  .rembit_principles .wrapper::before {
    bottom: 0px;
    left: -180px;
  }

  .rembit_principles .wrapper::after {
    right: 100px;
    top: 20px;
  }

  .rembit_principles__block {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .rembit_principles__block::before {
    right: 10px;
  }

  .rembit_principles__block h2 {
    font-size: 25px;
    line-height: 34px;
  }

  .rembit_principles__block__items {
    margin-top: 30px;
  }

  .rembit_principles__block__items .item {
    width: calc(50% - 20px);
  }

  .rembit_principles__block__items .item:nth-child(odd),
  .rembit_principles__block__items .item:nth-child(even) {
    margin-top: 0;
  }

  .rembit_principles__block__items .item:nth-child(n+3) {
    margin-top: 42px;
  }

  .rembit_scheme__block {
    display: none;
  }

  .rembit_scheme__block-slider {
    display: block;
  }

  .rembit_questions__block {
    flex-direction: column;
  }

  .rembit_questions__block__items {
    width: 100%;
  }

  .rembit_questions__block form {
    width: 100%;
    height: auto;
    padding-bottom: 55px;
  }

  .rembit_questions__block form::before {
    display: none;
  }

  .rembit_questions__block form::after {
    display: none;
  }

  .rembit_questions__block form .desc {
    width: 445px;
  }

  .rembit_questions__block form .input_block {
    flex-direction: row;
  }

  .rembit_questions__block form .input_block .input_item.name {
    margin-right: 12px;
    margin-bottom: 0;
  }

  .rembit_questions__block form textarea {
    width: 100%;
  }

  .rembit_questions__block form .foot {
    flex-direction: row;
  }

  .rembit_questions__block form .foot button {
    width: 263px;
    min-width: 263px;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .rembit_questions__block form .foot .conditions {
    width: 263px;
  }

  .rembit_questions__block form .foot .conditions br {
    display: none;
  }

  .rembit_reviews {
    padding-top: 50px;
    padding-bottom: 90px;
    height: auto;
  }

  .rembit_reviews h2 {
    margin-bottom: 40px;
  }

  .rembit_reviews__block {
    width: 738px;
  }

  .rembit_reviews__block .flickity-viewport {
    overflow: unset;
  }

  .rembit_reviews__block .flickity-page-dots {
    bottom: -45px;
  }

  .rembit_reviews__block .flickity-page-dots .dot {
    opacity: 1;
    background: url('../img/optional/dot.svg') no-repeat;
    background-size: contain;
    width: 8px;
    border-radius: 0;
    transition: 0.3s;
  }

  .rembit_reviews__block .flickity-page-dots .dot.is-selected {
    background: url(../img/optional/dot_selected.svg) no-repeat;
    background-size: contain;
  }

  .rembit_reviews__block .item {
    width: 567px;
    height: 280px;
    transition: 0.3s;
  }

  .rembit_reviews__block .item:not(.is-selected) {
    opacity: 0.4;
  }

  .rembit_description {
    height: auto;
    padding-bottom: 90px;
  }

  .rembit_description h2 {
    margin-bottom: 50px;
  }

  .rembit_description__block {
    width: 738px;
  }

  .rembit_description__block .flickity-viewport {
    overflow: unset;
  }

  .rembit_description__block .flickity-page-dots {
    bottom: -45px;
  }

  .rembit_description__block .flickity-page-dots .dot {
    opacity: 1;
    background: url('../img/optional/dot.svg') no-repeat;
    background-size: contain;
    width: 8px;
    border-radius: 0;
    transition: 0.3s;
  }

  .rembit_description__block .flickity-page-dots .dot.is-selected {
    background: url(../img/optional/dot_selected.svg) no-repeat;
    background-size: contain;
  }

  .rembit_description__block .item {
    width: 566px;
    height: 320px;
    margin-right: 30px;
  }

  .rembit_map {
    padding-top: 50px;
  }

  .rembit_map__block .city {
    width: 59%;
    flex-direction: column;
  }

  .rembit_map__block .city .title {
    margin-bottom: 20px;
    padding-top: 0;
  }

  .rembit_map__block .city .items {
    width: 407px;
  }

  .rembit_map__block .city .items .item:nth-child(odd),
  .rembit_map__block .city .items .item:nth-child(even) {
    margin: 0;
  }

  .rembit_map__block .city .items .item:nth-child(n+6) {
    margin-top: 14px;
  }

  .rembit_map__block .city .items .item:nth-child(n+2) {
    margin-left: 12px;
  }

  .rembit_map__block .city .items .item:nth-child(6),
  .rembit_map__block .city .items .item:nth-child(11) {
    margin-left: 0;
  }

  .rembit_map__block .area {
    width: 39%;
  }

  .rembit_map__block .area .check_area {
    width: 300px;
  }

  .rembit_brands {
    margin-bottom: 0;
    padding-bottom: 50px;
    background: #ffffff;
  }

  .rembit_brands__block a {
    width: 134px;
    height: 90px;
  }

  .rembit_brands__block a:nth-child(odd),
  .rembit_brands__block a:nth-child(even) {
    margin-top: 0;
    margin-right: 13px;
  }

  .rembit_brands__block a:nth-child(n+6) {
    margin-top: 13px;
  }

  .rembit_brands__block a:nth-child(5n) {
    margin-right: 0;
  }

  .rembit_faults__block .item .outer .price {
    width: 160px;
    padding-right: 0px;
    text-align: center;
  }

  .rembit_faults__block .item .inner .block_but .call {
    width: auto;
    padding: 0 40px;
  }

  .rembit_services h3 {
    font-size: 25px;
    line-height: 34px;
  }

  .rembit_services__block .item .outer .price {
    width: 160px;
    padding-right: 0px;
    text-align: center;
  }

  .rembit_services__block .item .inner .block_but .call {
    width: auto;
    padding: 0 40px;
  }

  .rembit_repair__block .item {
    width: 359px;
  }

  .rembit_repair__block .item:nth-child(odd),
  .rembit_repair__block .item:nth-child(even) {
    margin-right: 19px;
    margin-top: 20px;
  }

  .rembit_repair__block .item:nth-child(2n) {
    margin-right: 0;
  }

  .rembit_repair__block .item:nth-child(-n+2) {
    margin-top: 0;
  }

  .rembit_materials__block .item {
    width: 177px;
    height: 177px;
  }

  .rembit_materials__block .item:nth-child(odd),
  .rembit_materials__block .item:nth-child(even) {
    margin-right: 10px;
    margin-top: 10px;
  }

  .rembit_materials__block .item:nth-child(4n) {
    margin-right: 0;
  }

  .rembit_materials__block .item:nth-child(-n+4) {
    margin-top: 0;
  }

  .rembit_materials__block .item .block_for_img {
    height: 100px;
    margin-bottom: 10px;
  }

  .rembit_materials__block .item .title {
    font-size: 16px;
  }

  .rembit_cost {
    height: auto;
  }

  .rembit_cost .opt_back_left {
    height: 470px;
    width: 100%;
  }

  .rembit_cost .opt_back_right {
    top: 470px;
    width: 100%;
  }

  .rembit_cost__block {
    flex-direction: column;
  }

  .rembit_cost__block-check {
    width: 100%;
    min-width: 100%;
    padding-top: 40px;
  }

  .rembit_cost__block-check h2 {
    width: 460px;
  }

  .rembit_cost__block-check .but_block {
    justify-content: flex-start;
  }

  .rembit_cost__block-check .but_block .action {
    min-width: 315px;
    max-width: 315px;
    margin-right: 20px;
  }

  .rembit_cost__block-check .but_block .more_info {
    font-size: 18px;
  }

  .rembit_cost__block-category {
    padding-top: 170px;
    padding-left: 0;
    padding-bottom: 40px;
  }

  .rembit_footer__block .description {
    padding-right: 15px;
  }

  .rembit_footer__block .description__logo .text .title {
    font-size: 19px;
  }

  .rembit_footer__block .description__logo .text .desc {
    font-size: 12px;
    letter-spacing: 0.02em;
  }

  .rembit_footer__block .description__text {
    margin-top: 30px;
  }

  .rembit_footer__block .payment {
    padding: 0 20px;
    width: 275px;
  }

  .rembit_footer__block .payment .payment_systems .cards .item {
    width: 60px;
    height: 30px;
    margin-right: 5px;
  }

  .rembit_footer__block .payment .banks .cards .item {
    width: 60px;
    height: 30px;
    margin-right: 5px;
  }

  .rembit_footer__block .contacts {
    padding-left: 15px;
  }

  .rembit_footer__block .contacts .tel {
    font-size: 17px;
  }

  .rembit_footer__block .contacts .social a {
    margin-right: 5px;
  }

  .rembit_bid_404 {
    height: 390px;
  }

  .rembit_bid_404__block {
    padding-top: 30px;
    padding-left: 30px;
  }

  .rembit_bid_404__block .master {
    width: 265px;
    margin-right: 35px;
  }

  .rembit_bid_404__block .desc {
    padding-top: 15px;
  }

  .rembit_bid_404__block .desc img {
    width: 344px;
  }

  .rembit_bid_404__block .desc .title {
    font-size: 15px;
    line-height: 17px;
    margin-top: 15px;
  }

  .rembit_bid_404__block .desc .return {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 560px;
  }

  .debug > div {
    max-width: 560px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  .top_button {
    display: none;
    width: 50px;
    height: 40px;
  }

  #modal_call {
    width: 320px;
    height: 460px;
    padding-left: 15px;
    padding-right: 15px;
    overflow: auto;
  }

  #modal_call.fancybox-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  #modal_call .title {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 15px;
  }

  #modal_call .desc {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
  }

  #modal_call .desc .xs_off {
    display: none;
  }

  #modal_accepted {
    width: 320px;
    height: 460px;
  }

  #modal_accepted .title {
    margin-bottom: 10px;
  }

  .rembit_breadcrumb {
    margin-bottom: 20px;
  }

  .rembit_head {
    display: none;
  }

  .rembit_head_mob {
    display: block;
  }

  .wrapper_menu {
    display: none;
  }

  .rembit_bid {
    margin-top: 0;
    position: relative;
    background: #E2EFFF;
  }

  .rembit_bid::after {
    content: '';
    position: absolute;
    width: 269px;
    height: 228px;
    background: url(../img/bid/xs_master.svg) no-repeat;
    top: unset;
    bottom: 35px;
    left: unset;
    right: 0;
  }

  .rembit_bid .call {
    display: block;
  }

  .rembit_bid__block .rembit_bid__main_block {
    height: 486px;
    width: 100%;
    background: none;
    margin-right: 0;
  }

  .rembit_bid__block .rembit_bid__main_block::before {
    display: none;
  }

  .rembit_bid__block .rembit_bid__main_block::after {
    display: none;
  }

  .rembit_bid__block .rembit_bid__main_block .text {
    margin-top: 70px;
    padding: 0;
  }

  .rembit_bid__block .rembit_bid__main_block .text h1.stub {
    font-size: 20px;
    line-height: 25px;
  }

  .rembit_bid__block .rembit_bid__main_block .text h1 {
    font-size: 25px;
    line-height: 28px;
  }

  .rembit_bid__block .rembit_bid__main_block .text .desc .lg_br {
    display: none;
  }

  .rembit_bid__block .rembit_bid__main_block .text .desc .lg_br_none {
    display: none;
  }

  .rembit_bid__form_bid {
    display: none;
  }

  .rembit_bid_separate {
    margin-top: 58px;
    padding-bottom: 35px;
    position: relative;
  }

  .rembit_bid_separate .call {
    display: block;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block {
    flex-direction: column;
    height: auto;
    min-height: 436px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block::before {
    display: none;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block::after {
    display: none;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .block_f_img {
    max-height: 300px;
    margin: 0 auto;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text {
    background: none;
    width: 100%;
    margin-top: 15px;
    padding: 0;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4C4C4C;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc .lg_br_none {
    display: none;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .desc {
    margin-bottom: 20px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .price {
    font-size: 18px;
    line-height: 23px;
    position: relative;
    left: 0;
    padding-top: 10px;
  }

  .rembit_bid_separate__block .rembit_bid_separate__main_block .text .call_separate {
    display: none;
  }

  .rembit_bid_separate__form_bid {
    display: none;
  }

  .rembit_appliances {
    display: none;
  }

  .rembit_appliances_mob {
    display: block;
  }

  .rembit_appliances_mob__slider .slide .tech {
    background: #E2EFFF;
    background-size: cover;
  }

  .rembit_why {
    display: none;
  }

  .rembit_why_mob_one {
    display: block;
  }

  .rembit_why_mob_two {
    display: block;
  }

  .rembit_principles {
    display: none;
  }

  .rembit_principles_mob {
    display: block;
  }

  .rembit_scheme {
    background: #4B1BB1;
    padding-top: 40px;
    height: auto;
    padding-bottom: 90px;
  }

  .rembit_scheme .wrapper {
    padding: 0;
  }

  .rembit_scheme h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 40px;
  }

  .rembit_scheme__block-slider::before {
    display: none;
  }

  .rembit_scheme__block-slider::after {
    display: none;
  }

  .rembit_scheme__block-slider__item__main {
    padding: 30px 15px 30px 15px;
    flex-direction: column-reverse;
  }

  .rembit_scheme__block-slider__item__main img {
    align-self: flex-start;
    width: 160px;
    min-width: 160px;
    height: 160px;
    margin-bottom: 30px;
  }

  .rembit_scheme__block-slider__item .number {
    left: unset;
    bottom: unset;
    top: 30px;
    right: 15px;
  }

  .rembit_scheme .flickity-page-dots {
    bottom: -55px;
  }

  .rembit_questions {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .rembit_questions .wrapper {
    padding: 0;
  }

  .rembit_questions h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4C4C4C;
    margin-bottom: 30px;
  }

  .rembit_questions__block__items {
    min-width: auto;
  }

  .rembit_questions__block__items .item::after {
    right: 15px;
  }

  .rembit_questions__block__items .item .title {
    padding-left: 15px;
  }

  .rembit_questions__block__items .item .text {
    padding-left: 15px;
    padding-right: 15px;
  }

  .rembit_questions__block form {
    background: #E2EFFF;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
  }

  .rembit_questions__block form .title {
    font-size: 18px;
    line-height: 21px;
    width: 160px;
  }

  .rembit_questions__block form .desc {
    font-size: 16px;
    line-height: 20px;
    width: 225px;
    margin-bottom: 25px;
  }

  .rembit_questions__block form .input_block {
    flex-direction: column;
  }

  .rembit_questions__block form .input_block .input_item {
    width: 100%;
  }

  .rembit_questions__block form .input_block .input_item.name {
    margin-bottom: 20px;
  }

  .rembit_questions__block form .foot {
    flex-direction: column;
  }

  .rembit_questions__block form .foot button {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }

  .rembit_reviews {
    padding-top: 40px;
    background: #4B1BB1;
  }

  .rembit_reviews .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .rembit_reviews .flickity-page-dots {
    bottom: -55px;
  }

  .rembit_reviews h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

  .rembit_reviews__block {
    width: 100%;
  }

  .rembit_reviews__block .item {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 80px;
    width: 100%;
    height: auto;
  }

  .rembit_reviews__block .item .date {
    top: unset;
    right: unset;
    left: 15px;
    bottom: 40px;
  }

  .rembit_description {
    background: #E2EFFF;
    padding-top: 40px;
  }

  .rembit_description .wrapper {
    padding: 0;
  }

  .rembit_description .flickity-page-dots {
    bottom: -55px;
  }

  .rembit_description h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #784FD1;
    margin-bottom: 30px;
  }

  .rembit_description__block {
    width: 100%;
  }

  .rembit_description__block .item {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 65px;
    width: 100%;
    height: auto;
  }

  .rembit_map {
    padding-top: 40px;
    background: #ffffff;
    display: none;
  }

  .rembit_map h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #784FD1;
  }

  .rembit_map__block {
    flex-direction: column;
  }

  .rembit_map__block .city {
    margin-bottom: 30px;
    width: 100%;
  }

  .rembit_map__block .city .items {
    width: 100%;
  }

  .rembit_map__block .city .items .item:nth-child(odd),
  .rembit_map__block .city .items .item:nth-child(even) {
    margin: 0;
    margin-bottom: 15px;
    margin-right: 10px;
  }

  .rembit_map__block .area {
    width: 100%;
  }

  .rembit_map__block .area .check_area {
    width: 290px;
  }

  .rembit_brands {
    margin-bottom: 40px;
    display: none;
  }

  .rembit_brands_mob {
    display: block;
  }

  .rembit_faults .wrapper {
    padding: 0;
  }

  .rembit_faults .wrapper::before {
    display: none;
  }

  .rembit_faults .wrapper::after {
    display: none;
  }

  .rembit_faults h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4C4C4C;
    padding: 0 15px !important;
    margin-bottom: 25px;
  }

  .rembit_faults h2 .xs_on {
    display: block;
  }

  .rembit_faults__block::after {
    display: none;
  }

  .rembit_faults__block .item .outer {
    height: 80px;
    max-height: 80px;
    box-sizing: border-box;
  }

  .rembit_faults__block .item .outer .title .arrow {
    margin-left: 15px;
    margin-right: 10px;
  }

  .rembit_faults__block .item .outer .title .text {
    font-size: 13px;
    line-height: 16px;
    padding-right: 5px;
  }

  .rembit_faults__block .item .outer .price {
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    min-width: 90px;
  }

  .rembit_faults__block .item .outer .price::before {
    height: 79px;
  }

  .rembit_faults__block .item .inner {
    padding: 30px 15px 40px 15px;
  }

  .rembit_faults__block .item .inner .block_but {
    flex-direction: column;
  }

  .rembit_faults__block .item .inner .block_but .more {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .rembit_faults__block .item .inner .block_but .call {
    width: 100%;
    height: 50px;
  }

  .rembit_services {
    background: #E2EFFF;
    background-size: cover;
  }

  .rembit_services .wrapper {
    padding: 0;
  }

  .rembit_services h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4C4C4C;
    padding: 0 15px !important;
    margin-bottom: 25px;
  }

  .rembit_services h3 .xs_on {
    display: block;
  }

  .rembit_services__block .item .outer {
    height: 80px;
    max-height: 80px;
    box-sizing: border-box;
  }

  .rembit_services__block .item .outer .title .arrow {
    margin-left: 15px;
    margin-right: 10px;
  }

  .rembit_services__block .item .outer .title .text {
    font-size: 13px;
    line-height: 16px;
    padding-right: 5px;
  }

  .rembit_services__block .item .outer .price {
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    min-width: 90px;
  }

  .rembit_services__block .item .outer .price::before {
    height: 79px;
  }

  .rembit_services__block .item .inner {
    padding: 30px 15px 40px 15px;
  }

  .rembit_services__block .item .inner .block_but {
    flex-direction: column;
  }

  .rembit_services__block .item .inner .block_but .more {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .rembit_services__block .item .inner .block_but .call {
    width: 100%;
    height: 50px;
  }

  .rembit_repair {
    background: #E2EFFF;
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .rembit_repair .wrapper {
    padding: 0;
  }

  .rembit_repair h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #4C4C4C;
    padding: 0 15px !important;
  }

  .rembit_repair__block {
    display: block;
  }

  .rembit_repair__block .flickity-page-dots {
    bottom: -70px;
  }

  .rembit_repair__block .flickity-page-dots .dot {
    opacity: 1;
    background: url('../img/optional/dot.svg') no-repeat;
    background-size: contain;
    width: 8px;
    border-radius: 0;
    transition: 0.3s;
  }

  .rembit_repair__block .flickity-page-dots .dot.is-selected {
    background: url(../img/optional/dot_selected.svg) no-repeat;
    background-size: contain;
  }

  .rembit_repair__block .item {
    width: 100%;
    padding: 0 15px 40px 15px;
  }

  .rembit_repair__block .item:nth-child(odd),
  .rembit_repair__block .item:nth-child(even) {
    margin: 0;
  }

  .rembit_repair .show_all {
    display: none;
  }

  .rembit_materials {
    display: none;
  }

  .rembit_materials_mob {
    display: block;
  }

  .rembit_cost__block-check h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 100%;
  }

  .rembit_cost__block-check h2 .xs_on {
    display: block;
  }

  .rembit_cost__block-check .but_block {
    flex-wrap: wrap;
  }

  .rembit_cost__block-check .but_block .action {
    min-width: 100%;
    max-width: 100%;
  }

  .rembit_cost__block-check .but_block .more_info {
    margin: auto;
    margin-top: 30px;
    padding: 0;
  }

  .rembit_cost__block-category {
    padding-top: 80px;
  }

  .rembit_cost__block-category__select_block {
    height: 300px;
    overflow-y: auto;
    padding-top: 13px;
  }

  .rembit_footer {
    height: 880px;
    bottom: -880px;
  }

  .rembit_footer__block {
    flex-direction: column;
  }

  .rembit_footer__block .description {
    order: 1;
    margin-top: 25px;
    width: 100%;
    max-width: 361px;
  }

  .rembit_footer__block .payment {
    width: 100%;
    border: none;
    padding: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .rembit_footer__block .payment .payment_systems .cards .item:nth-child(odd),
  .rembit_footer__block .payment .payment_systems .cards .item:nth-child(even) {
    margin: 0;
    margin-right: 5px;
    margin-bottom: 15px;
  }

  .rembit_footer__block .payment .banks {
    margin-top: 25px;
  }

  .rembit_footer__block .payment .banks .cards .item:nth-child(odd),
  .rembit_footer__block .payment .banks .cards .item:nth-child(even) {
    margin: 0;
    margin-right: 5px;
    margin-bottom: 15px;
  }

  .rembit_footer__block .contacts {
    padding: 0;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .rembit_footer__block .contacts .tel {
    font-size: 20px;
  }

  .rembit_footer__block .contacts .social {
    margin-top: 30px;
  }

  .rembit_footer__block .contacts .social a {
    margin-right: 15px;
  }

  .rembit_bid_404__block {
    padding-left: 0;
  }

  .rembit_bid_404__block .master {
    display: none;
  }

  .rembit_bid_404__block .desc {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rembit_bid_404__block .desc img {
    width: 257px;
  }

  .rembit_bid_404__block .desc .title {
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
    text-align: center;
  }

  .rembit_bid_404__block .desc .return {
    width: 290px;
    margin-top: 60px;
  }
}

@media (max-width: 768px) {

}