@import "entry";

.rembit_appliances {
    margin-top: 20px;

    .xs-block({
        display: none;
    });

    &__block {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .tech {
            height: 220px;
            width: 220px;
            background: url(../img/appliances/back_1.png) no-repeat;
            background-size: cover;
            margin-right: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 30px;
            box-sizing: border-box;
            position: relative;

            .lg-block({
                width: 162px;
                height: 162px;
                margin-right: 8px;
                padding-top: 22px;
            });
            .md-block({
                width: 174px;
                height: 174px;
                margin-right: 14px;
                margin-top: 14px;
            });

            &:nth-child(8n+2) {
                background: url(../img/appliances/back_2.png) no-repeat;
                background-size: cover;
            }
            &:nth-child(8n+3) {
                background: url(../img/appliances/back_3.png) no-repeat;
                background-size: cover;
            }
            &:nth-child(8n+4) {
                background: url(../img/appliances/back_4.png) no-repeat;
                background-size: cover;
            }
            &:nth-child(5) {
                background: url(../img/appliances/back_5.png) no-repeat;
                background-size: cover;
            }
            &:nth-child(6) {
                background: url(../img/appliances/back_3.png) no-repeat;
                background-size: cover;
            }
            &:nth-child(7) {
                background: url(../img/appliances/back_3.png) no-repeat;
                background-size: cover;
            }

            &:nth-child(6n) {
                margin-right: 0;
            }
            &:nth-child(n+7) {
                margin-top: 8px;
            }

            .md-block({
                &:nth-child(odd), &:nth-child(even) {
                    margin-top: 0;
                    margin-right: 14px;
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }
                &:nth-child(n+5) {
                    margin-top: 14px;
                }
            });

            &:hover {
                transition: .3s;
                background: url(../img/appliances/back_hover.svg) no-repeat;
                background-size: cover;
                
                & .icon {
                    transition: .2s;
                    opacity: 0;
                }
                & .title {
                    transition: .3s;
                    color: #ffffff;
                }
            }

            .price {
                font-weight: normal;
                font-size: 30px;
                line-height: 35px;
                text-align: center;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                top: 78px;
                position: absolute;

                opacity: 0;
                display: none;

                .lg-block({
                    top: 70px;
                    font-size: 27px;
                    line-height: 27px;
                });
            }

            .icon {
                width: 110px;
                height: 110px;
                margin-bottom: 20px;

                .lg-block({
                    height: 82px;
                    width: 82px;
                });
            }
            .title {
                color: @fontColor;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.04em;

                .lg-block({
                    font-size: 14px;
                    line-height: 16px;
                });
            }
        }
    }

    &__block_optional {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .tech {
            height: 130px;
            width: 676px;
            background: url(../img/appliances/back_2_1.png) no-repeat;
            background-size: cover;
            margin-right: 8px;
            margin-top: 8px;
            padding-left: 50px;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .lg-block({
                height: 96px;
                width: 502px;
                margin-right: 8px;
            });
            .md-block({
                width: 362px;
                margin-right: 14px;
                margin-top: 14px;
                padding-left: 30px;
            });

            &:last-child {
                margin-right: 0;
            }
            
            &:hover {
                transition: .3s;
                background: url(../img/appliances/opt_back_hover.svg) no-repeat;
                background-size: cover;
                
                & .icon {
                    transition: .2s;
                    opacity: 0;
                }
                & .title {
                    transition: .3s;
                    color: #ffffff;
                }
            }

            .price {
                font-weight: normal;
                line-height: 35px;
                font-size: 30px;
                text-align: center;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                position: absolute;

                opacity: 0;
                display: none;

                .lg-block({
                    font-size: 27px;
                    line-height: 27px;
                });
            }

            .icon {
                width: 130px;
                height: 105px;
                fill: @fontColor;
                margin-right: 70px;

                .lg-block({
                    width: 100px;
                    height: 80px;
                });
            }
            .title {
                color: @fontColor;
                font-size: 25px;
                line-height: 29px;
                letter-spacing: 0.04em;

                .lg-block({
                    font-size: 20px;
                    line-height: 23px;
                });
                .md-block({
                    font-size: 16px;
                    line-height: 18px;
                });
            }
        }

    }
}








.rembit_appliances_mob {
    display: none;

    padding-top: 25px;
    padding-bottom: 60px;

    .xs-block({
        display: block;
    });

    &__slider {
        width: 100%;

        .dots(@dotGray, -38px);

        .slide {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &.optional {
                .tech {
                    margin: 0;
                    padding: 0;
                    padding-left: 15px;
                    padding-right: 20px;
                    margin-bottom: 2%;
                    width: 100%;
                    flex-direction: row;
                    justify-content: center;
                    
                    .icon {
                        width: 90px;
                        max-width: 100px;
                        margin-right: 35px;
                    }
                    .price {
                        position: absolute;
                        top: 60px;
                        left: 15px;
                    }
                }
            }

            .tech {
                width: 48%;
                height: 150px;
                max-height: 250px;
                background: url(../img/appliances/back_1.png) no-repeat;
                background-size: cover;
                box-sizing: border-box;

                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin-right: 2%;
                margin-bottom: 2%;
                padding-top: 20px;

                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(n+3) {
                    margin-top: 0;
                }

                .xs-block({
                    background: @back;
                    background-size: cover;
                });
                
                // &:hover {
                //     transition: .3s;
                //     background: url(../img/appliances/opt_back_hover.svg) no-repeat;
                //     background-size: cover;

                //     .xs-block({
                //         background: #75ACED;
                //         background-size: cover;
                //     });
                    
                //     & .icon {
                //         transition: .2s;
                //         opacity: 0;
                //     }
                //     & .title {
                //         transition: .3s;
                //         color: #ffffff;
                //     }
                // }

                .price {
                    font-weight: normal;
                    font-size: 27px;
                    line-height: 27px;
                    text-align: center;
                    letter-spacing: 0.04em;
                    color: #FFFFFF;
                    position: absolute;
    
                    opacity: 0;
                    top: 50px;
                    display: none;
                }
                .icon {
                    width: 70px;
                    max-width: 70px;
                    fill: @fontColor;
                    padding-bottom: 15px;
                }
                .title {
                    color: @fontColor;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.04em;
                }
            }
        }
    }

}