@import "entry";

.rembit_map {
    padding-top: 60px;
    box-sizing: border-box;
    position: relative;

    .md-block({
        padding-top: 50px;
    });
    .xs-block({
        padding-top: 40px;
        background: #ffffff;
        display: none;
    });

    h2 {
        .h2_color();
        margin-bottom: 30px;

        .xs-block({
            .h2_mob(@optiColor);
        });
    }
    &__block {
        display: flex;
        margin-bottom: 30px;

        .xs-block({
            flex-direction: column;
        });

        .title() {
            font-weight: 500;
            line-height: 20px;
            font-size: 16px;
            letter-spacing: 0.02em;
            color: #070707;

            .lg-block({
                font-weight: bold;
            });
        }
        .city {
            width: 50%;
            display: flex;

            .lg-block({
                width: 65%;
                align-items: flex-start;
            });
            .md-block({
                width: 59%;
                flex-direction: column;
            });
            .xs-block({
                margin-bottom: 30px;
                width: 100%;
            });
            .title {
                .title();
                margin-right: 20px;
                padding-top: 4px;

                .md-block({
                    margin-bottom: 20px;
                    padding-top: 0;
                });
            }
            .items {
                width: 500px;
                display: flex;
                flex-wrap: wrap;

                .lg-block({
                    align-items: flex-start;
                });
                .md-block({
                    width: 407px;
                });
                .xs-block({
                    width: 100%;
                });
                .item {
                    font-weight: normal;
                    line-height: 18px;
                    font-size: 14px;
                    text-align: center;
                    letter-spacing: 0.02em;
                    color: #000000;

                    // cursor: pointer;
                    cursor: not-allowed; // temporary
                    border: 1px solid #E2EFFF;
                    box-sizing: border-box;
                    border-radius: 20px;
                    padding: 5px 13px 5px 13px;

                    &:nth-child(n+7) {
                        margin-top: 10px;
                    }
                    &:nth-child(n+2) {
                        margin-left: 8px;
                    }
                    &:nth-child(7) {
                        margin-left: 0;
                    }

                    .md-block({
                        &:nth-child(odd), &:nth-child(even) {
                            margin: 0;
                        }
                        &:nth-child(n+6) {
                            margin-top: 14px;
                        }
                        &:nth-child(n+2) {
                            margin-left: 12px;
                        }
                        &:nth-child(6), &:nth-child(11) {
                            margin-left: 0;
                        }
                    });
                    .xs-block({
                        &:nth-child(odd), &:nth-child(even) {
                            margin: 0;
                            margin-bottom: 15px;
                            margin-right: 10px;
                        }
                    });

                    // &:hover {
                    //     transition: .3s;
                    //     color: @firstLinkHover;
                    //     border-color: @firstLinkHover;
                    // }
                    // &:active {
                    //     transition: 0s;
                    //     font-weight: bold;
                    //     color: #ffffff;
                    //     border-color: @firstLinkHover;
                    //     background: @firstLinkHover;
                    // }
                    &.item_1, &.item_2, &.item_3, &.item_6, &.item_7, &.item_8, &.item_11 {
                        width: 64px;
                        max-width: 64px;
                    }
                    &.item_4, &.item_5, &.item_9, &.item_10{
                        width: 74px;
                        max-width: 74px;
                    }
                    &.item_12{
                        width: 120px;
                        max-width: 120px;
                    }
                }
            }
        }
        .area {
            width: 50%;
            display: flex;
            align-items: flex-start;

            .lg-block({
                width: 35%;
                flex-direction: column;
            });
            .md-block({
                width: 39%;
            });
            .xs-block({
                width: 100%;
            });

            .title {
                .title();
                margin-right: 20px;

                .lg-block({
                    margin-bottom: 30px;
                });
            }

            .check_area {
                width: 440px;
                
                .lg-block({
                    width: 324px;
                });
                .md-block({
                    width: 300px;
                });
                .xs-block({
                    width: 290px;
                });
            }
        }
    }

    #map {
        width: 100%; 
        height: 400px;
    }
}