@import "entry";

.rembit_scheme {
    background: #3D0E92;
    background-position: 0px -32px;
    height: 565px;
    box-sizing: border-box;
    padding-top: 65px;

    &.visible {
        background: #3D0E92 url(../img/why/back.jpg) no-repeat;
        background-size: cover;
    }

    .lg-block({
        height: 590px;
        background-position: 0px 0px;
    });
    .xs-block({
        background: @backMob;
        padding-top: 40px;
        height: auto;
        padding-bottom: 90px;

        .wrapper {
            padding: 0;
        }
    });

    h2 {
        .h2();
        margin-bottom: 50px;

        .xs-block({
            .h2_mob();
            margin-bottom: 40px;
        });
    }
    
    &__block {
        display: flex;

        .md-block({
            display: none;
        });

        &__check {
            width: 440px;
            min-width: 440px;
            margin-right: 40px;

            .item {
                font-weight: 500;
                line-height: 20px;
                font-size: 16px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                
                background: #4D1CB5;
                height: 58px;
                display: flex;
                align-items: center;
                padding-left: 30px;
                transition: .3s;
                position: relative;
                z-index: 3;
                cursor: pointer;

                &:not(:first-child) {
                    margin-top: 3px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: -32px;
                    width: 0; 
                    height: 0;
                    border-top: 16px solid transparent;
                    border-left: 41px solid linear-gradient(262.69deg, #784FD1 1.5%, #7130FB 109.46%), #784FD1;;
                    border-bottom: 16px solid transparent;
                    opacity: 0;
                    transition: .15s;
                }

                &:hover {
                    background: linear-gradient(262.69deg, #784FD1 1.5%, #7130FB 109.46%), #784FD1;
                }
                &.active {
                    background: linear-gradient(262.69deg, #784FD1 1.5%, #7130FB 109.46%), #784FD1;
                    transform: translateX(30px);

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
        &__desc  {
            width: 100%; 
            height: calc(58px * 6 + 3px * 5);
            background: #ffffff;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);  
            position: relative;

            .lg-block({
                width: 532px;
            });

            &::before {
                content: '';
                background: url(../img/scheme/nut_1.png) no-repeat;
                background-size: contain;
                width: 50px;
                height: 43px;
                position: absolute;
                top: -8px;
                left: 60px; 

                .lg-block({
                    left: 35px;
                });
            }

            &::after {
                content: '';
                background: url(../img/scheme/nut_2.png) no-repeat;
                background-size: contain;
                width: 48px;
                height: 57px;
                position: absolute;
                top: -28px;
                left: 120px; 

                .lg-block({
                    left: 95px;
                });
            }

            &__main {
                display: flex;
                align-items: center;
                padding-left: 60px;
                padding-right: 50px;
                padding-top: 50px;

                .lg-block({
                    align-items: flex-start;
                    padding-left: 35px;
                    padding-right: 25px;
                });

                &.fade {
                    animation: fade .3s linear;
                }
                &.show {
                    animation: show .3s linear;
                }

                .text {
                    font-weight: normal;
                    line-height: 20px;
                    font-size: 16px;
                    letter-spacing: 0.02em;
                    color: @fontColor ;

                    width: 480px;
                    margin-right: 50px;

                    .lg-block({
                        margin-right: 20px;
                    });
                }
                img {
                    width: 240px;
                    height: 260px;

                    .lg-block({
                        width: 152px;
                        height: 167px;
                    });
                }
            }

            .number {
                font-weight: bold;
                line-height: 61px;
                font-size: 45px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #F2EDFA;

                position: absolute;
                left: 60px;
                bottom: 44px;

                .lg-block({
                    left: 35px;
                    bottom: 20px;
                });

                &.fade {
                    animation: fade .3s linear;
                }
                &.show {
                    animation: show .3s linear;
                }
            }
        }
    }
    

    &__block-slider {
        display: none;
        background: #FFFFFF;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);

        .md-block({
            display: block;
        });

        &::before {
            content: '';
            background: url(../img/scheme/nut_1.png) no-repeat;
            background-size: contain;
            width: 50px;
            height: 43px;
            position: absolute;
            top: -8px;
            left: 50px; 

            .xs-block({
                display: none;
            });
        }

        &::after {
            content: '';
            background: url(../img/scheme/nut_2.png) no-repeat;
            background-size: contain;
            width: 48px;
            height: 57px;
            position: absolute;
            top: -28px;
            left: 110px; 

            .xs-block({
                display: none;
            });
        }

        &__item {
            position: relative;
            width: 100%;

            &__main {
                display: flex;
                align-items: center;
                padding: 50px;

                .xs-block({
                    padding: 30px 15px 30px 15px;
                    flex-direction: column-reverse;
                });

                .text {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    color: @fontColor;

                    margin-right: 30px;
                }
                img {
                    width: 240px;
                    min-width: 240px;
                    height: 260px;

                    .xs-block({
                        align-self: flex-start;
                        width: 160px;
                        min-width: 160px;
                        height: 160px;
                        margin-bottom: 30px;
                    });
                }
            }
            .number {
                font-weight: bold;
                line-height: 61px;
                font-size: 45px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #F2EDFA;

                position: absolute;
                left: 50px;
                bottom: 20px;

                .xs-block({
                    left: unset;
                    bottom: unset;
                    top: 30px;
                    right: 15px;
                });
            }
        }
    }

    .flickity-page-dots {
        bottom: -45px;

        .xs-block({
            bottom: -55px;
        });

        .dot {
            opacity: 1;
            background: url(../img/optional/dot.svg) no-repeat;
            background-size: contain;
            width: 8px;
            border-radius: 0;
            transition: .3s;

            &.is-selected {
                background: url(../img/optional/dot_selected.svg) no-repeat;
                background-size: contain;
            }
        }
    }

}


@keyframes fade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}