@import "entry";

.rembit_why {
    margin-top: 20px;
    background: #3D0E92;
    height: 610px;

    &.visible {
        background: #3D0E92 url(../img/why/back.jpg) no-repeat;
        background-size: cover;
    }

    .lg-block({
        background-position: -7px 0px;
    });
    .md-block({
        height: auto;
    });
    .xs-block({
        display: none;
    });

    &__block {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .md-block({
            flex-direction: column;
            padding-bottom: 40px;
        });

        &__left_block {
            margin-top: 70px;
            font-size: 30px;
            line-height: 38px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #FFFFFF;

            .md-block({
                margin-top: 40px;
            });

            h2 {
                font-weight: bold;

                .md-block({
                    font-size: 25px;
                    line-height: 31px;
                });
            }
            .items_block {
                margin-top: 50px;
                display: flex;
                flex-wrap: wrap;

                .md-block({
                    padding-right: 50px;
                    justify-content: space-between;
                });

                .item {
                    display: flex;
                    align-items: center;
                    width: 46%;

                    .lg-block({
                        width: 40%;
                    });
                    .md-block({
                        width: 46%;

                        br {
                            display: none;
                        }
                    });

                    .lg_br_none {
                        .lg-block({
                            display: none;
                        });
                    }

                    .icon {
                        fill: #FFFFFF;
                        width: 50px;
                        height: 50px;
                        margin-right: 25px;
                    }
                    .desc {
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 23px;
                        letter-spacing: 0.02em;
                        letter-spacing: .04em;
                        text-transform: initial;

                        .lg-block({
                            font-size: 16px;
                            line-height: 20px;
                        });
                        .md-block({
                            font-size: 18px;
                            line-height: 23px;
                        });
                    }

                    &:nth-child(2n-1) {
                        margin-right: 50px;
                    }
                    &:nth-child(n+3) {
                        margin-top: 60px;
                    }
                }

            }
        }
    
        &__right_block {
            margin-top: 37px;
            width: 575px;
            min-width: 575px;
            height: 530px;
            background: #ffffff;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.55);
            padding-top: 40px;
            padding-left: 50px;
            padding-right: 150px;
            box-sizing: border-box;
            position: relative;

            .lg-block({
                margin-top: 46px;
                width: 409px;
                min-width: 409px;
                height: 500px;
                padding-left: 36px;
                padding-right: 25px;
            });
            .md-block({
                width: 100%;
                padding-right: 180px;
                height: auto;
                padding-bottom: 50px;
            });

            &::before {
                content: '';
                background: url(../img/why/block.svg) no-repeat;
                background-size: contain;
                width: 63px;
                height: 100px;
                position: absolute;
                top: 20px;
                right: -1px;

                .lg-block({
                    width: 50px;
                    height: 80px;
                    top: 35px;
                });
            }

            h2 {
                font-weight: bold;
                font-size: 22px;
                line-height: 30px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: @fontColor;

                .lg-block({
                    font-size: 20px;
                    line-height: 25px;
                });
                .md-block({
                    .md_off {
                        display: none;
                    }
                });
            }

            .items_block {
                margin-top: 27px;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;

                .lg-block({
                    margin-top: 32px;
                });

                .item {
                    display: flex;

                    .icon {
                        width: 50px;
                        height: 50px;
                        margin-right: 35px;
                    }
                    .desc {
                        line-height: 23px;
                        font-size: 18px;
                        letter-spacing: 0.02em;
                        font-weight: normal;
                        color: @fontColor;

                        .lg-block({
                            font-size: 16px;
                            line-height: 20px;
                        });
                        .md-block({
                            font-size: 18px;
                            line-height: 23px;
                        });
                    }

                    &:nth-child(n+2) {
                        margin-top: 60px;

                        .lg-block({
                            margin-top: 65px;
                        });
                    }
                    &:nth-child(3) {
                        .lg-block({
                            margin-top: 78px;
                        });
                    }
                }
            }
        }
    }
}




.rembit_why_mob_one {
    background: @backMob;
    display: none;

    .xs-block({
        display: block;
    });

    &__block {
        padding-top: 40px;
        padding-bottom: 100px;
        h2 {
            .h2_mob();
            margin-bottom: 30px;
        }
        .slider {
            .dots(@dot, -50px);

            .slide {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                .icon {
                    width: 70px;
                    height: 70px;
                    margin-bottom: 20px;
                }
                .desc {
                    .desc_mob();
                }
            }
        }
    }
}


.rembit_why_mob_two {
    display: none;
    background: #ffffff;

    .xs-block({
        display: block;
    });

    &__block {        
        padding-top: 40px;
        padding-bottom: 100px;
        h2 {
            .h2_mob(@fontColor);
            margin-bottom: 30px;
        }
        .slider {
            .dots(@dotGray, -50px);

            .slide {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                .icon {
                    width: 70px;
                    height: 70px;
                    margin-bottom: 20px;
                }
                .desc {
                    .desc_mob(@fontColor);
                }
            }
        }
    }
}