@import "entry";

.rembit_footer {
    box-sizing: border-box;
    background: #3D0E92;
    height: 360px;
    position: absolute;
    bottom: 0;
    bottom: -360px;
    left: 0;
    right: 0;

    &.visible {
        background: #3D0E92 url(../img/footer/back.jpg) no-repeat;
        background-size: cover;
    }

    .xs-block({
        height: 880px;
        bottom: -880px;
    });

    .title() {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.02em;
        margin-bottom: 25px;
    }

    &__block {
        color: #ffffff;
        padding-top: 30px;
        display: flex;
        .xs-block({
            flex-direction: column;
        });

        .description {
            width: 415px;
            padding-right: 30px;
            .lg-block({
                width: 268px;
            });
            .md-block({
                padding-right: 15px;
            });
            .xs-block({
                order: 1;
                margin-top: 25px;
                width: 100%;
                max-width: 361px;
            });
            &__logo {
                display: flex;
                cursor: pointer;
                .icon {
                    fill: #ffffff;
                    width: 49px;
                    height: 39px;
                    transition: .3s;
                }
                .text {
                    margin-left: 13px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    transition: .3s;
                    .title {
                        font-weight: bold;
                        font-size: 20px;
                        line-height: normal;
                        letter-spacing: 0.04em;
                        text-transform: uppercase;

                        .md-block({
                            font-size: 19px;
                        });
                    }
                    .desc {
                        font-weight: normal;
                        font-size: 13px;
                        line-height: normal;
                        letter-spacing: 0.01em;

                        .md-block({
                            font-size: 12px;
                            letter-spacing: .02em;
                        });
                    }
                }
            }
            &__text {
                width: 100%;
                margin-top: 50px;
                .md-block({
                    margin-top: 30px;
                });
                p {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: 0.02em;
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .payment {
            width: 350px;
            padding: 0 100px;
            border-right: 1px solid rgba(255, 255, 255, .1);
            border-left: 1px solid rgba(255, 255, 255, .1);
            .lg-block({
                width: 352px;
                padding: 0 40px;
            });
            .md-block({
                padding: 0 20px;
                width: 275px;
            });
            .xs-block({
                width: 100%;
                border: none;
                padding: 0;
                padding-bottom: 30px;
                border-bottom: 1px solid rgba(255, 255, 255, .1);
            });
            .title {
                .title();
            }
            .cards() {
                .cards {
                    display: flex;
                    flex-wrap: wrap;
                    .item {
                        background: #fff;
                        width: 70px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 4px;
                        box-sizing: border-box;
                        margin-right: 15px;
                        &:nth-child(n+5) {
                            margin-top: 15px;
                        }
                        .md-block({
                            width: 60px;
                            height: 30px;
                            margin-right: 5px;
                        });
                        .lg-block({
                            &:nth-child(4) {
                                margin-right: 0;
                            }
                        });
                        .xs-block({
                            &:nth-child(odd), &:nth-child(even) {
                                margin: 0;
                                margin-right: 5px;
                                margin-bottom: 15px;
                            }
                        });
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
            .payment_systems {
                .cards();
            }
            .banks {
                margin-top: 40px;
                .xs-block({
                    margin-top: 25px;
                });
                .cards();
            }
        }
        .contacts {
            padding-left: 60px;
            .lg-block({
                padding-left: 30px;
            });
            .md-block({
                padding-left: 15px;
            });
            .xs-block({
                padding: 0;
                margin-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid rgba(255, 255, 255, .1);
            });
            .title {
                .title();
            }
            .tel {
                font-weight: bold;
                font-size: 20px;
                line-height: normal;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                display: block;
                margin-bottom: 15px;

                .md-block({
                    font-size: 17px;
                });
                .xs-block({
                    font-size: 20px;
                });
            }
            .text() {
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
            }
            .address {
                .text();
                margin-bottom: 20px;
            }
            .time {
                .text();
                line-height: 18px;
            }
            .social {
                margin-top: 40px;
                .xs-block({
                    margin-top: 30px;
                });
                a {
                    margin-right: 15px;
                    .md-block({
                        margin-right: 5px;
                    });
                    .xs-block({
                        margin-right: 15px;
                    });
                    img {
                        width: 40px;
                        height: 46px;
                    }
                }
            }
        }
    }
}