@import "entry";

.rembit_brands {
    padding-top: 50px;
    margin-bottom: 40px;
    box-sizing: border-box;

    .md-block({
        margin-bottom: 0;
        padding-bottom: 50px;
        background: #ffffff;
    });
    .xs-block({
        margin-bottom: 40px;
        display: none;
    });

    h2 {
        .h2_gray();
        margin-bottom: 30px;
    }

    &__block {
        display: flex;
        flex-wrap: wrap;

        a {
            box-sizing: border-box;
            width: 157px;
            height: 100px;
            margin-right: 13px;
            border: 1px solid @back;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 10px;

            &:hover {
                box-shadow: 0 0 5px #e2efff;
                transition: .3s;
            }
            
            &:nth-child(8n) {
                margin-right: 0;
            }
            &:nth-child(n+9) {
                margin-top: 13px;
            }
            .lg-block({
                &:nth-child(odd), &:nth-child(even) {
                    margin-top: 0;
                    margin-right: 13px;
                }
                &:nth-child(n+7) {
                    margin-top: 13px;
                }
                &:nth-child(6n) {
                    margin-right: 0;
                }
            });
            .md-block({
                width: 134px;
                height: 90px;

                &:nth-child(odd), &:nth-child(even) {
                    margin-top: 0;
                    margin-right: 13px;
                }
                &:nth-child(n+6) {
                    margin-top: 13px;
                }
                &:nth-child(5n) {
                    margin-right: 0;
                }
            });
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .show_all {
        .btn_show_all();
    }
}



.rembit_brands_mob {
    display: none;
    background: #ffffff;
    padding-top: 40px;
    padding-bottom: 90px;
    .dots(@dotGray; -45px);

    .xs-block({
        display: block;
    });

    h2 {
        .h2_mob(@fontColor);
        margin-bottom: 25px;
    }

    &__slider {
        .item {
            width: 100%;
            display: flex;
            flex-wrap: wrap;        
            a {
                box-sizing: border-box;
                width: 48%;
                height: 100px;
                margin-right: 2%;
                border: 1px solid #e2efff;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px 10px;
                
                &:nth-child(even) {
                    margin-right: 0;
                }
                &:nth-child(-n+6) {
                    margin-bottom: 10px;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                }
            }
        }
    }
}