@import "entry";

.rembit_description {
    box-sizing: border-box;
    padding-top: 60px;
    background: #E2EFFF url(../img/description/back.png) no-repeat;
    background-size: cover;
    height: 760px;

    .md-block({
        height: auto;
        padding-bottom: 90px;
    });
    .xs-block({
        background: @back;
        padding-top: 40px;

        .wrapper {
            padding: 0;
        }
        .flickity-page-dots {
            bottom: -55px;
        }
    });

    h2 {
        .h2_color();
        margin-bottom: 70px;

        .md-block({
            margin-bottom: 50px;
        });
        .xs-block({
            .h2_mob(#784FD1;);
            margin-bottom: 30px;
        });
    }
    &__block {
        .lg-block({
            width: 800px;
            margin: 0 auto;
        });
        .md-block({
            width: 738px;

            .flickity-viewport {
                overflow: unset;
            }

            .dots(@dot; -45px);
        });
        .xs-block({
            width: 100%;
        });
        .item {
            box-sizing: border-box;
            width: 365px;
            height: 500px;
            padding-top: 70px;
            padding-left: 48px;
            padding-right: 48px;
            padding-bottom: 90px;
            // overflow-y: auto;
            background: #ffffff;
            position: relative;
            margin-right: 20px;

            .md-block({
                width: 566px;
                height: 320px;
                margin-right: 30px;
            });
            .xs-block({
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 65px;
                width: 100%;
                height: auto;
            });

            &:not(.is-selected) {
                opacity: 0.4;
            }
            
            &::before {
                .square_small();
            }
            &::after {
                .square_img();
            }

            .number {
                content: '';
                position: absolute;
                top: 18px   ;
                right: 16px;

                font-weight: normal;
                line-height: 40px;
                font-size: 30px;
                text-align: right;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: @optiColor;
            }
            .text {
                font-weight: normal;
                line-height: 23px;
                font-size: 16px;
                letter-spacing: 0.02em;
                color: @fontColor;
            }
        }
    }


    // flickity======================================================
    .flickity-viewport {
        overflow: unset;
    }
    .flickity-button {
        background: none;
        width: unset;
        height: unset;
    }
    .flickity-button:hover {
        background: none;
    }
    .flickity-button:hover svg .st0 {
        fill: @optiColor;
        transition: .3s;
    }
    .flickity-button:hover svg .st1 {
        fill: #ffffff;
        transition: .3s;
    }
    .flickity-prev-next-button {
        border-radius: 0;
        width: 69px;
        height: 77px;
        top: 50%;
        transition: .3s;

        .lg-block({
            width: 60px;
            height: 66px;
        });
    }
    .flickity-button-icon {
        display: none;
    }
    .flickity-prev-next-button.previous {
        left: -95px;
    }
    .flickity-prev-next-button.next {
        right: -95px;
    }
    // flickity======================================================
}