@import "entry";

.rembit_services {
    box-sizing: border-box;
    padding-top: 30px;    
    padding-bottom: 40px;   
    background: #e2efff;
    min-height: 600px;
    overflow: hidden;

    &.visible {
        background: url(../img/description/back.png) 0 0/cover no-repeat #e2efff;
    }

    .xs-block({
        background: @back;
        background-size: cover;
    });

    .wrapper {
        position: relative;

        .xs-block({
            padding: 0;
        });
    }

    h3 {
        .h2_gray();
        margin-bottom: 25px;

        .md-block({
            font-size: 25px;
            line-height: 34px;
        });

        .xs-block({
            .h2_mob(@fontColor);
            padding: 0 15px !important;
            margin-bottom: 25px;
        });

        .xs_on {
            display: none;
            .xs-block({
                display: block;
            });
        }
    }

    .text_block {
        font-weight: 400;
        line-height: 23px;
        font-size: 18px;
        letter-spacing: .02em;
        color: @fontColor;
        background: #FFFFFF;
        padding: 30px;
        margin-bottom: 40px;
        position: relative;
        
        &:before {
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            background: #784fd1;
            top: 0;
            left: 0;
        }
        &:after {
            content: '';
            position: absolute;
            width: 33px;
            height: 52px;
            background: url(../img/why/block.svg) 0 0/contain no-repeat;
            bottom: 0;
            right: 0;
        }

        p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__block {
        position: relative;

        .item {
            box-sizing: border-box;
            cursor: pointer;

            &:not(:first-child) {
                border-top: none;
            }

            &.active {
                .outer {
                    background: @firstLinkHover;
                    border: 1px solid @firstLinkHover;
                    border-bottom: none;

                    .title {
                        .text {
                            color: #ffffff;
                        }
                        .arrow {
                            fill: #ffffff;
                            transform: rotate(0) translateX(-2px);
                        }
                    }
                    .price {
                        color: #ffffff;

                        &::before {
                            background: @firstLinkHover;
                        }
                    }
                }
            }

            .outer {
                padding: 19px 0;
                position: relative;
                display: flex;
                justify-content: space-between;
                transition: .3s;
                background: #ffffff;
                border: 1px solid @back;
                border-bottom: none;

                .xs-block({
                    height: 80px;
                    max-height: 80px;
                    box-sizing: border-box;
                });

                &:hover {
                    background: @firstLinkHover;
                    border: 1px solid @firstLinkHover;
                    border-bottom: none;

                    .title {
                        .text {
                            color: #ffffff;
                        }
                        .arrow {
                            fill: #ffffff;
                            transform: rotate(0) translateX(-2px);
                        }
                    }
                    .price {
                        color: #ffffff;

                        &::before {
                            background: @firstLinkHover;
                        }
                    }
                }
                
                .title {
                    display: flex;
                    align-items: center;

                    .arrow {
                        fill: @optiColor;
                        width: 12px;
                        min-width: 12px;
                        height: 11px;
                        transform: rotate(90deg) translateX(-2px);
                        margin-right: 40px;
                        margin-left: 30px;
                        transition: .3s;

                        .xs-block({
                            margin-left: 15px;
                            margin-right: 10px;
                        });

                        &.active {
                            fill: #ffffff;
                            transform: rotate(0) translateX(-2px);
                        }
                    }
                    .text {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 25px;
                        letter-spacing: 0.02em;
                        color: @fontColor;
                        transition: .3s;

                        .xs-block({
                            font-size: 13px;
                            line-height: 16px;
                            padding-right: 5px;
                        });
                    }
                }
                .price {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                    text-align: right;
                    letter-spacing: 0.02em;
                    color: @fontColor;

                    box-sizing: border-box;
                    padding-right: 70px;
                    width: 240px;
                    height: 100%;
                    position: relative;
                    transition: .3s;

                    .lg-block({
                        width: 200px;
                        padding-right: 45px;
                    });
                    .md-block({
                        width: 160px;
                        padding-right: 0px;
                        text-align: center;
                    });
                    .xs-block({
                        font-size: 12px;
                        line-height: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 90px;
                        min-width: 90px;
                    });

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: -19px;
                        width: 1px;
                        height: 63px;
                        background: @back;
                        transition: .3s;

                        .xs-block({
                            height: 79px;
                        });
                    }
                }
            }

            &:last-of-type {
                .outer, .inner {
                    border-bottom: 1px solid @back;
                }
            }
            
            .inner {
                display: none;
                padding: 30px 240px 40px 80px;
                background: #ffffff;
                border-left: 1px solid @back;
                border-right: 1px solid @back;

                .lg-block({
                    padding: 30px 45px 40px 40px;
                });
                .xs-block({
                    padding: 30px 15px 40px 15px;
                });

                .text {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    color: @fontColor;
                    
                    margin-bottom: 40px;
                }
                .block_but {
                    display: flex;
                    justify-content: flex-start;

                    .xs-block({
                        flex-direction: column;
                    });

                    .more, .call {
                        width: 380px;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.04em;
                        color: #FFFFFF;
                        font-size: 18px;
                    }
                    .more {
                        .button_std();
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;

                        .xs-block({
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 10px;
                        });
                    }
                    .call {
                        .button_unset();
                        background: @optiColor;
                        transition: .15s;

                        .md-block({
                            width: auto;
                            padding: 0 40px;
                        });
                        .xs-block({
                            width: 100%;
                            height: 50px;
                        });

                        &:hover {
                            background: linear-gradient(0deg,rgba(255,255,255,.3),rgba(255,255,255,.3)),#784fd1;
                            box-shadow: 0 2px 5px rgba(0,0,0,.66);
                        }
                    }
                }
            }
        }
    }
}    