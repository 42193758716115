@import "entry";

.rembit_questions {
    box-sizing: border-box;
    padding: 60px 0;
    min-height: 500px;
    // overflow: hidden;

    .xs-block({
        padding-top: 40px;
        padding-bottom: 0;

        .wrapper {
            padding: 0;
        }
    });

    h2 {
        font-weight: bold;
        line-height: 34px;
        font-size: 25px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: @fontColor;
        margin-bottom: 50px;

        .xs-block({
            .h2_mob(@fontColor);
            margin-bottom: 30px;
        });
    }
    &__block {
        display: flex;

        .md-block({
            flex-direction: column;
        });

        &__items {
            width: 650px;
            min-width: 650px;
            margin-right: 40px;

            .lg-block({
                width: 580px;
                min-width: 580px;
                margin-right: 20px;
            });
            .md-block({
                width: 100%;
            });
            .xs-block({
                min-width: auto;
            });

            .item {
                background: #4D1CB5;
                transition: .3s;
                cursor: pointer;
                position: relative;
                &:not(:first-child) {
                    margin-top: 3px;
                }
                &::after {
                    content: '';
                    background: url(../img/questions/arrow.svg) no-repeat;
                    background-size: contain;
                    position: absolute;
                    right: 20px;
                    top: 25px;
                    width: 11px;
                    height: 10px;
                    transition: .3s;

                    .xs-block({
                        right: 15px;
                    });
                }

                &.active {
                    &::after {
                        transform: rotate(90deg);
                    }
                }

                .title {
                    font-weight: 500;
                    line-height: 20px;
                    font-size: 16px;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;

                    display: flex;
                    padding: 19px 30px;

                    .xs-block({
                        padding-left: 15px;
                    });

                    &:hover {
                        background: @firstLinkHover;
                    }
                    
                    &.active {
                        background: @firstLinkHover;
                    }
                }
                .text {
                    font-weight: normal;
                    line-height: 20px;
                    font-size: 16px;
                    letter-spacing: 0.02em;
                    color: #4C4C4C;
                    background: #FFFFFF;
                    border: 1px solid #E2EFFF;
                    border-top: none;
                    overflow-y: auto;
                    
                    display: none;
                    padding: 30px;

                    .xs-block({
                        padding-left: 15px;
                        padding-right: 15px;
                    });
                }
            }
        }
        form {
            background: #E2EFFF url(../img/questions/back.png) no-repeat;
            background-size: cover;
            width: 670px;
            height: 420px;
            padding-top: 35px;
            padding-left: 35px;
            padding-right: 35px;
            box-sizing: border-box;
            position: relative;

            .lg-block({
                width: 410px;
                height: 600px;
            });
            .md-block({
                width: 100%;
                height: auto;
                padding-bottom: 55px;
            });
            .xs-block({
                background: @back;
                padding-top: 25px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 40px;
            });

            .br_on {
                display: none;
            }
            .lg-block({
                .br_off {
                    display: none;
                }
                .br_on {
                    display: block;
                }
            });

            &::before {
                content: '';
                background: url(../img/principles/nut_back.svg) no-repeat;
                background-size: contain;
                position: absolute;
                bottom: -110px;
                right: 35px;
                width: 137px;
                height: 163px;
                z-index: -2;
                
                .md-block({
                    display: none;
                });
            }
            &::after {
                content: '';
                background: url(../img/principles/nut_back.svg) no-repeat;
                background-size: contain;
                position: absolute;
                bottom: -150px;
                right: 160px;
                width: 59px;
                height: 70px;
                z-index: -2;

                .md-block({
                    display: none;
                });
            }

            .error_name {
                .error();
                font-size: 13px;
                position: absolute;
            }
            .error_tel {
                .error();
                font-size: 13px;
                margin: 0;
                position: absolute;
            }

            .title {
                font-weight: bold;
                line-height: 25px;
                font-size: 22px;
                letter-spacing: 0.04em;
                color: @fontColor;
                margin-bottom: 20px;

                .xs-block({
                    font-size: 18px;
                    line-height: 21px;
                    width: 160px;
                });
            }
            .desc {
                font-weight: normal;
                line-height: 23px;
                font-size: 18px;
                letter-spacing: 0.02em;
                color: @fontColor;
                margin-bottom: 30px;

                .md-block({
                    width: 445px;
                });
                .xs-block({
                    font-size: 16px;
                    line-height: 20px;
                    width: 225px;
                    margin-bottom: 25px;
                });
            }
            .font_placeholder() {
                font-weight: normal;
                line-height: normal;
                font-size: 16px;
                letter-spacing: 0.02em;
                color: @fontColor;
                padding-left: 17px;
                box-sizing: border-box;
            }
            .input_block {
                display: flex;
                .lg-block({
                    flex-direction: column;
                });
                .md-block({
                    flex-direction: row;
                });
                .xs-block({
                    flex-direction: column;
                });
                .input_item {
                    width: 287px;

                    input {
                        .font_placeholder();
                        height: 45px;
                        outline: none;
                        border: none;
                        width: 100%;
                    }
    
                    .lg-block({
                        width: 350px;
                    });
                    .xs-block({
                        width: 100%;
                    });
    
                    &.name {
                        margin-right: 20px;
                        .lg-block({
                            margin-right: 0;
                            margin-bottom: 20px;
                        });
                        .md-block({
                            margin-right: 12px;
                            margin-bottom: 0;
                        });
                        .xs-block({
                            margin-bottom: 20px;
                        });
                    }
                    &::placeholder {
                        color: @fontColorFaded;
                    }
                }
            }
            textarea {
                .font_placeholder();
                padding-top: 15px;
                width: 100%;
                height: 85px;
                resize: none;
                border: none;
                margin-top: 20px;

                .lg-block({
                    width: 350px;
                });
                .md-block({
                    width: 100%;
                });
                &::placeholder {
                    color: @fontColorFaded;
                }
            }
            .foot {
                margin-top: 20px;
                display: flex;

                .lg-block({
                    flex-direction: column;
                });
                .md-block({
                    flex-direction: row;
                });
                .xs-block({
                    flex-direction: column;
                });
                button {
                    .button_unset();
                    .button_std();
                    margin-right: 20px;

                    .lg-block({
                        width: 350px;
                        margin-right: 0;
                        margin-bottom: 24px;
                    });
                    .md-block({
                        width: 263px;
                        min-width: 263px;
                        margin-bottom: 0;
                        margin-right: 20px;
                    });
                    .xs-block({
                        width: 100%;
                        margin: 0;
                        margin-bottom: 20px;
                    });
                }
                .conditions,
                .conditions a {
                    font-weight: normal;
                    line-height: 14px;
                    font-size: 12px;
                    color: @fontColorFaded;
                }
                .conditions {
                    .md-block({
                        width: 263px;
                        br {
                            display: none;
                        }
                    });
                    a {
                        text-decoration-line: underline;
                        font-weight: 500;
                        transition: .3s;

                        &:hover {
                            color: @firstLinkHover;
                        }
                    }
                }
            }
        }
    }
}