@import "entry";

.rembit_bid_404 {
    background: @back url(../img/404/back.png) no-repeat;
    background-size: cover;
    height: 600px;
    width: 100%;

    .md-block({
        height: 390px;
    });

    &__block {
        padding-top: 30px;
        padding-left: 100px;
        display: flex;
        .lg-block({
            padding-top: 75px;
            padding-left: 50px;
        });
        .md-block({
            padding-top: 30px;
            padding-left: 30px;
        });
        .xs-block({
            padding-left: 0;
        });

        .master {
            width: 444px;
            height: 100%;
            margin-right: 110px;

            .lg-block({
                width: 373px;
                margin-right: 70px;
            });
            .md-block({
                width: 265px;
                margin-right: 35px;
            });
            .xs-block({
                display: none;
            });
        }
        .desc {
            padding-top: 40px;

            .lg-block({
                padding-top: 10px;
            });
            .md-block({
                padding-top: 15px;
            });
            .xs-block({
                padding-top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            });

            img {
                width: 485px;
                .lg-block({
                    width: 450px;
                });
                .md-block({
                    width: 344px;
                });
                .xs-block({
                    width: 257px;
                });
            }
            .title {
                font-weight: normal;
                font-size: 22px;
                line-height: 25px;
                color: @fontColor;
                margin-top: 30px;

                .lg-block({
                    font-size: 20px;
                });
                .md-block({
                    font-size: 15px;
                    line-height: 17px;
                    margin-top: 15px;
                });
                .xs-block({
                    font-size: 18px;
                    line-height: 21px;
                    margin-top: 20px;
                    text-align: center;
                });
            }
            .return {
                .button_unset();
                background: @optiColor;
                font-weight: bold;
                font-size: 18px;
                text-align: center;
                color: #fff;
                letter-spacing: 0.04em;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 260px;
                height: 45px;
                margin: 75px auto 0;
        
                .lg-block({
                    margin-top: 60px;
                });        
                .md-block({
                    margin-top: 40px;
                });
                .xs-block({
                    width: 290px;
                    margin-top: 60px;
                });
        
                &:hover {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
                    transition: .15s;
                }
            }
        }
    }
}