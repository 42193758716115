@import "entry";

.rembit_materials {
    box-sizing: border-box;
    background: @back url(../img/description/back.png) no-repeat;
    background-size: cover;
    padding: 50px 0;  

    .xs-block({
        display: none;
    });

    h2 {
        .h2_gray();
        margin-bottom: 40px;
    }
    &__block {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 210px;
            height: 210px;
            background: #ffffff;
            position: relative;
            margin-right: 20px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            &:nth-child(6n) {
                margin-right: 0;
            }
            &:nth-child(-n+6) {
                margin-top: 0;
            } 

            .lg-block({
                width: 194px;
                height: 194px;

                &:nth-child(odd), &:nth-child(even) {
                    margin-right: 10px;
                    margin-top: 10px;
                }
                &:nth-child(5n) {
                    margin-right: 0;
                }
                &:nth-child(-n+5) {
                    margin-top: 0;
                } 
            });
            .md-block({
                width: 177px;
                height: 177px;

                &:nth-child(odd), &:nth-child(even) {
                    margin-right: 10px;
                    margin-top: 10px;
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }
                &:nth-child(-n+4) {
                    margin-top: 0;
                } 
            });

            .block_for_img {
                height: 110px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 28px;
                margin-bottom: 20px;

                .md-block({
                    height: 100px;
                    margin-bottom: 10px;
                });

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .title {
                font-weight: normal;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                letter-spacing: 0.02em;
                color: @fontColor;
                
                .md-block({
                    font-size: 16px;
                });
            }
            
            
            &::before {
                .square_small();
                width: 8px;
                height: 8px;
            }
            &::after {
                .square_small();
                width: 8px;
                height: 8px;
                left: unset;
                top: unset;
                right: 0;
                bottom: 0;
            }
        }
    }
    .show_all {
        .btn_show_all();
    }
}



.rembit_materials_mob {
    padding-top: 40px;
    padding-bottom: 100px;
    display: none;
    background: @back;

    .xs-block({
        display: block;
    });

    h2 {
        .h2_mob(@fontColor);
        margin-bottom: 30px;
        // padding: 0 15px !important;
    }

    &__slider {
        .dots(@dot; -55px);
        // .flickity-viewport {
        //     transition: height 0.3s;
        // }
        .slide {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 49%;
                height: auto;
                background: #ffffff;
                position: relative;
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                
                &:nth-child(odd) {
                    margin-right: 2%;
                }

                .block_for_img {
                    height: 70px;
                    min-height: 70px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .title {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                    letter-spacing: 0.02em;
                    padding: 0 5px 15px 5px;
                    margin: auto 0;
                }
                &::before {
                    .square_small();
                    width: 8px;
                    height: 8px;
                }
                &::after {
                    .square_small();
                    width: 8px;
                    height: 8px;
                    left: unset;
                    top: unset;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}