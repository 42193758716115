@import "entry";

.wrapper_menu {
	z-index: 10;
	position: relative;
	background: @mainColor;
	width: 100%;

	.md-block({
		background: @mainLists;
	});
	.xs-block({
        display: none;
    });

	.wrapper {
		position: relative;
		display: flex;
		justify-content: space-between;
	}

	.menu_top {
		background: @mainColor;
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 45px;
		width: 1100px;

		.md-block({
			width: 615px;
		});
	
		li {
			height: 100%;
			display: flex;
			align-items: center;
			padding: 0 25px;
			transition: .3s;
	
			a {
				color: @fontColor;
				text-decoration: none;
			}
	
			&.menu_top__submenu:first-of-type:hover {
				background: @firstLinkHover;
				
				.burger, .first {
					transition: 0s;
					color: #fff;
				}
			}
		}

		.md-block({
			& > li {
				padding: 0 0 0 10px;
				&:first-of-type, &:last-of-type {
					padding: 0;
				}
			}
		});
	
	
	
		.burger {
			border-radius: 8px;
			color: @fontColor;
			cursor: pointer;
			user-select: none;
			width: 30px;
			height: 22px;
	
			&:before, &:after {
				border-radius: inherit;
				background: currentColor;
				content: "";
				display: block;
				height: 2px;
				transition: 0.3s;

				.md-block({
					height: 1px;
				});
			}
			&:after {
				filter: drop-shadow(0 -10px currentColor);
				transform: translateY(18px);

				.md-block({
					filter: drop-shadow(0 -7px currentColor);
					transform: translateY(14px);
				});
			}
	
			&.open:before {
				transform: translateY(10px) rotateZ(40deg);
			}
			&.open:after {
				filter: initial;
				transform: translateY(8px) rotateZ(-40deg);

				.md-block({
					transform: translateY(9px) rotateZ(-40deg);
				});
			}
		}
	
	
		&__submenu {
			box-sizing: border-box;
			background: @mainLists;
			width: 25%;

			.md-block({
				&:nth-child(-n+3) {
					display: none;
				}
			});
	
			&:nth-of-type(n+2):nth-of-type(-n+4) {
				border-left: 1px solid #E4E4E4;
			}

			&.second {
				.menu_top__submenu_list_optilist {
					.lg-block({
						padding-left: 20px;
						width: 493px;
					});
				}
			}
			&.third {
				.menu_top__submenu_list_optilist {
					width: 485px;
					.lg-block({
						width: 300px;
					});
				}
				.block_double {
					.list {
						width: 100%;
						&:first-child {
							margin: 0;
						}
					}
				}
			}

			&.info {

				a {
					justify-content: left;
				}

				.md-block({
					width: 200px;
					min-width: 200px;
					margin: 0 0 0 auto;
					& > a {
						justify-content: center;
					}
				});

				.menu_top__submenu_list {
					background: #ffffff;
					border: 1px solid @back;

					&.active {
						opacity: 1;
						display: block;
					}

					.time {
						display: none;
						height: 40px;
						padding-top: 25px;
						padding-left: 25px;
						.text {
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							color: @fontColorFaded;
						}

						.icon {
							width: 18px;
							height: 18px;
							min-width: 18px;
							min-height: 18px;
							fill: @fontColorFaded;
							margin-right: 10px;
						}

						.md-block({
							display: flex;
						});
					}

					&.hover {
						animation: submenu_hover .3s ease forwards;
					}

					li {
						&:hover {
							a {
								transition: .3s;
								color: @firstLinkHover;								
							}
						}
					}
				}
			}

			&.md {
				height: 100%;
				width: 100%;
				background: @mainColor;
				cursor: pointer;
				display: none;
				padding-left: 15px;

				.md-block({
					display: block;
				});

				.catalog {
					display: flex;
					align-items: center;
					height: 100%;

					.title {
						color: @fontColor;
						transition: .3s;
					}

					&.active {
						.arrow {
                            fill: #ffffff;
                            transform: rotate(0) translateX(-2px);
						}
						.title {
							color: #ffffff;
						}
					}
				}

				// & > a {
				// 	color: @fontColor;
				// }
				// .burger {
				// 	margin-left: 20px;
				// 	height: 20px;
				// 	&.open {
				// 		transition: 0s;
				// 		color: #fff;
				// 	}
				// }

				.arrow {
					fill: #784fd1;
					width: 12px;
					min-width: 12px;
					height: 11px;
					margin-right: 15px;
					transform: rotate(90deg) translateX(-2px);
					transition: .3s;
				}
				// .icon {
				// 	position: relative;
				// 	&::before {
				// 		content: '';
				// 		position: absolute;
				// 		background: url(../img/questions/arrow.svg) 0 0/contain no-repeat;
				// 		width: 11px;
				// 		height: 10px;
				// 	}
				// }

				.main_menu {
					display: none;
					padding-top: 15px;
					padding-bottom: 15px;
					background: @mainColor;
					position: absolute;
					left: 15px;
					right: 15px;

					.main_item {
						cursor: pointer;
						height: 48px;

						&:hover {
							color: @firstLinkHover;
							transition: .3s;
						}

						.icon {
							width: 17px;
							height: 17px;
							margin-right: 15px;
							fill: @iconColor;

							&.faults {
								height: 24px;
							}
						}
					}
				}

				.tech {
					display: none;
					padding-top: 15px;
					padding-bottom: 15px;
					background: @mainColor;
					position: absolute;
					left: 15px;
					right: 15px;

					li {
						height: 48px;

						&.title {
							color: @firstLinkHover;
							.arrow {
								transform: rotate(-180deg);
								fill: @firstLinkHover;
							}
							&:hover {
								transition: .3s;
								color: @optiColor;
								background: none;
								.arrow {
									fill: @optiColor;
								}
							}
						}
						&.other {
							margin-top: 15px;
						}
						&:hover {
							background: @firstLinkHover;
							transition: .3s;

							.icon, .arrow {
								fill: #fff;
							}
							.link {
								color: #fff;
							}
						}

						.icon {
							width: 30px;
							min-width: 30px;
							height: 30px;
							min-height: 30px;
							margin-right: 20px;
							fill: @fontColor;
						}
						.link {
							color: @fontColor;
						}
						.arrow {
							transform: rotate(0deg);
    						margin-left: 15px;
						}
					}
				}

				.table_finish {
					display: none;
					padding-top: 25px;
					padding-bottom: 25px;
					padding-left: 20px;
					padding-right: 20px;
					background: @mainColor;
					position: absolute;
					left: 15px;
					right: 15px;

					.title, .all {
						font-size: 16px;
						height: 40px;
						color: @firstLinkHover;
						display: flex;
						align-items: center;
						&:hover {
							transition: .3s;
							color: @optiColor;
							.arrow {
								fill: @optiColor;
							}
						}
						.arrow {
							fill: @firstLinkHover;
							transform: rotate(-180deg);
						}
					}
					.all {
						text-decoration: underline;
					}
					
					.block_double {
						display: flex;
						.list {
							width: 45%;
							&:first-child {
								margin-right: 10%;
							}
							.title {
								height: 35px;
								font-size: 16px;
								font-weight: 700;
								color: @menuTitle;
								margin-bottom: 4px;
							}
							ul {
								li {
									font-size: 16px;
									height: 40px;

									&:hover {
										a {
											transition: .3s;
											color: @firstLinkHover;
										}
									}
								}
							}
						}
					}
					.block_one {
						.list {
							width: 100%;
							.title {
								height: 35px;
								font-size: 16px;
								font-weight: 700;
								color: @menuTitle;
								margin-bottom: 4px;
							}
							ul {
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;
								li {
									width: 45%;
									font-size: 16px;
									height: 40px;
									box-sizing: border-box;

									&:nth-child(odd) {
										margin-right: 10%;
									}

									&:hover {
										a {
											transition: .3s;
											color: @firstLinkHover;
										}
									}

									a {
										.logo {
										    width: 40px;
											margin-right: 5px;
										}
										&.all {
											color: @firstLinkHover;
											text-decoration: underline;
											transition: .3s;
											&:hover {
												text-decoration: none;
											}
										}
									}
								}
							}
						}
					}
					
					.item {
						display: none;
					}
				}

			}


			&:hover {
				background: @firstLinkHover;
				transition: .3s;
				cursor: pointer;
				& > a {
					color: #ffffff;
					transition: .3s;

					.icon {
						fill: #ffffff;
						transition: .3s;
					}
				}
			}
	
			&_title {
				display: flex;
				align-items: center;
				height: 100%;
				width: 100%;
				font-size: 15px;
				letter-spacing: 0.02em;

				.md-block({
					font-size: 13px;
				});
				
				.icon {
					margin-right: 15px;
					fill: @iconColor;
					width: 20px;
					height: 23px;

					.lg-block({
						min-width: 20px;
						min-height: 20px;
					});

					&.faults {
						width: 22px;
						height: 30px;
					}
				}
	
				&.first {
					.icon {
						margin-right: 20px;

						.lg-block({
							margin-right: 13px;
						});
					}
				}
	
			}
			&_list {
				z-index: 900;
				display: none;
				opacity: 0;
				position: absolute;
				left: 0;
				top: 55px;
				width: 325px;
				background: @mainColor;
				// padding-top: 20px;
				padding-top: 15px;
				// padding-bottom: 30px;
				padding-bottom: 10px;
	
				li {
					padding: 0;
					// height: 50px;
					height: 48px;
					font-size: 16px;

					a {
						align-items: center;
						display: flex;
						width: 100%;
						height: 100%;
						padding: 0 25px;

						.link {
							width: 215px;
							transition: .3s;
						}
						.arrow {
							fill: @fontColor;
							width: 12px; 
							height: 10px;
						}
					}

					&.other {
						// margin-top: 20px;
						margin-top: 15px;
					}
	
					&:hover {
						cursor: pointer;
						background: #fff;

						& > a {
							color: @firstLinkHover;

							.icon {
								fill: @firstLinkHover;
								transition: .3s;
							}
							.arrow {
								fill: @firstLinkHover;
							}
						}
					}
	
					.icon {
						width: 30px;
						min-width: 30px;
						height: 30px;
						min-height: 30px;
						margin-right: 20px;
						fill: @fontColor;
					}
				}
				&.hover {
					animation: submenu_hover .3s ease forwards;
				}
	
				&_optilist {
					box-sizing: border-box;
					z-index: 900;
					display: none;
					position: absolute;
					opacity: 0;
					left: 0;
					top: 0;
					width: 575px;
					background: #fff;
					padding-top: 15px;
					padding-left: 35px;
					padding-bottom: 30px;
					
					&.hover {
						animation: submenu_hover .3s ease forwards;
					}
	
					.title {
						height: 48px;
						font-size: 16px;
						font-weight: bold;
						color: #9B9B9B;
						margin-bottom: 4px;
						display: flex;
						align-items: center;
					}

					.block_double {
						display: flex;
						.list {
							width: 45%;
							&:first-child {
								margin-right: 10%;
							}
						}
						ul {
							display: flex;
							flex-wrap: wrap;
							flex-direction: column;
							li {
								height: 40px;

								&:hover {
									a {
										transition: .3s;
										color: @firstLinkHover;								
									}
								}

								// .all {
								// 	font-weight: bold;
								// 	text-decoration-line: underline;
								// 	color: @firstLinkHover;

								// 	&:hover {
								// 		text-decoration: none;
								// 	}
								// }
								a {
									color: @fontColor;
									padding-left: 0;
									transition: .3s;
									font-size: 14px;
								}
							}							
						}
					}

					.block_one {
						ul {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							li {
								height: 40px;
								width: 45%;

								&:nth-child(odd) {
									margin-right: 10%;
								}

								&:hover {
									a {
										transition: .3s;
										color: @firstLinkHover;								
									}
								}

								a {
									color: @fontColor;
									padding-left: 0;
									transition: .3s;
									font-size: 14px;

									.logo {
									    width: 40px;
										margin-right: 5px;
										.lg-block({
											display: none;
										});
									}
									&.all {
										color: @firstLinkHover;
										text-decoration: underline;
										transition: .3s;
										&:hover {
											text-decoration: none;
										}
									}
								}
							}							
						}
					}

				}
			}
		}
	}

	.call {
		.button_unset();
		background: @optiColor;
		font-weight: 500;
		font-size: 18px;
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		letter-spacing: 0.04em;
		width: 260px;

		.lg-block({
			width: 238px;
			min-width: 238px;
		});

		.md-block({
			width: 200px;
			min-width: 200px;
			font-size: 14px;
			line-height: 16px;
		});

		&:hover {
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
			transition: .15s;
		}
	}
}



// optional====================================================
.dimming {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.3);
	z-index: 9;
	display: none;
	animation: submenu_hover .3s ease forwards;
}

@keyframes submenu_hover {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}