@import "entry";

.rembit_reviews {
    box-sizing: border-box;
    height: 500px;
    background: #3D0E92;
    background-position: 0 -30px;
    padding-top: 65px;

    &.visible {
        background: #3D0E92 url(../img/why/back.jpg) no-repeat;
        background-size: cover;
    }

    .lg-block({
        height: 585px;
        background-position: 0 0px;
    });
    .md-block({
        padding-top: 50px;
        padding-bottom: 90px;
        height: auto;
    });
    .xs-block({
        padding-top: 40px;
        background: @backMob;

        .wrapper {
            padding-left: 0;
            padding-right: 0;
        }
        .flickity-page-dots {
            bottom: -55px;
        }
    });

    h2 {
        .h2();
        margin-bottom: 55px;

        .md-block({
            margin-bottom: 40px;
        });
        .xs-block({
            .h2_mob();
            margin-bottom: 30px;
        });
    }

    &__block {
        width: 1170px;
        margin: 0 auto;

        .lg-block({
            width: 880px;
        });
        .md-block({
            width: 738px;

            .flickity-viewport {
                overflow: unset;
            }

            .dots(@dot; -45px);

        });
        .xs-block({
            width: 100%;
        });

        .item {
            background: #ffffff;
            width: 575px;
            height: 280px;
            position: relative;
            padding-top: 25px;
            padding-left: 50px;
            padding-right: 50px;
            box-sizing: border-box;
            margin-right: 20px;

            .lg-block({
                width: 430px;
                height: 330px;
            });
            .md-block({
                width: 567px;
                height: 280px;
                transition: .3s;

                &:not(.is-selected) {
                    opacity: .4;
                }
            });
            .xs-block({
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 80px;
                width: 100%;
                height: auto;
            });

            .square() {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                background: @optiColor;
            }

            &::before {
                .square();
                top: 0;
                left: 0;
            }
            &::after {
                .square();
                top: 0;
                right: 0;
                left: unset;
            }

            .squares {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                &::before {
                    .square();
                    bottom: 0;
                    left: 0;
                }
                &::after {
                    .square();
                    bottom: 0;
                    right: 0;
                }
            }

            .date {
                position: absolute;
                right: 50px;
                top: 40px;

                font-weight: normal;
                line-height: 15px;
                font-size: 12px;
                letter-spacing: 0.02em;
                color: @fontColor;

                .xs-block({
                    top: unset;
                    right: unset;
                    left: 15px;
                    bottom: 40px;
                });
            }
            .person {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                img {
                    width: 58px;
                    height: 67px;
                    margin-right: 30px;
                }
                .desc {
                    .name {
                        font-weight: 500;
                        line-height: 20px;
                        font-size: 16px;
                        letter-spacing: 0.02em;
                        color: #070707;
                    }
                    .position {
                        font-weight: normal;
                        line-height: 18px;
                        font-size: 14px;
                        letter-spacing: 0.02em;
                        color: @fontColor;
                    }
                }
            }
            p {
                font-weight: normal;
                line-height: 23px;
                font-size: 16px;
                letter-spacing: 0.02em;
                color: @fontColor;
                
            }
        }
    }

    // flickity======================================================
    .flickity-button {
        background: none;
        width: unset;
        height: unset;
    }
    .flickity-button:hover {
        background: none;
    }
    .flickity-button:hover svg .st0 {
        fill: @optiColor;
        transition: .3s;
    }
    .flickity-button:hover svg .st1 {
        fill: #ffffff;
        transition: .3s;
    }
    .flickity-prev-next-button {
        border-radius: 0;
        width: 69px;
        height: 77px;
        top: 50%;
        transition: .3s;

        .lg-block({
            width: 60px;
            height: 66px;
        });
    }
    .flickity-button-icon {
        display: none;
    }
    .flickity-prev-next-button.previous {
        left: -95px;

        .lg-block({
            left: -73px;
        });
    }
    .flickity-prev-next-button.next {
        right: -95px;

        .lg-block({
            right: -73px;
        });
    }
    // flickity======================================================
}