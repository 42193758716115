@import "entry";

.rembit_cost {
    box-sizing: border-box;
    height: 500px;
    position: relative;

    .md-block({
        height: auto;
    });

    .select2-dropdown {
        width: 360px !important;
    }
    
    .opt_back_left {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 45%;
        background: #4F23A7;
        z-index: -1;

        &.visible {
            background: #4F23A7 url(../img/bid/back.png) no-repeat;
            background-size: cover;
        }

        .md-block({
            height: 470px;
            width: 100%;
        });
    }
    .opt_back_right {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 55%;
        background: radial-gradient(1007.29px at 75.25% 50.6%, #602DE1 0%, #4D1CB5 30.2%, #3D0E91 60.17%, #34057B 84.45%, #300273 100%);
        z-index: -1;

        .md-block({
            top: 470px;
            width: 100%;
        });
    }
    &__block {
        box-sizing: border-box;
        display: flex;

        .md-block({
            flex-direction: column;
        });

        &-check {
            padding-top: 60px;
            width: 465px;
            min-width: 465px;

            .lg-block({
                width: 423px;
                min-width: 423px;
            });
            .md-block({
                width: 100%;
                min-width: 100%;
                padding-top: 40px;
            });

            h2 {
                .h2();
                margin-bottom: 30px;

                .md-block({
                    width: 460px;
                });
                .xs-block({
                    .h2_mob();
                    width: 100%;
                });

                .xs_on {
                    display: none;
                    .xs-block({
                        display: block;
                    });
                }
            }
            .title {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                margin-bottom: 30px;

                &:last-of-type {
                    margin-top: 10px;
                }
            }
            select {
                width: 100%;
            }
            .but_block {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;

                .md-block({
                    justify-content: flex-start;
                });
                .xs-block({
                    flex-wrap: wrap;
                });
                .action {
                    .button_std();
                    min-width: 210px;
                    max-width: 210px;

                    .md-block({
                        min-width: 315px;
                        max-width: 315px;
                        margin-right: 20px;
                    });
                    .xs-block({
                        min-width: 100%;
                        max-width: 100%;
                    });
                }
                .more_info {
                    .button_unset();
                    font-weight: 500;
                    font-size: 18px;
                    line-height: normal;
                    text-align: center;
                    letter-spacing: 0.04em;
                    color: @firstLinkHover;
                    text-decoration: underline;
                    transition: .3s;

                    .lg-block({
                        font-size: 17px;
                    });
                    .md-block({
                        font-size: 18px;
                    });
                    .xs-block({
                        margin: auto;
                        margin-top: 30px;
                        padding: 0;
                    });
                    
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        &-category {
            padding-top: 100px;
            padding-left: 190px;

            .lg_on {
                display: none;
                .lg-block({
                    display: block;
                });
            }

            .lg-block({
                padding-top: 60px;
                padding-left: 60px;
            });
            .md-block({
                padding-top: 170px;
                padding-left: 0;
                padding-bottom: 40px;
            });
            .xs-block({
                padding-top: 80px;
            });

            .title {
                font-weight: bold;
                font-size: 21px;
                line-height: 28px;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                margin-bottom: 10px;
            }
            .opt_title {
                font-weight: normal;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                margin-bottom: 45px;
            }
            &__select_block {
                display: flex;
                flex-wrap: wrap;
                height: 217px;

                .xs-block({
                    height: 300px;
                    overflow-y: auto;
                    padding-top: 13px;
                });
                
                // select===========================================================================
                .select2-container {
                    margin-right: 20px;
                    margin-bottom: 20px;
                    height: 36px;

                    .lg-block({
                        margin-bottom: 30px;
                    });
                }
                .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
                    border-radius: 20px;
                }
                .select2-container--default .select2-selection--single {
                    border-radius: 20px;
                    height: 36px;
                }
                .select2-container--default .select2-search--dropdown .select2-search__field {
                    height: 36px;
                }
                .select2-container--default .select2-selection--single .select2-selection__placeholder {
                    line-height: 36px;
                }
                .select2-container--default .select2-selection--single .select2-selection__rendered {
                    line-height: 36px;
                }
                .select2-container--default .select2-selection--single .select2-selection__arrow b {
                    top: 12px;
                }
                // select===========================================================================
            }
        }
    }
}



