@import "entry";

.rembit_head {
    padding: 21px 0 21px 0;

    .md-block({
        padding: 12px 0 12px 0;
    });
    .xs-block({
        display: none;
    });

    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        display: flex;
        .size(3);            
        cursor: pointer;

        .lg-block({
            width: 290px;
        });
        .md-block({
            width: 285px;
        });

        &:hover {
            & .text {
                color: @firstLinkHover;
            }
            & .icon {
                fill: @firstLinkHover;
            }
        }

        .icon {
            fill: @iconColor;
            width: 59px;
            height: 53px;
            transition: .3s;

            .md-block({
                width: 34px;
                height: 30px;
            });
        }
        .text {
            margin-left: 13px;
            color: @fontColor;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transition: .3s;

            .md-block({
                margin-left: 7px;
            });

            .title {
                text-transform: uppercase;
                font-size: 22px;
                letter-spacing: 0.04em;
                
                .md-block({
                    font-size: 16px;
                });
            }

            .desc {
                font-weight: normal;
                font-size: 14px;
                letter-spacing: 0.014em;
                
                .md-block({
                    font-size: 10px;
                    letter-spacing: 0.022em;
                });
            }
        }
    }

    &__search {
        display: flex;
        align-items: center;
        margin-right: 47px;
        .size(1);

        .lg-block({
            width: 16px;
            margin-right: 30px;
        });

        .icon {
            fill: @fontColorTwo;
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            margin-right: 10px;
        }
        .title {
            color: @fontColorTwo;
            font-weight: 500;
            line-height: 14px;
            font-size: 12px;
            min-width: 83px;

            .lg-block({
                display: none;
            });
        }
    }

    &__time {
        display: flex;
        align-items: center;
        .size(1);
        margin-right: 65px;

        .lg-block({
            display: none;
        });

        .icon {
            fill: @fontColorTwo;
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            margin-right: 10px;
        }
        .text {
            min-width: 103px;
            .title {
                color: @fontColorFaded;
                font-weight: 500;
                line-height: 14px;
                font-size: 12px;
            }
            .desc {
                font-weight: 500;
                line-height: 17px;
                font-size: 14px;
                color: @fontColor;
            }
        }
    }

    &__cost {        
        display: flex;
        align-items: center;
        .size(2);
        cursor: pointer;

        .lg-block({
            width: 168px;
        });
        .md-block({
            width: 150px;
        });

        &:hover {
            & .title {
                color: @firstLinkHover;
            }
            & .icon {
                fill: @firstLinkHover;
            }
        }

        .icon {
            fill: @iconColor;
            width: 34px;
            min-width: 34px;
            height: 34px;
            min-height: 34px;
            margin-right: 12px;
            transition: .3s;

            .lg-block({
                width: 27px;
                min-width: 27px;
                height: 27px;
                min-height: 27px;
            });

            .md-block({
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
            });
        }
        .title {
            min-width: 111px;
            color: @fontColor;
            font-weight: 500;
            line-height: 16px;
            font-size: 14px;
            transition: .3s;

            .md-block({
                font-size: 12px;
                line-height: 13px;
            });
        }
    }

    &__question {        
        display: flex;
        align-items: center;
        .size(2);        
        cursor: pointer;

        .md-block({
            width: 150px;
        });

        &:hover {
            & .title {
                color: @firstLinkHover;
            }
            & .icon {
                fill: @firstLinkHover;
            }
        }

        .icon {
            fill: @iconColor;
            width: 34px;
            min-width: 34px;
            height: 34px;
            min-height: 34px;
            margin-right: 12px;
            transition: .3s;

            .lg-block({
                width: 27px;
                min-width: 27px;
                height: 27px;
                min-height: 27px;
            });
            .md-block({
                width: 20px;
                min-width: 20px;
                height: 20px;
                min-height: 20px;
            });
        }
        .title {
            min-width: 111px;
            color: @fontColor;
            font-weight: 500;
            line-height: 16px;
            font-size: 14px;
            transition: .3s;

            .md-block({
                font-size: 12px;
                line-height: 13px;
            });
        }
    }

    &__call {
        display: flex;
        align-items: center;
        justify-content: right;
        flex-direction: column;
        .size(3);
        width: 263px;

        .lg-block({
            width: 210px;
        });
        .md-block({
            align-items: flex-end;
        });

        .title {
            color: @fontColorFaded;
            font-weight: 500;
            line-height: 16px;
            font-size: 14px;

            .lg-block({
                line-height: 45px;
            });
            .md-block({
                font-size: 12px;
                line-height: 20px;
            });
        }

        .tel {
            margin-top: -10px;
            display: flex;
            align-items: flex-end;
            cursor: pointer;

            .md-block({
                margin-top: 0;
            });

            &:hover {
                & .number {
                    color: @firstLinkHover;
                }
                & .icon {
                    fill: @firstLinkHover;
                }
            }

            .icon {
                fill: @iconColor;
                width: 38px;
                height: 38px;
                margin-right: 14px;
                transition: .3s;

                .lg-block({
                    display: none;
                });
            }
            .number {
                color: @fontColor;
                font-weight: bold;
                font-size: 24px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                transition: .3s;

                .md-block({
                    font-size: 17px;
                });
            }
        }
    }

}

.rembit_head_mob {
    display: none;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px #000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    .xs-block({
        display: block;
    });

    &__block {
        display: flex;
        justify-content: space-between;
        padding: 11px 0;
        .logo {
            display: flex;
            align-items: center;
            .icon {
                fill: @iconColor;
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }
            .title {
                font-weight: bold;
                font-size: 12px;
                line-height: normal;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: @fontColor;
            }
        }
        .burger {
            width: 25px;
            height: 18px;
            fill: @fontColor;
            transition: .15s;

            &.hide {
                opacity: 0;
            }
        }
        .tel {
            margin: 0 30px 0 auto;
            svg {
                width: 18px;
                height: 18px;
                fill: @iconColor;
            }
        }
    }
}


.rembit_bottom_mob {
    display: none;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px #000000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 43px;
    .wrapper {
        padding-left: 0;
    }
    &__block {
        display: flex;
        justify-content: space-between;
        .call {
            .button_unset();
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            color: #FFFFFF;
            background: @optiColor;
            height: 43px;
            width: 50%;
        }
        .right_block {
            padding: 11px 0;
            .burger {
                width: 25px;
                height: 18px;
                fill: @fontColor;
                transition: .15s;
    
                &.hide {
                    opacity: 0;
                }
            }
            .tel {
                margin: 0 30px 0 auto;
                svg {
                    width: 18px;
                    height: 18px;
                    fill: @iconColor;
                }
            }
        }
    }
}




.rembit_mob_menu {
    z-index: 1;
    background: #fff;
    top: 42px;
    padding-top: 22px;
    width: 100%;
    height: calc(100vh - 64px);
    position: fixed;
    overflow-y: auto;
    left: -100%;

    &.bottom {
        top: 0;
        height: calc(100vh - 43px);
    }

    &.show {
        animation: slideLeftShow .3s linear forwards;
    }
    &.close {
        animation: slideLeftClose .3s linear forwards;
    }

    .info_block {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(157,157,157,.1);
        margin-bottom: 30px;
        
        .titleTop() {
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: @fontColorFaded;
        }
        .descTop() {
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: @fontColor;
        }

        .time {
            display: flex;
            flex-direction: column;
            .top {
                display: flex;
                margin-bottom: 5px;
                .icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
                .title {
                    .titleTop();
                }
            }
            .desc {
                .descTop();
                padding-left: 19px;
            }
        }
        .phone {
            .title {
                .titleTop();
                margin-bottom: 5px;
            }
            .tel {
                .descTop();
            }
        }
    }

    .main_menu {

        .item() {
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.02em;
            color: @fontColor;
            display: flex;
            align-items: center;
        }

        .main_item {
            .item();
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: 1px solid rgba(157,157,157,.1);
                padding-bottom: 35px;
            }
            .icon {
                fill: @iconColor;
                margin-right: 15px;
                &:nth-child(1) {
                    width: 21px;
                    height: 17px;
                }
                &:nth-child(2) {
                    width: 23px;
                    height: 29px;
                }
                &:nth-child(3) {
                    width: 20px;
                    height: 20px;
                }
                &:nth-child(4) {
                    width: 20px;
                    height: 20px;
                }
            }
            .arrow {
                fill: @iconColor;
                width: 17px;
                height: 15px;
                margin: 0 0 0 auto;
            }
        }

        .optional_block {
            .item();
            flex-direction: column;
            margin-top: 20px;

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 25px;
                color: @fontColor;
                &:last-child {
                    margin-bottom: 0;
                }
                .icon {
                    fill: @iconColor;
                    margin-right: 20px;
                    &:nth-child(1) {
                        width: 29px;
                        height: 29px;
                    }
                    &:nth-child(2) {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }

    .action {
        .button_unset();
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: #FFFFFF;
        background: @optiColor;
        height: 35px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 68px;

        &.hide {
            display: none;
        }

        &:hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #784FD1;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.66);
            transition: .15s;
        }
    }

}

// ========================================================
@keyframes slideLeftShow {
    0% {left: -100%;}
    100% {left: 0%;}
}

@keyframes slideLeftClose {
    0% {left: 0%;}
    100% {left: -100%;}
}

@keyframes slideRighttShow {
    0% {left: 100%;}
    100% {left: 0%;}
}

@keyframes slideRightClose {
    0% {left: 0%;}
    100% {left: 100%;}
}

.menuBlockOptions(@zIndex: 2) {
    z-index: @zIndex;
    background: @mainLists;
    top: 42px;
    width: 100%;
    height: calc(100vh - 42px);
    position: fixed;
    overflow-y: auto;
    left: 100%;

    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: @fontColor;

    &.bottom {
        top: 0;
        height: calc(100vh - 43px);
    }

    &.show {
        animation: slideRighttShow .3s linear forwards;
    }
    &.close {
        animation: slideRightClose .3s linear forwards;
    }

    .main_title {
        display: flex;
        align-items: center;
        padding-top: 28px;
        padding-bottom: 28px;
        padding-left: 15px;
        background: #fff;
        .arrow {
            width: 17px;
            height: 15px;
            transform: rotate(180deg);
            fill: @iconColor;
            margin-right: 15px;
        }
    }
}
// ========================================================

.rembit_mob_menu__second,
.rembit_mob_menu__second_info {
    .menuBlockOptions(2);

    .tech {
        padding-bottom: 75px;
        li {
            display: flex;
            align-items: center;
            height: 55px;

            &.other {
                margin-top: 15px;
                padding-top: 15px;
                border-top: 1px solid #ffffff;
            }

            .icon {
                width: 30px;
                height: 30px;
                fill: @fontColor;
                margin-right: 22px;
            }
            .link {
                color: @fontColor;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
            }
            .arrow {
                width: 17px;
                height: 15px;
                fill: @iconColor;
                margin: 0 0 0 auto;
            }
        }
    }
}


.rembit_mob_menu__third {
    .menuBlockOptions(3);
    background: #ffffff;
    overflow-y: scroll;
    .wrapper {
        padding: 0;
    }

    .table_finish {
        padding-bottom: 75px;
        display: none;
        &.show {
            display: block !important;
        }
        .item {
            display: none;
            &.show {
                display: block !important;
            }

            .all {
                box-sizing: border-box;
                font-weight: 700;
                font-size: 16px;
                line-height: normal;
                letter-spacing: .02em;
                color: @fontColor;
                background: @mainLists;
                height: 62px;
                padding: 0 15px;
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 3px;
            }

            .list {
                margin-bottom: 3px;
                &.active {
                    .title {
                        .arrow {
                            transform: rotate(270deg);
                        }
                    }
                }

                .title {
                    padding: 0 15px;
                    background: @mainLists;
                    height: 62px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .link {
                        font-weight: bold;
                        font-size: 16px;
                        line-height: normal;
                        letter-spacing: 0.02em;
                        color: @fontColor;
                    }
                    .arrow {
                        width: 17px;
                        height: 15px;
                        fill: @iconColor;
                        transform: rotate(90deg);
                        transition: .3s;
                    }
                }
                .scroll {
                    background: #ffffff;
                    padding: 15px;
                    display: none;
                    li {
                        height: 55px;
                        display: flex;
                        align-items: center;
                        a {
                            font-weight: normal;
                            font-size: 16px;
                            line-height: normal;
                            letter-spacing: 0.02em;
                            color: @fontColor;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            .logo {
                                width: 40px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}